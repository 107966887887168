import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom'
//Utils
import Socket from '../../components/utils/Socket.config';
//Componenet App
import { Form, Button } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';

import Swal from "sweetalert2";
import NavBar from './Nav/NavBar';
import { ProductsFetchAPI } from '../../api/Paymentpost/Products';
import { CategriesPosFetchAPI } from '../../api/Paymentpost/CategoriesPos';
import { UsersFetchAPI } from '../../api/Users';
import { Modal } from 'react-bootstrap';
import { RenderIf } from '../utils/RenderIf';
import ModalPendingOrders from './ModalPendingOrders';
import ModalWaiters from './ModalWaiters';
import ModalDiscounts from './ModalDiscounts'
import ModalReturn from './ModalReturn';
import ModalCoupons from './ModalCoupons'
import ModalPaymet from './ModalPayMet';
import ModalIngredient from './ModalIngredient';
import ModalCreatePreOrder from './ModalCreatePreOrder';
import ModalParthners from './ModalParthners';
import { PromotionsFechtAPI } from '../../api/Promotions';
import { MachinesFetchAPI } from '../../api/Machines';
import { InventoryFechtAPI } from '../../api/Inventory';
import { GroupsFechtAPI } from '../../api/Groups';
import { ParthnerFetchAPI } from '../../api/Parthner';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import AlertSmallInfo from '../Alerts/AlertSmallInfo';

//Img
import logo_sp from '../../assets/img/logos/icon-black.png';
import empty_img from './img/empty_cart.png';
import promotion_img from './img/promos.png'
import all_products from './img/all.png';
import sad_products from './img/emptyassing.png'
import img_default from './img/imagen.png'
import img_combo from './img/combo.png';
//Icons
import Empty from '../utils/Empty';
import { HiShoppingCart } from 'react-icons/hi';
import LoadingAlert from '../Alerts/LoadingAlert';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import AlertSmall from '../Alerts/AlertSmall';
import { SegmentsFechtAPI } from '../../api/Segments';
import { BusinessFetchAPI } from '../../api/Business';
import { StatusMenu } from '../../api/StatusMenu';
import { AuthFetchAPI } from '../../api/Auth';
import Totals from './Totals';
//Config
import jsonConfig from '../../config/posPlus.json';
//import jsonConfig from '../../config/posLite.json';
import './css/payment.css';
import { RequestLocalFetchAPI } from '../../api/Paymentpost/RequestLocal';


const PaymentPost = () => {
   //let index = 0;
   let business = ''
   let token = ''
   let branchoffice = ''
   const navigate = useNavigate();
   if (localStorage.getItem("x-access-machine-token") == "" || localStorage.getItem("user") == "" || localStorage.getItem("branchoffice") == "") {
      business = JSON.parse(localStorage.getItem("business"))
      navigate(`/machines/login/` + business.code)
   } else {
      business = JSON.parse(localStorage.getItem("business"))
      token = localStorage.getItem("x-access-machine-token")
      branchoffice = JSON.parse(localStorage.getItem("branchoffice"))

   }

   let { business_code, branchoffice_code } = useParams();
   //States


   const [dataReferenceOrderMicros, setDataReferenceOrderMicros] = useState({ micros_reference: { checkNum: "", checkSeq: "" } })
   const [devolution_validation, setDevolution_validation] = useState(0)
   const [discount_validation, setDiscount_validation] = useState(0)
   const [activeTab, setActiveTab] = useState(0);
   const [dataDicountApply, setdataDiscountApply] = useState([])
   const [statusRequest, setStatusRequest] = useState(true)
   const [segmentsNow, setSegmentsNow] = useState([])
   const [groupNow, setGroupNow] = useState({ nama: '', price: 0.00, img: '', description: '', segments: [] })
   const [showModalIngredient, setShowModalIngredient] = useState(false)
   const [comboSelect, setComboSelect] = useState([])
   const [stepsReadyComboNow, setStepsReadyComboNow] = useState([])
   const [businessLogo, setBusinessLogo] = useState("")
   const [number, setNumber] = useState(1);
   const [client_phone, setClient_phone] = useState("00")
   const [amountPaymentReady, setAmountPaymenReady] = useState(0.00)
   const [locator, setLocator] = useState("0");
   const [dataClient, setDataClient] = useState([]);
   const [categories, setCategories] = useState([]);
   const [categorieSelect, setCategorieSelect] = useState({})
   const [products, setProducts] = useState([]);
   const [promotionsList, setPromotionsList] = useState([]);
   const [dataPreOrder, setDataPreOrder] = useState({ pre_Order: false, _id: "" });
   const [viewProducts, setViewProducts] = useState([]);
   const [allProducts, setAllProducts] = useState([]);
   const [showModalSelectItem, setShowModalSelectItem] = useState(false);
   const [listPaymentReady, setlistPaymentReady] = useState([])
   const [registerTurnOrder, setRegisterTurnOrder] = useState(0);
   const [notesItem, setNotesItem] = useState("");
   const [inputFocusState, setInputFocusState] = useState(true);
   const [previewPriceItemsegmentSelect, setPreviewPriceItemsegmentSelect] = useState(0)
   const [selectProduct, setSelectProduct] = useState({
      img: '',
      name: '',
      img: '',
      name: '',
      price: ''
   });

   const [previewSegment, setPreviewSegment] = useState([])
   const [segments, setSegments] = useState([]);
   const [shoppingCart, setShoppingCart] = useState([]);
   const [shoppingCartCombos, setShoppingCartCombos] = useState([])
   const [parthner_order, setParthner_order] = useState(false)
   //Event handler
   const [eventHandlerPreviewsegment, setEventHandlerPreviewsegment] = useState(0);
   const [eventHandler, setEventHandler] = useState(0);
   const [pageLoader, setPageLoader] = useState(0)
   //Totals states
   const [invoiceTotal, setInvoiceTotal] = useState(0);
   const [invoiceTotalItbms, setInvoiceTotalItbms] = useState(0);
   const [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
   const [invoiceBaseReducedTax, setInvoiceBaseReducedTax] = useState(0);
   const [invoiceTotalDiscount, setInvoiceTotalDiscount] = useState(0)
   const [taxesArrayAll, setTaxesArrayAll] = useState([])
   const [invoiceTips, setInvoiceTips] = useState(0)
   const [recomendationTips, setRecomendationTips] = useState([])
   const [moduleTips, setModuleTips] = useState(0)
   const [totaltopay, setTotaltopay] = useState(0)
   const [pos_integration_name, setPos_integration_name] = useState("");
   //filter
   const [temporalProducts, setTemporalProducts] = useState([]);
   const [searchTerm, setSearchTerm] = useState('');
   const [paramsMicros, setParamsMicros] = useState(0)
   const [parthners, setParthners] = useState([]);
   const [codeParthner, setCodeParthner] = useState(0)
   const [resetData, setResetData] = useState(0)
   const [yappy_transaction_id, setYappy_transaction_id] = useState("");
   const [showWaiters, setShowWaiters] = useState(false)


   //PreOrder Table info
   const [tableInfo, setTableInfo] = useState({ table_id: 0, table_code: "0", table_name: "" })

   //data client
   const [optionalData, setOptionalData] = useState(false);
   const [dataResponse, setDataResponse] = useState(false);
   const [ruc, setRuc] = useState(0);
   const [name, setName] = useState("Cliente generico");
   const [dv, setDv] = useState(0);
   const [checkDeliveryOrder, setCheckDeliveryOrder] = useState("comer aqui")
   let machine = localStorage.getItem('machine');

   const inputRefLog = useRef(null);
   {/*setTimeout(() => {
      inputRefLog.current.focus()
   }, 3000);*/}

   //We validate if the branch
   if (machine !== null && machine !== undefined && machine !== '') {
      machine = JSON.parse(localStorage.getItem('machine'))
   }

   //data checked discount
   const [productChecked, setProductChecked] = useState([]);
   const [combosChecked, setCombosChecked] = useState([])
   const [preOrderInvoice, setPreOrderInvoice] = useState(false);

   //Mobile Modal
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   //Function to increment the amount of products on the product detail modal
   const incrementNumber = () => {
      if (selectProduct.quantity) {

         let filter = shoppingCart.filter(group => group.group_id == selectProduct.group_id)
         console.log(filter);

         let amountNow = 0
         if (filter != undefined && filter.length > 0) {
            filter.forEach(group => { amountNow = (amountNow + group.amount) })
         }

         console.log(amountNow);

         let newLimit = (selectProduct.quantity - amountNow)
         console.log(newLimit);
         if (newLimit > number) {
            setNumber(number + 1);
         } else {
            const Toast = Swal.mixin({
               toast: true,
               position: "top-end",
               showConfirmButton: false,
               width: 600,
               timer: 3000,
               timerProgressBar: true,
               didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
               }
            });
            Toast.fire({
               icon: "info",
               title: "Este producto solo tiene " + selectProduct.quantity + " unidades disponibles"
            });
         }
      } else {
         setNumber(number + 1);
      }
   };

   const getInfoBusiness = async (statusParthners) => {
      let infoBusiness = JSON.parse(localStorage.getItem("business"))
      try {
         const response = await BusinessFetchAPI.getBusinessById(infoBusiness.id, localStorage.getItem("x-access-machine-token"));
         console.log(response.data.data);
         setBusinessLogo(response.data.data.logo);

         //let external_menu = localStorage.getItem('external_menu_manual')


         if (statusParthners == 1) {
            getParthners(response.data.data);
         } else {
            //if (external_menu == "true") {
            if (machine.external_menu == "1") {
               getParthners(response.data.data);
            }
         }
      } catch (err) {
         console.log(err);
      }
   }



   //get parthners of the business
   const getParthners = async (data) => {
      try {
         const response = await ParthnerFetchAPI.getBusinessParthners(token);
         //setParthners(response.data.parthners);
         let array = []
         array.push({ parthner_id: data.id, logo: data.logo, parthner_name: data.name })

         response.data.parthners.forEach((parthner) => {
            array.push(parthner)
         });

         console.log("Parthners:");
         console.log(array);

         setParthners(array);

      } catch (err) {
         setParthners([]);
         console.log(err);
      }
   }


   //Function to decrement the amount of products on the product detail modal
   const decrementNumber = () => {
      //If the amount of product is more than 1 we decrement it
      if (number > 1) {
         setNumber(number - 1);
      }
   };

   //Filter the products by the term searched
   const filteredProducts = useMemo(() => {


      if (searchTerm === '') {
         return products
      }
      return viewProducts.filter(product => {

         const nameMatch = product.name.toLowerCase().includes(searchTerm.toLowerCase());
         const descriptionMatch = product.description.toLowerCase().includes(searchTerm.toLowerCase());
         //const upc_code_match = product.upc_codes.some((upc) => upc.upc_code === searchTerm);
         //return nameMatch || descriptionMatch || upc_code_match;
         return nameMatch || descriptionMatch;
      })
   }, [searchTerm, products]);

   //function to select type delivery order
   const selectTypyDeliveryOrder = (typeOrder) => {

      let intervalRadioButton = 0
      let temShopingCart = shoppingCart
      let temShopingCartCombo = shoppingCartCombos
      setCheckDeliveryOrder(typeOrder)

      const initShopingcard = () => {
         clearInterval(intervalRadioButton)
         setShoppingCart(temShopingCart)
         setShoppingCartCombos(temShopingCartCombo)
         setEventHandler(1)
         SuccessAlert()
      }

      if (typeOrder != "Mixto") {
         LoadingAlert()
         setShoppingCart([])
         setShoppingCartCombos([])
         setEventHandler(1)
         temShopingCart.forEach(product => {
            product.delivery_type = typeOrder
         })
         temShopingCartCombo.forEach(product => {
            product.delivery_type = typeOrder
         })
         intervalRadioButton = setInterval(initShopingcard, 1000)
      }
   }



   //-------------------------------------------------------------------------------------
   //-------------------------------------------------------------------------------------
   // get status change of the info to  menu
   const getStatusContentMenu = async (budinessParthner, branchofficeParthner, machineParthner) => {
      console.log("getStatusContentMenu------------------------------------------------------------");
      let business_id = 0
      let branchoffice_id_general = 0
      let machine_id = 0

      if (budinessParthner === undefined) {
         business_id = business.id
         branchoffice_id_general = branchoffice.id
         machine_id = machine.id
      } else {
         business_id = budinessParthner
         branchoffice_id_general = branchofficeParthner
         // machine_id = machineParthner
         machine_id = machine.id
      }




      if (localStorage.getItem('generalData') == '' || localStorage.getItem('generalData') == undefined || localStorage.getItem('generalData') == null) {
         getDataGeneralSmartpay(business_id, branchoffice_id_general, machine_id, 1)
      } else {

         let generalData = JSON.parse(localStorage.getItem('generalData'))
         let filterData = generalData.find(({ branchoffice_id }) => branchoffice_id == branchoffice_id_general)
         console.log(filterData);
         if (filterData != undefined) {
            try {
               const response = await StatusMenu.getContentMenu(token, machine_id)
               console.log(machine_id);
               console.log("Status menu:");
               console.log(response);
               if (response.data.data.content_status == true) {
                  getDataGeneralSmartpay(business_id, branchoffice_id_general, machine_id, 2)
               } else {
                  console.log("no hay cambios en el menu-------------------------------------------------------");
                  dataconsolidation(generalData)
               }
            } catch (err) {
               console.log(err);
            }
         } else {
            getDataGeneralSmartpay(business_id, branchoffice_id_general, machine_id, 3)
         }
      }
   }

   //Function get the groups by machine
   const getDataGeneralSmartpay = async (business_id, branchoffice_id, machine_id, step) => {
      try {

         //getProductsByBranchOfficessByStatus(branchoffice.id, token)
         const response_allgroups = await ProductsFetchAPI.getProductsByBranchOfficessByStatus(branchoffice_id, 1, token)

         const response = await AuthFetchAPI.getMenuGenerate(token, machine_id, branchoffice_id, business_id)

         let data = []
         switch (step) {
            case 1://first time guarda data si no encuentra el parametro
               console.log("caso 1 ---------------------------------------------------------------");
               data = [{ "branchoffice_id": branchoffice_id, "data": response.data.data, "groups_by_branchoffice": response_allgroups.data.data }]
               break;
            case 2://consulta nueva data de una sucursal especifica
               console.log("caso 2 ---------------------------------------------------------------");
               let generalData1 = JSON.parse(localStorage.getItem('generalData'))
               //let filterData = generalData1.filter(({ branchoffice_id }) => branchoffice_id != branchoffice_id);
               //filterData.data=response.data.data

               generalData1.forEach((dataNow) => {
                  if (dataNow.branchoffice_id == branchoffice_id) {
                     dataNow.data = response.data.data
                     dataNow.groups_by_branchoffice = response_allgroups.data.data
                  }
               })

               console.log(generalData1);
               data = generalData1

               console.log("resplazar data");
               break;
            case 3:
               console.log("caso 3 ---------------------------------------------------------------");
               let generalData2 = JSON.parse(localStorage.getItem('generalData'))
               generalData2.push({ "branchoffice_id": branchoffice_id, "data": response.data.data, "groups_by_branchoffice": response_allgroups.data.data })
               console.log(generalData2);
               data = generalData2

               break;

         }

         localStorage.setItem('generalData', JSON.stringify(data))


         dataconsolidation(data)
      } catch (err) {
         console.log(err);
      }
   }


   //consolidates data into corresponding statements
   const dataconsolidation = (generalData, status) => {
      console.log("consolidacion de datos");
      let data = {}
      let allGroups = []

      generalData.forEach((dataNow) => {
         if (dataNow.branchoffice_id == branchoffice.id) {
            data = dataNow.data
            //allGroups = dataNow.groups_by_branchoffice
            allGroups = dataNow.data.groups
         }
      })


      //section categories------------------------------------------------
      //let dataCategories = data.category
      //let typeCodeCategories = 'P'
      //let categories_info = dataCategories.filter(({ code }) => code === typeCodeCategories);
      //console.log(" categorias filtradas");
      //console.log(categories_info);
      setCategories(data.category)


      //---------------------------------------------------------------------
      //section promotions---------------------------------------------------

      //filter promotion by status 1
      let dataPromotions = data.promotions
      let dataTemp = [];
      dataPromotions.forEach((promotionNow) => {
         if (promotionNow.status == 1) {
            dataTemp.push(promotionNow)
         }
      })

      setPromotionsList(data.promotions)

      //---------------------------------------------------------------------
      //section paymentMethods---------------------------------------------------
      //let dataPaymentMethods= data.paymentMethods
      //setPaymentMethod(dataPaymentMethods);
      //---------------------------------------------------------------------



      //all groups=================================================================

      setAllProducts(allGroups)
      setProducts(allGroups)
      setViewProducts(allGroups)
      setTemporalProducts(allGroups)
      //===========================================================================


      console.log(data);

      setDevolution_validation(data.branchoffice.devolution_validation)
      setDiscount_validation(data.branchoffice.discount_validation)

      console.log(data.branchoffice.devolution_validation);

      let dataTips = data.branchoffice.tips_validation
      setModuleTips(dataTips)


      //set data type integration
      setPos_integration_name(data.business.pos_integration_name)
      if (data.business.pos_integration_id != null) {
         setParamsMicros({ "pos_integration_id": data.business.pos_integration_id, "pos_integration_name": data.business.pos_integration_name, "pos_integration_params": data.business.pos_integration_params })
      } else {
         setParamsMicros(0)
      }


      //preserves the value of the selected category
      if (status == true) {
         console.log(categorieSelect);
         data.category.forEach((category) => {
            if (category.category_id == categorieSelect.category_id) {
               console.log("entre en las categorias");
               console.log(category);
               setCategorieSelect(category)
               selectCategorie(category)
            }
         })
      }

      //data code parthner
      console.log("parthner_order");
      console.log(parthner_order);

      if (parthner_order == true) {
         setCodeParthner({ business_id: data.business.id, business_code: data.business.code, branchoffice_id: data.branchoffice.id, branchoffice_code: data.branchoffice.code, machine_id: data.machine.machine_id, machine_code: data.machine.code })
         localStorage.setItem('codeParthner', JSON.stringify({ business_id: data.business.id, business_code: data.business.code, branchoffice_id: data.branchoffice.id, branchoffice_code: data.branchoffice.code, machine_id: data.machine.machine_id, machine_code: data.machine.code }))

      } else {

         if (branchoffice.parthner_business) {
            setParthner_order(true)
            setCodeParthner({ business_id: data.business.id, business_code: data.business.code, branchoffice_id: data.branchoffice.id, branchoffice_code: data.branchoffice.code, machine_id: data.machine.machine_id, machine_code: data.machine.code })
            localStorage.setItem('codeParthner', JSON.stringify({ business_id: data.business.id, business_code: data.business.code, branchoffice_id: data.branchoffice.id, branchoffice_code: data.branchoffice.code, machine_id: data.machine.machine_id, machine_code: data.machine.code }))

         } else {
            setParthner_order(false)
            setCodeParthner(0)
            localStorage.setItem('codeParthner', "")

         }
      }

   }

   //-------------------------------------------------------------------------------------
   //-------------------------------------------------------------------------------------


   //function get categories by brach office and by status
   const getCategoriesByBranchOffices = async (branchoffice_id, token) => {
      try {
         const response = await CategriesPosFetchAPI.getCategoriesByBranchOffices(branchoffice_id, 1, token);
         setCategories(response.data.data)
      } catch (error) {
         console.log(error);
      }
   }

   //function get products by branch offices and by status
   const getProductsByBranchOfficessByStatus = async (branchoffice_id, token) => {

      try {
         const response = await ProductsFetchAPI.getProductsByBranchOfficessByStatus(branchoffice_id, 1, token)
         setAllProducts(response.data.data)
         setProducts(response.data.data)
         setViewProducts(response.data.data)
         setTemporalProducts(response.data.data)

      } catch (err) {
         console.log(err);
      }
   }

   // function get segment by id product
   const getSegmentByProduct = async (info) => {


      if (info.group_id) {
         let group_id = info.group_id
         let token = localStorage.getItem('x-access-machine-token');
         let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))

         console.log(info);

         if (info.segments) {
            setShowModalSelectItem(true)
            compilationDataSegments(info, info.segments)

         } else {
            LoadingAlert()
            try {
               //For default we clean the segments of the state
               setSegments([])
               //We get the active segments of the group
               const response = await ProductsFetchAPI.getSegmentsByProducts(group_id, 1, branchoffice.id, token);
               setShowModalSelectItem(true)
               AlertSmall("Consulta exitosa")
               //ErrorAlert("", "Producto consultado", "success")
               //save item default by segment

               compilationDataSegments(response.data.group, response.data.data)
            } catch (err) {

               if (err.response.data.error === "No tiene acompañantes registrados para este combo") {
                  setSegments([]);
                  getGroupById(group_id)
               } else {
                  ErrorAlert("Verificar los segmentos he items del producto", "Error de consulta", "error")
                  setShowModalSelectItem(false)
                  setSegments([]);
                  console.log(err);
               }
            }

         }

      } else {
         console.log("Combos:");


         let data = info
         let priceCombo = 0

         if (data.setGroups.length > 0) {
            data.setGroups.forEach((combo) => {
               if (combo.isMainSetGroup == 1) {
                  if (combo.groups.length > 0) {
                     priceCombo = (combo.groups[0].price + priceCombo)
                  }
               }
            })
            data.price = priceCombo
         }

         console.log(data);
         setSelectProduct(data)
         setSegments([])
         setShowModalSelectItem(true)
      }

      function compilationDataSegments(group, segments) {
         let validItemsSgments = true
         let arrayTem = new Array
         for (let k = 0; k < segments.length; k++) {
            let segment = segments[k]
            let itemDefault = 0
            let turnItem = false

            if (segment.items.length == 0) {
               validItemsSgments = false
               console.log("no habia items en este segmento")
            } else {
               for (let f = 0; f < segment.items.length; f++) {
                  if (segment.segment_item_id_default == segment.items[f].item_id) {

                     console.log("este es el item default")
                     itemDefault = segment.items[f]
                     turnItem = true
                  }
               }

               if (turnItem == true) {
                  arrayTem.push({ segment: segment, item: itemDefault })
                  setPreviewSegment(arrayTem)
               } else {
                  console.log("Este producto no tiene default")
                  arrayTem.push({ segment: segment, item: segment.items[0] })
                  setPreviewSegment(arrayTem)
               }
            }
         }

         //setPreviewPriceItemsegmentSelect(response.data.group.price)

         if (validItemsSgments = true) {
            let segmentsTemp = segments
            let priceGroupTemp = group.price
            segmentsTemp.forEach((segmentTempI) => {
               if (segmentTempI.items.length > 0) {
                  let defaultCheckedSegment = segmentTempI.segment_item_id_default
                  let items = segmentTempI.items

                  if (defaultCheckedSegment == null || defaultCheckedSegment == "" || defaultCheckedSegment == undefined) {
                     priceGroupTemp = (priceGroupTemp + items[0].price);
                  } else {
                     items.forEach((itemTemp) => {
                        if (itemTemp.item_id == defaultCheckedSegment) {
                           priceGroupTemp = (priceGroupTemp + itemTemp.price);
                        }
                     });
                  }
               }

            });

            //We set the segments
            setSegments(segments);
            setSelectProduct(group);

            setPreviewPriceItemsegmentSelect(priceGroupTemp)
         } else {

            ErrorAlert("Hay un segmento sin items", "Error de consulta", "error")
            setShowModalSelectItem(false)
            setSegments([]);
         }

      }
   }

   //Function get group by id
   const getGroupById = async (group_id) => {

      let token = localStorage.getItem('x-access-machine-token');
      try {
         setShowModalSelectItem(true)
         const response = await GroupsFechtAPI.getGroupById(group_id, token)
         console.log(response);
         let formatProductAdd = response.data.data
         //ErrorAlert('', 'producto encontrado', 'success')
         AlertSmall("Consulta exitosa")

         //For default we set that the amount of the selected product depending the number 
         formatProductAdd.amount = 1;
         formatProductAdd.cantidadItem = 1;
         formatProductAdd.delivery_type = "comer aqui";
         formatProductAdd.note = '';
         formatProductAdd.unit_discount = 0;
         formatProductAdd.product_discount = 0;
         formatProductAdd.base_price = formatProductAdd.price;
         formatProductAdd.descuento = formatProductAdd.product_discount;
         formatProductAdd.discount = formatProductAdd.product_discount;
         //For default we initialice the segments empty of the selected product 
         formatProductAdd.segments = [];
         formatProductAdd.DetalleSegmentos = [];
         formatProductAdd.segments_string = formatProductAdd.name

         //let temporal_segment_string = formatProductAdd.segments_string
         setSelectProduct(formatProductAdd);

      } catch (err) {
         console.log(err);
      }
   }


   // function get segment by id product
   const getSegmentByPromotion = async (promotion) => {
      if (promotion.segments) {

         setShowModalSelectItem(true)
         compilationDataSegments(promotion, promotion.segments)


      } else {

         LoadingAlert()
         let token = localStorage.getItem('x-access-machine-token');

         try {
            //For default we clean the segments of the state
            setSegments([])
            //We get the active segments of the group
            const response = await SegmentsFechtAPI.getSegmentsByPromotion(promotion.promotion_id, token);
            setShowModalSelectItem(true)
            AlertSmall("Consulta exitosa")
            compilationDataSegments(promotion, response.data.data)


         } catch (err) {

            if (err.response.data.error === "No tiene acompañantes registrados para esta promoción") {
               AlertSmall("Consulta exitosa")
               setSegments([]);
               setSelectProduct(promotion);
               setShowModalSelectItem(true)
            } else {
               ErrorAlert("Verificar los segmentos he items de la promocion", "Error de consulta", "error")
               setShowModalSelectItem(false)
               setSegments([]);
               console.log(err);
            }
         }

      }

      function compilationDataSegments(promotion, segments) {
         console.log(promotion);
         let promotionJson = segments
         promotionJson.group = promotion
         promotionJson.group.name = promotion.promotion_name
         promotionJson.group.description = promotion.promotion_description

         let validItemsSgments = true
         let arrayTem = new Array
         for (let k = 0; k < promotionJson.length; k++) {
            let segment = promotionJson[k]
            let itemDefault = 0
            let turnItem = false

            if (segment.items.length == 0) {
               validItemsSgments = false
               console.log("no habia items en este segmento")
            } else {
               for (let f = 0; f < segment.items.length; f++) {
                  if (segment.segment_item_id_default == segment.items[f].item_id) {
                     itemDefault = segment.items[f]
                     turnItem = true
                  }
               }


               if (turnItem == true) {
                  arrayTem.push({ segment: segment, item: itemDefault })
                  setPreviewSegment(arrayTem)
               } else {
                  arrayTem.push({ segment: segment, item: segment.items[0] })
                  setPreviewSegment(arrayTem)
               }
            }
         }

         if (validItemsSgments = true) {
            let segmentsTemp = promotionJson
            let priceGroupTemp = promotionJson.group.price
            segmentsTemp.forEach((segmentTempI) => {
               let defaultCheckedSegment = segmentTempI.segment_item_id_default
               let items = segmentTempI.items

               if (defaultCheckedSegment == null || defaultCheckedSegment == "" || defaultCheckedSegment == undefined) {
                  priceGroupTemp = (priceGroupTemp + items[0].price);
               } else {
                  items.forEach((itemTemp) => {
                     if (itemTemp.item_id == defaultCheckedSegment) {
                        priceGroupTemp = (priceGroupTemp + itemTemp.price);
                     }
                  });
               }

            });


            //We set the segments
            setSegments(promotionJson);
            setSelectProduct(promotionJson.group);

            setPreviewPriceItemsegmentSelect(priceGroupTemp)
         } else {
            ErrorAlert("Hay un segmento sin items", "Error de consulta", "error")
            setShowModalSelectItem(false)
            setSegments([]);
         }
      }
   }

   //view categories
   const listCategories = () => {
      const listCategorie = categories.map((categorie, index) => (
         <>
            {/* <option value={categorie.category_id} key={categorie.category_id}  >{categorie.category_name}</option> */}
            {/* <a className="nav-link"> {categorie.category_name}</a> */}
            <li key={index}>
               <div className="button-container">
                  <input type="radio" className="btn-check"
                     id={categorie.category_id}
                     name='options-outlined'
                     value={JSON.stringify(categorie)} autocomplete="off" onChange={(e) => selectCategorie(categorie)} />
                  <label className="btn btn-outline-primary rounded-3 border"
                     htmlFor={categorie.category_id}>
                     <img className='rounded-circle' src={categorie.img === null ? img_default : categorie.img} alt="Imagen 1" />
                     {categorie.category_name}
                  </label>
               </div>
            </li>
         </>
      ));
      return (listCategorie)
   }

   const changeLanguage = (languague) => {

      document.cookie = "googtrans=/" + languague;


      {/*if (languague === 'es') {
         document.cookie = "googtrans=" + "/es";
      } else {
         document.cookie = "googtrans=" + "/es/zh-CN";
      }*/}
      new window.google.translate.TranslateElement({ pageLanguage: 'es' }, 'google_translate_element')



   }

   const promotions = async () => {
      console.log("Estoy en promociones")

      let token = localStorage.getItem('x-access-machine-token');
      let business = JSON.parse(localStorage.getItem('business'))
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'));

      console.log(promotionsList);

      if (promotionsList.length > 0) {
         let arrayPromotions = promotionsList;

         arrayPromotions.forEach((promotion) => {
            promotion.cloudinary_id = null
            promotion.id = promotion.promotion_id
            promotion.suggested_price = 0
            promotion.group_type = "infinite"
            promotion.status = 1
            promotion.preparation = 0
            promotion.expiration_date = promotion.expirationDate
            promotion.batch = null
            promotion.presentation = null
            promotion.brand_id = null
            promotion.especial_discount = 0
            promotion.dTasaITBMSValue = "01"


            promotion.name = promotion.promotion_name
            promotion.description = promotion.promotion_description
            promotion.upc_codes = [{ upc_code: "" }];
         })

         console.log("detalle de la promocion")
         console.log(arrayPromotions)
         setProducts(arrayPromotions)
         setViewProducts(arrayPromotions)

      } else {
         try {
            const response = await PromotionsFechtAPI.getPromotionsByBranchoffice(branchoffice.id, token)
            console.log(response.data.promotions)

            let arrayPromotions = response.data.promotions;

            arrayPromotions.forEach((promotion) => {
               promotion.cloudinary_id = null
               promotion.id = promotion.promotion_id
               promotion.suggested_price = 0
               promotion.group_type = "infinite"
               promotion.status = 1
               promotion.preparation = 0
               promotion.expiration_date = promotion.expirationDate
               promotion.batch = null
               promotion.presentation = null
               promotion.brand_id = null
               promotion.especial_discount = 0
               promotion.dTasaITBMSValue = "01"


               promotion.name = promotion.promotion_name
               promotion.description = promotion.promotion_description
               promotion.upc_codes = [{ upc_code: "" }];
            })


            setProducts(arrayPromotions)
            setViewProducts(arrayPromotions)

         } catch (err) {
            setProducts([])
            setViewProducts([])
            console.log("No hay productos aqui ");
            console.log(err)
         }
      }
   }

   //function filter products by category
   const selectCategorie = async (categorie) => {

      console.log("seleccion categorias------------------------------*-*-*-*-*-*-*-*-*");
      setCategorieSelect(categorie)
      //clean input filter menu
      document.getElementById("searchInput").value = "";
      setSearchTerm("");

      let token = localStorage.getItem('x-access-machine-token');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      if (categorie == "todos") {
         setProducts(temporalProducts)
         setViewProducts(temporalProducts)
      } else if (categorie == "promociones") {
         promotions();
      } else {
         let categorieJson = categorie
         let category_id = categorieJson.category_id

         if ((categorieJson.groups.length + categorieJson.combos.length) > 0) {
            console.log("data local");

            let allProducts = categorieJson.groups.concat(categorieJson.combos)
            console.log(allProducts);
            setProducts(allProducts)
            setViewProducts(allProducts)
         } else {
            try {
               const response = await ProductsFetchAPI.getProductsByBranchOfficessByStatusByCategories(category_id, branchoffice.id, 1, token)
               setProducts(response.data.data)
               setViewProducts(response.data.data)
            } catch (err) {
               setProducts([])
               setViewProducts([])
               console.log("No hay productos aqui ");
               console.log(err);
            }
         }
      }
   }


   // function set product select
   const selectProductoInfo = async (info) => {
      setShowModalSelectItem(true)
      //clean input filter menu
      document.getElementById("searchInput").value = "";
      setSearchTerm("");

      setPreviewSegment([]);

      //We set the amount of the product
      setNumber(1);
      //We set for default the amount of the info product selected on 1
      info.amount = 1;
      info.cantidadItem = 1;
      //We set the info of the product and segment


      if (info.promotion_id) {

         getSegmentByPromotion(info)
      } else {
         getSegmentByProduct(info)
      }

      //getSegmentByProduct(info.group_id)

   }

   //view product select
   const contentProduct = () => {
      const contentetProductInfo = (
         <>

         </>
      );
      return (contentetProductInfo)
   }

   const handlerchangePriceProductGeneric = (e) => {
      console.log(selectProduct);
      console.log(e.target.value);
      selectProduct.price = parseFloat(e.target.value)
      console.log(selectProduct.price);
      setSelectProduct(selectProduct)
   }

   const handlerchangeTaxProductGeneric = (e) => {
      console.log(e.target.value);
      selectProduct.taxtValue = parseFloat(e.target.value)
      console.log(selectProduct.price);
      setSelectProduct(selectProduct)
   }

   //view segments by products
   const contentSegments = () => {

      const contentetProductInfo = segments.map((segment, index) => (
         <>
            {segment.items.length > 0 ? <>

               <div key={index}>
                  <h5 className='pt-1 fw-bold text-underline'>
                     <u>{segment.name}</u>
                  </h5>
               </div>
               <div className='' key={index}>
                  <div className="mt-1 ms-1 overflow-auto d-flex gap-3">
                     {segmentsItems(segment)}
                  </div>


                  {/* <h5 className='ms-5 900pt-1 fw-bold text-underline'>
                     <u>{segment.name}</u>
                  </h5>

               <div className='ms-5 mt-2 mb-5 bg-light-subtle rounded-3  border-inset border'>
                  <div className='overflow-auto d-flex gap-3'>
                     {segmentsItems(segment)}
                  </div>
               </div> */}
               </div></> : <></>}
         </>
      ));
      return (contentetProductInfo)
   }

   //Function to populate the segment items
   const segmentsItems = (segment) => {
      //validates the item by default and if it does not exist, sets the first position
      let itemSegments = segment.items
      let turnDefault = false


      itemSegments.forEach((item) => {
         if (segment.segment_item_id_default == item.item_id) {
            turnDefault = true
            item.turnDefault = true
         }
      })

      //set first position
      if (turnDefault == false) { itemSegments[0].turnDefault = true }

      const listItems = itemSegments.map((item, index) => (<>

         <article className='my-1 list-info-products-inputs  border-0 card-product-segments-kiosk' key={index}>
            <input
               className="list-group-item-check " type={segment.type == "UNIQ" ? "radio" : "checkbox"} name={"listGroupCheckableRadios" + segment.segment_id}
               id={"listGroupCheckableRadios" + segment.segment_id + item.item_id} onChange={() => (previewSegmentDetected(segment, item))}
               //defaultChecked={segment.segment_item_id_default === item.item_id || (!segment.segment_item_id_default && item.item_id === segment.items[0].item_id)}
               defaultChecked={segment.default_items.length == 0 ? index == 0 ? true : false : segment.default_items.find((default_item) => default_item.item_id == item.item_id) != undefined ? true : false}
            //defaultChecked={item.turnDefault}
            />
            <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-white p-1 shadow-sm position-relative h-100" htmlFor={"listGroupCheckableRadios" + segment.segment_id + item.item_id}>
               {item.name}
               <div className="d-flex align-items-center gap-3 py-3 rounded" >
                  <img src={item.img === null ? img_default : item.img}
                     alt="Product" width="50" height="50" className="rounded-3 flex-shrink-0 " />
                  <div className="d-flex gap-2 justify-content-around">

                     <div className='my-2'>
                        <span className=" d-block mb-0 fw-normal text-wrap ">
                           {item.description}
                        </span>

                     </div>
                  </div>
               </div>
               <span class="badge bg-success-subtle text-success-emphasis rounded-pill bottom-0 end-0 position-absolute m-1">
                  {item.price == 0 ? "+ $0.00" : "+ $" + item.price.toFixed(2)}</span>
            </label>
         </article>
      </>
      ));
      return (listItems)
   }

   //Template card Group of setGroup
   const cardGroup = (product) => {
      return (
         <div className="card-group">
            <div className="card">
               <div className='d-flex justify-constent- between'>
                  <img src={product.img === null ? img_default : product.img} className='m-2' alt="..." width="50" height="50" />
                  <div className="card-body">
                     <h5 className="card-title">{product.name}</h5>
                     <p className="card-text">{product.description}</p>
                     <details close >
                        <summary className=''>
                           <span className='badge bg-light text-dark summary-text-pos  fw-bold border rounded-4  text-wrap '>
                              <i className="uil uil-info-circle  px-1 "></i>  Detalles
                           </span>
                        </summary>
                        {product.segments.map(item => (
                           <span className="badge bg-light text-dark  summary-text-pos m-2 border rounded-4 my-1 text-wrap ">
                              {/* <span className='kiosk-subtitle-s' >   </span> */}
                              <span className='text-muted m-0 p-0'>{item.segment_name ? item.segment_name : item.name}:</span>
                              <br />
                              <strong>
                                 <span className='me-2'>{item.name} </span>
                                 {item.price ? parseFloat(item.price).toFixed(2) : parseFloat(item.item.price).toFixed(2)}
                              </strong>
                           </span>
                        ))}
                     </details>
                  </div>
               </div>
            </div>
         </div>
      )
   }

   const previewSegmentDetected = (segment, item) => {
      let arrayTemporal = previewSegment
      console.log(previewSegment);
      if (previewSegment.length > 0) {
         let include = true;
         for (let i = 0; i < arrayTemporal.length; i++) {
            if (arrayTemporal[i].segment.segment_id == segment.segment_id) {
               arrayTemporal[i].item = item
               include = false
            }
         }
         if (include == true) {
            let jsonSegmentTem = { segment: segment, item: item }
            arrayTemporal.push(jsonSegmentTem)
         }
      } else {
         let jsonSegmentTem = { segment: segment, item: item }
         arrayTemporal.push(jsonSegmentTem)
      }

      let pricePreviewTemp = selectProduct.price
      arrayTemporal.forEach((i) => {
         pricePreviewTemp = (pricePreviewTemp + parseFloat(i.item.price))
      });
      console.log(arrayTemporal);
      setPreviewPriceItemsegmentSelect(pricePreviewTemp);

      setPreviewSegment(arrayTemporal)
      setEventHandlerPreviewsegment(1)
   }

   //Function to listen the swicht
   const handleSwitchChange = async (event) => {
      //We set the data to update

      let product = JSON.parse(event.target.value)
      let newDelivery_type = ""
      let validAllTypeOrder = true

      if (event.target.checked == true) {
         newDelivery_type = "comer aqui"
      } else {
         newDelivery_type = "para llevar"
      }

      let oldDelivery_typeItem = ""

      if (product.combo_id) {
         shoppingCartCombos.forEach((item, index) => {
            EditTypeProduct(item, index)
         });
      } else {
         shoppingCart.forEach((item, index) => {
            EditTypeProduct(item, index)
         });
      }

      function EditTypeProduct(item, index) {
         if (JSON.stringify(item) == JSON.stringify(product)) {
            item.delivery_type = newDelivery_type;
         }

         if (index !== 0) {
            if (item.delivery_type != oldDelivery_typeItem) {
               validAllTypeOrder = false
            }
         }
         oldDelivery_typeItem = item.delivery_type
      }

      if (validAllTypeOrder == false) {
         setCheckDeliveryOrder("mixto")
      } else {
         setCheckDeliveryOrder(newDelivery_type)
      }

      setShoppingCart(shoppingCart)
      setEventHandler(1)
   };

   const viewSelectSegmentItem = () => {
      const contentlistPreview = previewSegment.map((segmentpreview, index) => (
         <>

            <div className='col-sm-6 ' key={index}>
               <h6 className="pt-1 fw-bold text-underline">
                  <u>{segmentpreview.segment.name}:</u>
               </h6>
               <div className='bg-light border-sp d-flex align-items-center justify-content-center' >
                  <div className='d-flex align-items-center justify-content-center'>
                     <img src={segmentpreview.item.img === null || segmentpreview.item.img === '' ? businessLogo : segmentpreview.item.img} className=" border-sp m-2 " width="30px" />
                  </div>
                  <div className='m-2 d-flex align-items-center justify-content-center'>
                     <h6>{segmentpreview.item.name}</h6>
                  </div>
               </div>
            </div>
         </>
      ));
      return (contentlistPreview)
   }


   //Function to add format products to the shopping cart
   const addProductUpc = (selectProduct, segmentsTemp) => {
      //------------------------------------------------------------

      //For default we set that the amount of the selected product depending the number 
      if (selectProduct.img === null || selectProduct.img === "") {
         selectProduct.img = businessLogo
      }
      selectProduct.amount = number;
      selectProduct.cantidadItem = number;
      selectProduct.delivery_type = "comer aqui";
      selectProduct.note = notesItem;
      selectProduct.unit_discount = 0;
      selectProduct.product_discount = 0;
      selectProduct.descuento = selectProduct.product_discount;
      selectProduct.discount = selectProduct.product_discount;
      //For default we initialice the segments empty of the selected product
      selectProduct.segments = [];
      selectProduct.DetalleSegmentos = [];
      selectProduct.segments_string = ""
      //We set the segment items selected on the product selected

      let temporal_segment_string = ""
      segmentsTemp.forEach((segment) => {
         let defaultCheckedSegment = segment.segment_item_id_default
         let items = segment.items

         if (defaultCheckedSegment == null || defaultCheckedSegment == "" || defaultCheckedSegment == undefined) {
            let item = items[0]
            temporal_segment_string = selectProduct.segments_string + " " + segment.name + ": " + item.name
            selectProduct.segments_string = selectProduct.segments_string + " " + segment.name + ": " + item.name
            selectProduct.segments.push({ ...item, segment_name: segment.name, cantidadItem: 1, item: { name: item.name, description: item.description, img: item.img, price: item.price, item_id: item.item_id, amount: 1, taxtValue: item.taxtValue, tax: item.tax, external_reference: item.external_reference } })
            selectProduct.DetalleSegmentos.push({ ...item, segment_name: segment.name, cantidadItem: 1, item: { name: item.name, description: item.description, img: item.img, price: item.price, item_id: item.item_id, amount: 1, taxtValue: item.taxtValue, tax: item.tax, external_reference: item.external_reference } })
            selectProduct.price = selectProduct.price + item.price
         } else {
            items.forEach((item) => {
               if (item.item_id == defaultCheckedSegment) {
                  temporal_segment_string = selectProduct.segments_string + " " + segment.name + ": " + item.name
                  selectProduct.segments_string = selectProduct.segments_string + " " + segment.name + ": " + item.name
                  selectProduct.segments.push({ ...item, segment_name: segment.name, cantidadItem: 1, item: { name: item.name, description: item.description, img: item.img, price: item.price, item_id: item.item_id, amount: 1, taxtValue: item.taxtValue, tax: item.tax, external_reference: item.external_reference } })
                  selectProduct.DetalleSegmentos.push({ ...item, segment_name: segment.name, cantidadItem: 1, item: { name: item.name, description: item.description, img: item.img, price: item.price, item_id: item.item_id, amount: 1, taxtValue: item.taxtValue, tax: item.tax, external_reference: item.external_reference } })
                  selectProduct.price = selectProduct.price + item.price
               }
            });
         }
      });

      addProductSelectToShoppingcart(selectProduct, temporal_segment_string)

      let asholp = shoppingCart
      console.log('Carrito Products ', asholp);

      setPreviewSegment([])
      setSegments([])
      setNotesItem("")

      //--------------------
      handlerInputFocus()
   }

   const getInventory = async (product) => {

      if (product.group_type === "infinite") { selectProductoInfo(product) } else {
         let data = { "products": [] }
         data = { "products": [{ "group_id": product.group_id }] }
         try {
            const response = await InventoryFechtAPI.getInventoryByProducts(data, localStorage.getItem("x-access-machine-token"))
            let dataInventory = response.data.inventory

            console.log("Inventario en el producto");
            console.log(dataInventory);

            if (parseInt(dataInventory[0].quantity) > 0) {
               product.quantity = parseInt(dataInventory[0].quantity)
               selectProductoInfo(product)
            } else {
               ErrorAlert('', 'Este producto no tiene inventario disponible', 'info')
            }

         } catch (err) {
            console.log(err.response.data.message);
            selectProductoInfo(product)

         }
      }
   }


   //Function to add format products to the shopping cart
   const addProduct = (e) => {
      console.log(selectProduct)
      //------------------------------------------------------------
      e.preventDefault();
      formatJsonToAddShoppingCart()

      //For default we set that the amount of the selected product depending the number 
      /*if (selectProduct.img === null || selectProduct.img === "") {
         selectProduct.img = businessLogo
      }
      selectProduct.amount = number;
      selectProduct.cantidadItem = number;
      selectProduct.delivery_type = "comer aqui";
      selectProduct.note = notesItem;
      selectProduct.unit_discount = 0;
      selectProduct.product_discount = 0;
      selectProduct.descuento = selectProduct.product_discount;
      selectProduct.discount = selectProduct.product_discount;
      //For default we initialice the segments empty of the selected product
      selectProduct.segments = [];
      selectProduct.DetalleSegmentos = [];
      selectProduct.segments_string = ""
      //We set the segment items selected on the product selected

      let temporal_segment_string = ""
      for (let s = 0; s < segments.length; s++) {
         let segment = segments[s];

         for (let i = 0; i < segment.items.length; i++) {
            let item = segment.items[i]
            let radiobutton = document.getElementById("listGroupCheckableRadios" + segment.segment_id + item.item_id);

            if (radiobutton.checked) {
               temporal_segment_string = selectProduct.segments_string + " " + segment.name + ": " + item.name
               selectProduct.segments_string = selectProduct.segments_string + " " + segment.name + ": " + item.name
               selectProduct.segments.push({ ...item, segment_name: segment.name, cantidadItem: number, item: { name: item.name, description: item.description, img: item.img, price: item.price, item_id: item.item_id, amount: number, taxtValue: item.taxtValue, tax: item.tax, external_reference: item.external_reference } })
               selectProduct.DetalleSegmentos.push({ ...item, segment_name: segment.name, cantidadItem: number, item: { name: item.name, description: item.description, img: item.img, price: item.price, item_id: item.item_id, amount: number, taxtValue: item.taxtValue, tax: item.tax, external_reference: item.external_reference } })
               selectProduct.price = selectProduct.price + item.price
            }
         }

      }


      if (selectProduct.promotion_id) {
         addpromotionToShoppiingCart()
      } else {
         addProductSelectToShoppingcart(selectProduct, temporal_segment_string)
      }


      setPreviewSegment([])
      setSegments([])
      setNotesItem("")

      //restorage data menu
      let generalData = JSON.parse(localStorage.getItem('generalData'))

      dataconsolidation(generalData)

      if (categorieSelect == 'promociones') {
         selectCategorie('todos')
      } else {
         selectCategorie(categorieSelect)
      }*/
      //-----------------------------------------

   }

   // format json of product to add shopppingcart
   const formatJsonToAddShoppingCart = (dataProduct, status, addAuto, segmentsAuto) => {
      let formatProductAdd = {}
      let idCheckBox = ""
      let detailSegments = []
      if (dataProduct == undefined) {
         formatProductAdd = selectProduct
         idCheckBox = "listGroupCheckableRadios"
         detailSegments = segments
      } else {
         formatProductAdd = dataProduct
         idCheckBox = "listGroupCheckableRadios"

         if (addAuto == true) {
            detailSegments = segmentsAuto

            console.log(detailSegments);
         } else {
            detailSegments = segmentsNow
            console.log(formatProductAdd);
         }
      }

      if (formatProductAdd.img == null || formatProductAdd.img == "" || formatProductAdd.img == undefined) {
         formatProductAdd.img = businessLogo
      }
      //For default we set that the amount of the selected product depending the number 
      formatProductAdd.amount = number;
      formatProductAdd.cantidadItem = number;
      formatProductAdd.delivery_type = "comer aqui";
      formatProductAdd.note = notesItem;
      formatProductAdd.unit_discount = 0;
      formatProductAdd.product_discount = 0;
      formatProductAdd.base_price = formatProductAdd.price;
      formatProductAdd.valueOriginal = formatProductAdd.price;
      formatProductAdd.descuento = formatProductAdd.product_discount;
      formatProductAdd.discount = formatProductAdd.product_discount;
      //For default we initialice the segments empty of the selected product 
      formatProductAdd.segments = [];
      formatProductAdd.DetalleSegmentos = [];
      formatProductAdd.segments_string = ""

      //find segments checked
      let temporal_segment_string = ""
      detailSegments.forEach((segment) => {

         if (addAuto == true) {
            if (segment.segment_item_id_default == null) {
               let dataFirsItem = segment.items[0]
               addItem(dataFirsItem)
            } else {
               segmentsItems()
            }
         } else {
            segmentsItems()
         }

         //Scooch items of segment
         function segmentsItems() {
            segment.items.forEach((item) => {

               if (addAuto == true) {//if addAuto is true add automatic item defauld in group
                  if (item.item_id == segment.segment_item_id_default) {
                     addItem(item)
                  }

               } else {
                  let idCheckedItem = document.getElementById(idCheckBox + segment.segment_id + item.item_id)
                  if (idCheckedItem.checked == true) {
                     addItem(item)
                  }
               }
            })
         }

         //Add item selected
         function addItem(itemNow) {
            itemNow.amount = number
            temporal_segment_string = formatProductAdd.segments_string + " " + segment.name + ": " + itemNow.name
            formatProductAdd.segments_string = formatProductAdd.segments_string + " " + segment.name + ": " + itemNow.name
            formatProductAdd.segments.push({ ...itemNow, segment_name: segment.name, cantidadItem: number, item: itemNow })
            formatProductAdd.DetalleSegmentos.push({ ...itemNow, segment_name: segment.name, cantidadItem: number, item: itemNow })
            formatProductAdd.price = formatProductAdd.price + itemNow.price
         }

      });

      if (status == true) {
         saveDataGroupByCombo(formatProductAdd)
      } else {
         if (formatProductAdd.promotion_id) {
            addpromotionToShoppiingCart()
         } else {
            if (formatProductAdd.quantity) {

               let filter = shoppingCart.filter(group => group.group_id == selectProduct.group_id)
               console.log(filter);

               let amountNow = 0
               if (filter != undefined && filter.length > 0) {
                  filter.forEach(group => { amountNow = (amountNow + group.amount) })
               }

               console.log(amountNow);

               let newLimit = (selectProduct.quantity - amountNow)
               console.log(newLimit);
               if (newLimit >= number) {
                  addProductSelectToShoppingcart(formatProductAdd, temporal_segment_string)
               } else {
                  ErrorAlert("", "Este producto ya llego a limite de cantidad disponible en la cartilla", "info")
               }
            } else {
               addProductSelectToShoppingcart(formatProductAdd, temporal_segment_string)
            }
         }



         if (categorieSelect == 'promociones') {
            //selectCategorie('promociones')
            clearDataInfoProduct('promociones')
         } else {
            //selectCategorie(categorieSelect)
            clearDataInfoProduct(true)
         }
         //-----------------------------------------

      }
      setPreviewSegment([])
      setSegments([])
      setNotesItem("")
   }

   const saveDataGroupByCombo = (data) => {
      let setGroup = selectProduct.setGroups[activeTab]
      setGroup.activeTab = activeTab
      setGroup.ready = true

      let comboFind = comboSelect.find(({ set_id }) => set_id === setGroup.set_id)
      if (comboFind) {
         let comboIndex = comboSelect.findIndex(({ set_id }) => set_id === setGroup.set_id)
         let groupindex = comboSelect[comboIndex].groupsselected.findIndex(({ group_id }) => group_id === data.group_id)
         if (groupindex == -1) {
            if (comboSelect[comboIndex].count > 1) {
               comboSelect[comboIndex].groupsselected.push(data)
            } else {
               comboSelect[comboIndex].groupsselected = [data]
            }
         } else {
            comboSelect[comboIndex].groupsselected[groupindex] = data
         }
      } else {
         setGroup.groupsselected = []
         setGroup.groupsselected.push(data)
         comboSelect.push(setGroup)
      }
      stepSaveCombos()
   }

   //Esta funcion verifica que todos los pasos del combo se cumplan 
   const stepSaveCombos = (alert) => {
      //guarda tamaño del setgroups original
      let sizeSetGroups = selectProduct.setGroups.length;
      //convierte el tamaño del arreglo en un nuevo indice
      let array = Array.from({ length: sizeSetGroups }, (_, index) => index);
      let stepReady = [];

      //recorre el arreglo de los setGroups ya seleccionados y gusrda su index en un array 
      comboSelect.forEach((group) => {
         stepReady.push(group.activeTab)
      })
      setStepsReadyComboNow(stepReady)

      //Filtra los indices faltantes 
      let pendingStep = array.filter(index => !stepReady.includes(index));
      if (pendingStep.length > 0) {
         setActiveTab(pendingStep[0])
         if (alert == true) {
            ErrorAlert("Faltan pasos por completar", "Por favor completa todos los pasos del combo", "warning");
         }

      } else {
         let comboReadyFormat = {}
         comboReadyFormat.name = selectProduct.name
         comboReadyFormat.description = selectProduct.description
         comboReadyFormat.price = selectProduct.price
         comboReadyFormat.priceBase = selectProduct.price
         comboReadyFormat.img = selectProduct.img
         comboReadyFormat.combo_id = selectProduct.combo_id
         comboReadyFormat.descuento = 0
         comboReadyFormat.discount = 0
         comboReadyFormat.unit_discount = 0
         comboReadyFormat.taxes = selectProduct.taxes
         comboReadyFormat.external_reference = selectProduct.external_reference
         comboReadyFormat.note = notesItem
         comboReadyFormat.delivery_type = "comer aqui"
         comboReadyFormat.amount = number
         comboReadyFormat.setGroups = []
         comboReadyFormat.setGroupString = ""
         comboReadyFormat.referenced_group = selectProduct.referenced_group
         comboReadyFormat.referenced_group_id = selectProduct.referenced_group_id

         let newPrice = 0
         comboSelect.forEach((combo) => {
            let newSetGroup = {}
            newSetGroup.name = combo.name
            newSetGroup.set_id = combo.set_id
            newSetGroup.groups = combo.groupsselected
            newSetGroup.isMainSetGroup = combo.isMainSetGroup
            comboReadyFormat.setGroups.push(newSetGroup)
            comboReadyFormat.setGroupString = comboReadyFormat.setGroupString + combo.name

            combo.groupsselected.forEach((group) => {
               comboReadyFormat.setGroupString = comboReadyFormat.setGroupString + group.segments_string
               newPrice = newPrice + group.price
            })
         })

         comboReadyFormat.price = (selectProduct.price + newPrice)
         let coincidence = false

         let cant = 0
         let datacombo = {}
         for (let i = 0; i < shoppingCartCombos.length; i++) {
            if (shoppingCartCombos[i].setGroupString === comboReadyFormat.setGroupString) {
               if (shoppingCartCombos[i].discount == 0) {
                  coincidence = true
                  cant = (shoppingCartCombos[i].amount + number)
                  console.log(cant);
                  shoppingCartCombos[i].amount = cant
                  datacombo = shoppingCartCombos[i]
               }
            }
         }
         if (coincidence == true) {
            setShoppingCartCombos(shoppingCartCombos)
            calculateInvoiceTotals(shoppingCart, shoppingCartCombos)
         } else {
            console.log(comboReadyFormat);
            setShoppingCartCombos([...shoppingCartCombos, comboReadyFormat])
            calculateInvoiceTotals(shoppingCart, [...shoppingCartCombos, comboReadyFormat])
         }
         setActiveTab(0)
         setComboSelect([])
         clearDataInfoProduct(true)
         setShowModalSelectItem(false);
         setShow(false)

      }
   }


   const deleteSetGroupOfComboSelected = (setgroupActive) => {

      selectProduct.setGroups[setgroupActive].ready = false
      let removeFilter = comboSelect.filter(({ activeTab }) => activeTab != setgroupActive)

      setComboSelect(removeFilter)
      stepSaveCombos()
      //sethandlerEventAction(1)
   }

   const addpromotionToShoppiingCart = () => {
      let turn = 0
      shoppingCart.forEach((product) => {

         if (product.promotion_id) {
            if (product.promotion_id == selectProduct.promotion_id) {
               /*if (selectProduct.segments_string == product.segments_string) {
                  console.log("este producto ya esta");
                  shoppingCart.amount = (shoppingCart.amount + number)
                  shoppingCart.cantidadItem = shoppingCart.amount
                  turn = 1
               }*/
               ErrorAlert("Puedes incrementar la cantidad del mismo en el carrito.", "Ya existe esta promocion en el carrito.", "warning");
               turn = 1
            }
         }

      });

      if (turn == 0) {
         //We add the new product to the shopping cart
         setShoppingCart([...shoppingCart, selectProduct]);
         //We calculate the invoice totals
         calculateInvoiceTotals([...shoppingCart, selectProduct], shoppingCartCombos)

         if (checkDeliveryOrder != "comer aqui") { setCheckDeliveryOrder("mixto") }
      } else {
         setShoppingCart(shoppingCart)
         //We calculate the invoice totals
         calculateInvoiceTotals(shoppingCart, shoppingCartCombos)
      }

      selectCategorie("promociones")
   }



   //add product to shopingcart
   const addProductSelectToShoppingcart = (selectProductItem, temporal_segment_string) => {

      let temporalShopingCart = shoppingCart
      let turn = 0
      //check item string segments into shopingcart
      for (let shopping = 0; shopping < temporalShopingCart.length; shopping++) {

         if (selectProductItem.group_id == temporalShopingCart[shopping].group_id) {

            console.log(temporalShopingCart[shopping].segments_string);
            console.log(temporal_segment_string);
            if (temporalShopingCart[shopping].segments_string === temporal_segment_string) {
               console.log();
               if (temporalShopingCart[shopping].descuento == 0) {
                  console.log("este producto ya esta");
                  temporalShopingCart[shopping].amount = (temporalShopingCart[shopping].amount + number)
                  temporalShopingCart[shopping].cantidadItem = temporalShopingCart[shopping].amount
                  turn = 1
               }
            } else {

            }
         }

      }

      if (turn == 0) {
         //We add the new product to the shopping cart
         setShoppingCart([...shoppingCart, selectProductItem]);
         //We calculate the invoice totals
         calculateInvoiceTotals([...shoppingCart, selectProductItem], shoppingCartCombos)
         if (checkDeliveryOrder != "comer aqui") { setCheckDeliveryOrder("mixto") }
      } else {
         setShoppingCart(temporalShopingCart)
         //We calculate the invoice totals
         calculateInvoiceTotals(temporalShopingCart, shoppingCartCombos)
      }
   }

   //Function to delete a product of the shopping cart
   const deleteProduct = (product_index, group) => {
      //We delete the product of the shopping cart list
      if (group == true) {
         shoppingCart.splice(product_index, 1);
         setShoppingCart([...shoppingCart]);
      } else {
         shoppingCartCombos.splice(product_index, 1);
         setShoppingCartCombos([...shoppingCartCombos]);
      }

      //We recalculate the invoice totals
      calculateInvoiceTotals([...shoppingCart], [...shoppingCartCombos])

      if (preOrderInvoice == true) {
         if (shoppingCart.length == 0) {
            setPageLoader(1);
         }
      }
   }

   //Function to incremet the product amount on the shopping cart
   const incrementProductAmount = (product_index, group) => {
      //We search the product on the list

      let product = {}
      if (group == true) {
         product = shoppingCart[product_index];

         if (product.quantity) {

            let filter = shoppingCart.filter(group => group.group_id == product.group_id)
            console.log(filter);

            let amountNow = 0
            if (filter != undefined && filter.length > 0) {
               filter.forEach(group => { amountNow = (amountNow + group.amount) })
            } else {
               amountNow = product.amount
            }

            console.log(amountNow);

            if (product.quantity > amountNow) {
               product.amount = product.amount + 1;
            } else {
               const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  width: 600,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                     toast.onmouseenter = Swal.stopTimer;
                     toast.onmouseleave = Swal.resumeTimer;
                  }
               });
               Toast.fire({
                  icon: "info",
                  title: "Este producto solo tiene " + product.quantity + " unidades disponibles"
               });
            }
         } else {
            product.amount = product.amount + 1;
         }
      } else {
         product = shoppingCartCombos[product_index];
         product.amount = product.amount + 1;
      }

      //We affect the product data

      product.cantidadItem = product.amount;
      product.descuento = product.descuento + product.unit_discount;
      product.discount = product.discount + product.unit_discount;

      //We set the handler to recharge the data
      setEventHandler(1);
   };

   //Function to incremet the product amount on the shopping cart
   const decrementProductAmount = (product_index, group) => {
      //We search the product on the list
      let product = {}

      if (group == true) {
         product = shoppingCart[product_index];
      } else {
         product = shoppingCartCombos[product_index];

      }
      //We affect the product data
      product.amount = product.amount - 1;
      product.cantidadItem = product.amount
      product.descuento = product.descuento - product.unit_discount;
      product.discount = product.discount - product.unit_discount;

      //We check if the product amount is lower than 0
      if (product.amount <= 0) {
         //if is lower or equal to 0 we delete the product of the shopping cart list
         if (group == true) {
            shoppingCart.splice(product_index, 1);
            setShoppingCart([...shoppingCart])
         } else {
            shoppingCartCombos.splice(product_index, 1);
            setShoppingCartCombos([...shoppingCartCombos])
         }
      }
      //We set the handler to recharge the data
      setEventHandler(1)
   };


   //decimal reduction
   const decimalReduction = (value) => {

      let string = value.toString()
      let arrayString = string.split(".");
      let newDecimalString = []
      let sample = ''
      if (arrayString[1] != undefined) {
         newDecimalString = arrayString[1].slice(0, 2);
         sample = arrayString[0] + "." + newDecimalString
         console.log("Hice la reduccion");
      } else {
         console.log("Array: " + arrayString);
         console.log("Decimal: " + newDecimalString);
         console.log("Nuevo valor: " + sample);
         sample = string;
      }

      let endValue = parseFloat(sample)
      return endValue
   }

   // Función para redondear hacia arriba si el tercer decimal es mayor a 0
   function roundUpToTwoDecimals(value) {
      console.log("Nuevo redondeo de tax");
      console.log(value);
      // Verificamos si hay más de dos decimales
      const decimals = value.toString().split('.')[1];

      if (decimals && decimals.length > 2) {
         // Si el tercer decimal es mayor que 0, redondeamos hacia arriba
         if (parseInt(decimals[2]) > 0) {
            return Math.ceil(value * 100) / 100;
         }
      }

      console.log(parseFloat(value.toFixed(2)));

      // Si ya tiene dos decimales o menos, devolvemos el valor tal cual
      return parseFloat(value.toFixed(2));
   }
   //Function to round to two decimals but if the third decimal is greater than 5, round up
   function roundToTwoDecimals(value) {
      // Verificamos si hay más de dos decimales
      const decimals = value.toString().split('.')[1];

      if (decimals && decimals.length > 2) {
         // Si el tercer decimal es mayor que 5, redondeamos hacia arriba
         if (parseInt(decimals[2]) > 5) {
            console.log("Redondeo hacia arriba");
            return parseFloat((Math.ceil(value * 100) / 100).toFixed(2));
         } else if (parseInt(decimals[2]) == 5) {
            console.log("devolvemos solo los dos decimales iniciales sin redondear");
            return parseFloat(value.toString().split('.')[0] + "." + decimals[0] + decimals[1]);
         }
      } else {
         console.log("Redondeo hacia abajo");
      }

      console.log(parseFloat(value.toFixed(2)));

      // Si ya tiene dos decimales o menos, devolvemos el valor tal cual
      return parseFloat(value.toFixed(2));
   }
   //Function to round to two decimals but if the third decimal is greater than 5 or equal to 5, round up
   function roundToTwoDecimals1(value) {
      // Verificamos si hay más de dos decimales
      const decimals = value.toString().split('.')[1];

      if (decimals && decimals.length > 2) {
         // Si el tercer decimal es mayor que 5, redondeamos hacia arriba
         if (parseInt(decimals[2]) >= 5) {
            return Math.ceil(value * 100) / 100;
         }
      }
      // Si ya tiene dos decimales o menos, devolvemos el valor tal cual
      return parseFloat(value.toFixed(2));
   }

   //------------------------------------------------------------------------------
   //Function to calculate the invoice totals
   const calculateInvoiceTotals = (products, combos) => {

      //For every new calculate we set the totals
      let taxesArray = [];
      let invoiceTotalDiscount = 0
      let invoiceSubtotal = 0
      let invoiceTotalItbms = 0
      let invoiceTotal = 0
      let invoiceBaseReducedTax = 0
      //if we dont we products we put the totals in 0 for default
      if ((products.length + combos.length) === 0) {
         //We calculate the totals of the invoice
         setInvoiceTotalDiscount(0)
         setInvoiceSubtotal(0);
         setInvoiceTotalItbms(0)
         setInvoiceTotal(0);
         setInvoiceBaseReducedTax(0)
         setTaxesArrayAll(taxesArray)
      } else {

         //For every combos we calculate
         combos.forEach((combo) => {
            byProductandCombo(combo)
         })

         products.forEach((group) => {
            byProductandCombo(group)
         })

         //Esta funcion calcula los valores por producto o combo por posicion
         function byProductandCombo(item) {

            //Variables
            let total_product_price = 0;
            let total_product_itbms = 0;
            let product_total_discount = 0;
            let base_reduced_tax = 0;
            //We set the total discount of the product
            product_total_discount = (item.unit_discount * item.amount);
            //We set the total of the product
            total_product_price = (item.amount * (item.price - item.unit_discount));

            //Calculate tax by group--------------------------------
            let taxesGroup = item.taxes;
            taxesGroup.forEach(tax => {
               //calculate tax  value and add it to array
               let valueTax = 0

               if (tax.tax === "state_tax") {
                  valueTax = roundToTwoDecimals1((item.amount * ((item.price - item.unit_discount) * tax.taxtValue)));
                  //valueTax = (item.amount * ((item.price - item.unit_discount) * tax.taxtValue))
               } else if (tax.tax === "reduced_tax") {
                  valueTax = roundToTwoDecimals1((item.amount * ((item.price - item.unit_discount) * tax.taxtValue)));
                  if(tax.taxtValue >0){
                     base_reduced_tax = base_reduced_tax + (item.amount * ((item.price - item.unit_discount)))
                  }
               } else if (tax.tax === "city_tax" && products.length === 1) {
                  valueTax = roundToTwoDecimals1(item.amount * ((item.price - item.unit_discount) * tax.taxtValue))
               } else if (tax.tax === "city_tax" && products.length > 1) {
                  valueTax = roundToTwoDecimals1(item.amount * ((item.price - item.unit_discount) * tax.taxtValue))
               }
               else {
                  valueTax = roundUpToTwoDecimals((item.amount * ((item.price - item.unit_discount) * tax.taxtValue)));
               }

               tax.total = valueTax;
               //Identifier tax of the group position
               var index = taxesArray.findIndex(function (info, i) {
                  return info.tax_id === tax.tax_id
               });

               if (index < 0) {
                  let taxInfoNow = {
                     "dTasaITBMSValue": tax.dTasaITBMSValue,
                     "percentage": tax.percentage,
                     "tax": tax.tax,
                     "tax_id": tax.tax_id,
                     "taxtValue": tax.taxtValue,
                     "total": valueTax
                  }
                  taxesArray.push(taxInfoNow);
               } else {
                  //taxesArray[index].total = roundToTwoDecimals((taxesArray[index].total + valueTax))
                  taxesArray[index].total = (taxesArray[index].total + valueTax)
               }
               console.log(valueTax)
               console.log("Valor del impuesto " + tax.tax + ":  " + valueTax);

               total_product_itbms = (total_product_itbms + valueTax)

            });
            //-------------------------------------------------------
            //We set the itbms of the product
            //total_product_itbms = (product_info.amount * ((product_info.price - product_info.unit_discount) * product_info.taxtValue));

            //We calculate the totals of the invoice
            invoiceTotalDiscount = invoiceTotalDiscount + product_total_discount;
            invoiceSubtotal = decimalReduction(invoiceSubtotal + total_product_price);
            invoiceTotalItbms = invoiceTotalItbms + total_product_itbms;
            invoiceTotal = invoiceTotal + total_product_price + total_product_itbms;
            invoiceBaseReducedTax = invoiceBaseReducedTax + base_reduced_tax

            console.log("invoiceTotalDiscount:" + invoiceTotalDiscount);
            console.log("invoiceSubtotal:" + invoiceSubtotal);
            console.log("invoiceTotalItbms:" + invoiceTotalItbms);
            console.log("invoiceTotal:" + invoiceTotal);
         }

         //We set the totals
         setInvoiceTotalDiscount(invoiceTotalDiscount)
         setInvoiceSubtotal(invoiceSubtotal);
         setInvoiceTotalItbms(invoiceTotalItbms);
         setInvoiceTotal(invoiceTotal);
         setInvoiceBaseReducedTax(invoiceBaseReducedTax)
         setTaxesArrayAll(taxesArray);
         setTotaltopay((invoiceTotal + invoiceTips))
         validModuleTips(invoiceSubtotal)

         console.log("total: " + invoiceTotal);
         console.log("Propina: " + invoiceTips);
         console.log("total a pagar: " + (invoiceTotal + invoiceTips));



         let newArray = []
         console.log(dataDicountApply)
         //valid discount report
         dataDicountApply.forEach((discount, index) => {
            let discountvalid = 0
            discount.groups.forEach((group) => {
               let productfind = products.find(({ group_id }) => group_id === group.group_id)
               if (productfind) {
                  discountvalid = discountvalid + productfind.discount
               }
            })

            discount.combos.forEach((combo) => {
               let combofind = combos.find(({ combo_id }) => combo_id === combo.combo_id)
               if (combofind) {
                  discountvalid = discountvalid + combofind.discount
               }
            })


            discount.index = index
            if (discountvalid == 0) {
               let indexRemove = index
               dataDicountApply.forEach((item, index) => {
                  if (item.index != indexRemove) {
                     newArray.push(item)
                  }
               })

            } else {
               if (discountvalid != discount.total) {
                  discount.total = discountvalid
               }
            }
         })


         if (newArray.length > 0) {
            setdataDiscountApply(newArray)
         } else {
            setdataDiscountApply(dataDicountApply)
         }
      }

   }


   const validModuleTips = (subtotal) => {//Module tips validation
      if (moduleTips == 1) {
         let data = [
            { rate: "10%", value: decimalReduction(subtotal * 0.10) },
            { rate: "15%", value: decimalReduction(subtotal * 0.15) },
            { rate: "20%", value: decimalReduction(subtotal * 0.20) }
         ]
         setRecomendationTips(data)

      }
   }

   //Function to clear all the shopping cart
   const clearShoppingCart = () => {
      if ((shoppingCart.length + shoppingCartCombos.length) > 0) {
         const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
               confirmButton: 'btn btn-success',
               cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
         })

         swalWithBootstrapButtons.fire({
            title: 'Aun hay productos en la cartilla',
            text: "¿Estás seguro de que deseas eliminar todos los productos de tu carrito? Esta acción no se puede deshacer.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No!',
            reverseButtons: true
         }).then((result) => {
            if (result.isConfirmed) {
               swalWithBootstrapButtons.fire(
                  'Orden cancelada!',
                  '',
                  'success'
               )

               setShoppingCart([]);
               setShoppingCartCombos([]);
               setProductChecked([])
               setCombosChecked([])
               setEventHandler(1);
               setPageLoader(1)
            } else if (
               /* Read more about handling dismissals below */
               result.dismiss === Swal.DismissReason.cancel
            ) {
               swalWithBootstrapButtons.fire(
                  'La orden se mantiene!',
                  '',
                  'info'
               )
            }
         })
      } else {
         setShoppingCart([]);
         setProductChecked([])
         setCombosChecked([])
         setEventHandler(1);
         setPageLoader(1)
      }
   }

   const buttonTypeOrdersDelivery = () => {
      let radioButtons = (
         <>
            <input type="radio" className="btn-check " name="btnradio" id="btnradio1" data-type="comer aqui" autocomplete="off" onChange={(e) => (selectTypyDeliveryOrder(e.target.dataset.type))} checked={checkDeliveryOrder === "comer aqui"} />
            <label className="btn btn-outline-primary border rounded-pill " for="btnradio1">
               <small className='fw-bold fs-6 mx-1' ><i className="uil uil-arrow-down"></i>
                  Comer aquí
               </small>
            </label>

            <input type="radio" className="btn-check " name="btnradio" id="btnradio2" data-type="para llevar" autocomplete="off" onChange={(e) => (selectTypyDeliveryOrder(e.target.dataset.type))} checked={checkDeliveryOrder === "para llevar"} />
            <label className="btn btn-outline-primary border rounded-pill mx-1" for="btnradio2">
               <small className='fw-bold fs-6 mx-1' ><i className="uil uil-arrow-up-right"></i>
                  Para llevar
               </small>
            </label>

            <input type="radio" className="btn-check " name="btnradio" data-type="mixto" id="btnradio3" autocomplete="off" onChange={(e) => (selectTypyDeliveryOrder(e.target.dataset.type))} checked={checkDeliveryOrder === "mixto"} disabled />
            <label className="btn btn-outline-primary border rounded-pill" for="btnradio3">
               <small className='fw-bold fs-6 mx-1' >
                  <i className="uil uil-exchange-alt"></i>
                  Mixto
               </small>
            </label>
         </>
      )
      return (radioButtons)
   }

   //action focur input upc
   const handlerInputFocus = () => {
      AlertSmallInfo()
      inputRefLog.current.focus();
   }

   //funciones especificas para el manejo de los combos

   //select tap of combo
   const changeTab = (tabIndex) => {
      setActiveTab(tabIndex);
   };

   const actionCheckIngradent = (group, datacheck, addAuto) => {
      setStatusRequest(true)
      if (datacheck != undefined) {
         if (document.getElementById(datacheck).checked) {
            getdataGroup(group, addAuto)
         } else {
            if (addAuto == true) {
               getdataGroup(group, addAuto)
            } else {
               console.log("no checked")
            }
         }
      }
   }

   const getdataGroup = async (group, addAuto, modification) => {
      setStatusRequest(false)
      try {
         if (statusRequest == true) {
            const response = await ProductsFetchAPI.getSegmentsByProducts(group.group_id, 1, branchoffice.id, token)
            let dataGroup = response.data.group

            if (modification == true) {
               dataGroup.price = group.base_price
            } else {
               dataGroup.price = group.price
            }

            setSegmentsNow(response.data.data);
            setGroupNow(dataGroup);


            if (addAuto == true) {
               console.log("Entre aqui");
               formatJsonToAddShoppingCart(dataGroup, true, addAuto, response.data.data)
            } else {
               console.log(dataGroup);
               setShowModalIngredient(true);
            }

            setStatusRequest(true)
         }
      } catch (err) {

         setSegmentsNow([]);
         setGroupNow(group);
         if (addAuto == true) { formatJsonToAddShoppingCart(group, true, true, []) }
         setStatusRequest(true)
         console.log(err.response.data.error);
      }

   }

   //clear data of menu
   const clearDataInfoProduct = (status) => {
      let generalData1 = JSON.parse(localStorage.getItem('generalData'))
      dataconsolidation(generalData1, status)
      if (categorieSelect == 'promociones') { promotions()/*document.getElementById("cateforieAll").checked = true*/ }

   }
   //-----------------------------------------------------------------------------

   const handleChange = async (e) => {

      if (e.key === 'Enter') {

         let inputValue = e.target.value
         let product_search = 0
         allProducts.forEach((product) => {
            let upc_codes = product.upc_codes;
            upc_codes.forEach((upc) => {
               if (upc.upc_code == inputValue) {
                  product_search = product
               }
            })
         });

         console.log(allProducts)

         LoadingAlert("Buscando producto", "Espere un momento por favor...")
         try {

            let token = localStorage.getItem('x-access-machine-token');
            let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
            const response = await ProductsFetchAPI.getSegmentsByProducts(product_search.group_id, 1, branchoffice.id, token);

            ErrorAlert("", "Producto encontrado.", "success");
            console.log("Esta es la peticion del segmento")
            console.log(response.data.group);
            setSelectProduct(response.data.group)

            let segmentsTemp = response.data.data
            //let temporal_segment_string = ""

            addProductUpc(response.data.group, segmentsTemp)

         } catch (err) {
            ErrorAlert("Error de consulta", "Producto no encontrado.", "error");
            console.log(err)
         }

         //setSearchTerm(inputValue)
         document.getElementById("input_upc").value = ""
      }
   }



   //====================================Parthners=================================================
   //save and select parthner
   const selectRestaurant = (data) => {
      console.log("selectRestaurant---------------------------------------------------------");
      console.log(data);
      if (data.parthner_id == business.id) {
         resetData()
         //getUserByIdCard(data.parthner_business)
      } else {
         setParthner_order(true)

         let jsonNewParthner = { id: data.parthner_branchoffice_id, name: data.parthner_name, code: data.code, branchoffice_open: null, branchoffice_printer: null, branchoffice_support_phone: data.phone, parthner_business: data.parthner_business, data_Parthner: data }

         branchoffice = jsonNewParthner
         localStorage.setItem('branchoffice', JSON.stringify(jsonNewParthner))

         if (data.parthner_branch_machine_id != null) {
            getInfoMachine(data.parthner_branch_machine_id, true, data)
         }

         //setParthner_business(data.parthner_business)

         //getBusinessConfig()
         getRequest()

      }

      function resetData() {
         setParthner_order(false)
         if (localStorage.getItem('branchoffice_original') != null) {
            localStorage.setItem('branchoffice', localStorage.getItem('branchoffice_original'))
            branchoffice = JSON.parse(localStorage.getItem('branchoffice_original'))
         }

         if (localStorage.getItem('machine_original') != null) {
            localStorage.setItem('machine', localStorage.getItem('machine_original'))
            machine = JSON.parse(localStorage.getItem('machine_original'))
         }
         //setParthner_business(businessInfo.id)
         //getBusinessConfig()
         getRequest()
         localStorage.setItem('user', localStorage.getItem('user_original'))
      }

      function getRequest() {
         console.log("getRequest---------------------------------------------------------");
         localStorage.setItem('logo', data.logo)
         getStatusContentMenu(data.parthner_business, data.parthner_branchoffice_id, data.parthner_branch_machine_id);
      }
   }



   const getUserByIdCard = async (parthner_id) => {

      let userDataNow = JSON.parse(localStorage.getItem('user'))
      try {
         const response = await UsersFetchAPI.getUserByIdCard(userDataNow.idCard, token)
         console.log(response.data.user);
         let arrayUser = response.data.user
         console.log(arrayUser);
         console.log(parthner_id);

         let userByBusiness = arrayUser.find(user => user.business_id === parthner_id)
         if (userByBusiness !== undefined) {
            userDataNow.id = userByBusiness.id
            userDataNow.lastName = userByBusiness.lastName
            userDataNow.name = userByBusiness.name
            userDataNow.user_code = userByBusiness.user_code
            userDataNow.email = userByBusiness.email
            userDataNow.idCard = userByBusiness.idCard
            userDataNow.business_id = userByBusiness.business_id
            localStorage.setItem('user', JSON.stringify(userDataNow))

            console.log("Encontre el usuario");

         } else {

            console.log("user not found")
            ErrorAlert("", userDataNow.name + " " + "no tiene permitido facturar aqui.", "warning");
            resetDataRestaurant()

         }


      } catch (err) {
         console.log(err);
         ErrorAlert("", "Error de consulta de usuario", "error");
         //resetDataRestaurant()
      }

   }


   //Reset branch office and machine data original
   const resetDataRestaurant = () => {

      if (showWaiters == false) {
         setTimeout(() => {
            if (localStorage.getItem('branchoffice_original') != null) {
               localStorage.setItem('branchoffice', localStorage.getItem('branchoffice_original'))
               branchoffice = JSON.parse(localStorage.getItem('branchoffice_original'))
            }

            if (localStorage.getItem('machine_original') != null) {
               localStorage.setItem('machine', localStorage.getItem('machine_original'))
               machine = JSON.parse(localStorage.getItem('machine_original'))
            }
            //setParthner_business(businessInfo.id)
            //getBusinessConfig()
            localStorage.setItem('logo', businessLogo)
            localStorage.setItem('user', localStorage.getItem('user_original'))

            updatePage()
         }, 1000);
      } else {
         setShowWaiters(true)
      }
   }

   const updatePage = () => {
      window.location.reload()
   }

   //function get machine info
   const getInfoMachine = async (machine_id, statusParthnerMachine, parthner) => {
      try {
         const response = await MachinesFetchAPI.getmachineByid(machine_id, token)
         let infoMachineJson = response.data.data

         if (statusParthnerMachine == true) {
            let jsonNewMachine = {
               id: infoMachineJson.machine_per_branch_id,
               code: infoMachineJson.code,
               is_logged: 0,
               name_type_machine: infoMachineJson.name_type_machine,
               machine_type_id: infoMachineJson.machine_type_id,
               printers: infoMachineJson.printers,
               machine_config: infoMachineJson.machine_config,
               user_code_validation: infoMachineJson.user_code_validation,
               external_menu: infoMachineJson.external_menu
            }

            //if(infoMachineJson.user_code_validation == 1){setShowWaiters(true)}else{setShowWaiters(false)}

            if (infoMachineJson.user_code_validation == 1) { setShowWaiters(true) } else { setShowWaiters(false) }
            localStorage.setItem('machine', JSON.stringify(jsonNewMachine));
            machine = jsonNewMachine

            if (parthner != undefined) {
               getUserByIdCard(parthner.parthner_business)
            }
         }

      } catch (err) {
         console.log(err);
         ErrorAlert("", "Error de consulta de maquina", "error");
         resetDataRestaurant()
      }
   }

   //=====================================================================================

   //Hook to load the initial info
   useEffect(() => {
      (async () => {

         console.log("RENDERISADO DE LA PAGINA-------------------------------------");
         let token = localStorage.getItem('x-access-machine-token');
         let branchoffice = localStorage.getItem('branchoffice') !== '' ? JSON.parse(localStorage.getItem('branchoffice')) : '';


         try {
            //------------------------------------------------
            if (branchoffice !== '' && branchoffice !== null && branchoffice !== undefined) {


               let branchoffice_original = JSON.parse(localStorage.getItem('branchoffice_original'))
               const responseBranchOfficeInfo = await BranchOfficeFetchAPI.getBranchOfficesByid(branchoffice_original.id, token)
               localStorage.setItem('external_menu', responseBranchOfficeInfo.data.data.branchoffice.external_menu);

               let codeParthner = localStorage.getItem('codeParthner');
               if (codeParthner !== null && codeParthner !== undefined && codeParthner !== '') {
                  codeParthner = JSON.parse(localStorage.getItem('codeParthner'))
                  getStatusContentMenu(codeParthner.business_id, codeParthner.branchoffice_id, codeParthner.machine_id)
               } else {

                  getStatusContentMenu()

               }

               getInfoBusiness(responseBranchOfficeInfo.data.data.branchoffice.external_menu);


               //getCategoriesByBranchOffices(branchoffice.id, token)
               //getProductsByBranchOfficessByStatus(branchoffice.id, token)
            }
         } catch (err) {
            console.log(err);
         }

         resetStates();
         handlerInputFocus();

      })();

   }, [pageLoader]);


   useEffect(() => {
      resetStates()
      setResetData(0)
   }, [resetData])

   const resetStates = () => {
      //clean input filter menu
      //document.getElementById("searchInput").value = "";
      //valid invoice to user code


      setSearchTerm("");
      localStorage.setItem('preOrderComparativeOld', 0)
      setdataDiscountApply([])
      setShoppingCart([]);
      setShoppingCartCombos([]);
      setRecomendationTips([])
      setTaxesArrayAll([]);
      setlistPaymentReady([])
      setAmountPaymenReady(0.00)
      setInvoiceTips(0)
      setPreOrderInvoice(false)
      setInvoiceTotalDiscount(0)
      setInvoiceBaseReducedTax(0)
      setInvoiceSubtotal(0);
      setInvoiceTotalItbms(0)
      setInvoiceTotal(0);
      setPageLoader(0)
      setCheckDeliveryOrder("comer aqui")
      setName("Cliente generico")
      setDv(0)
      setRuc(0)
      setRegisterTurnOrder(0)
      setClient_phone("00")
      setLocator("0")
      setDataClient([]);
      setDataResponse(false)
      setOptionalData(false)
      setYappy_transaction_id("")
      setDataPreOrder({ pre_Order: false, _id: "" })



      if (machine.user_code_validation == 1) { setShowWaiters(true) } else { setShowWaiters(false) }

   }


   //Hook to recharge data of the shopping cart
   useEffect(() => {
      setShoppingCart([...shoppingCart]);
      setShoppingCartCombos([...shoppingCartCombos])
      calculateInvoiceTotals([...shoppingCart], [...shoppingCartCombos])
      setEventHandler(0);
   }, [eventHandler]);

   //Hook to recharge data of preview segments
   useEffect(() => {
      setPreviewSegment([...previewSegment])
      setEventHandlerPreviewsegment(0)
   }, [eventHandlerPreviewsegment]);

   // layoutConfig, setlayoutConfig
   const [responseMachine, setResponseMachine] = useState(null);

   //layout config
   const [layoutConfig, setLayoutConfig] = useState([]);
   const [layoutConfigBtn, setLayoutConfigBtn] = useState([]);

   const [btnLayout, setBtnLayout] = useState({

      couponPlus: null,
      couponLite: null,

      discountsPlus: null,
      discountsLite: null,

      pendingOrdersPlus: null,
      pendingOrdersLite: null,

      returnPlus: null,
      returnLite: null,

      menuLite: null,


   });


   useEffect(() => {
      (async () => {
         try {
            const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, localStorage.getItem('x-access-machine-token'));
            let code_language = response_machine.data.data.language.code

            setLayoutConfig(response_machine.data.data.machine_config)
            setLayoutConfigBtn(response_machine.data.data.machine_config.buttons.coupons.actvLite)

            setBtnLayout({
               couponPlus: response_machine.data.data.machine_config.buttons.coupons.actvPlus,
               couponLite: response_machine.data.data.machine_config.buttons.coupons.actvLite,

               discountsPlus: response_machine.data.data.machine_config.buttons.discounts.actvPlus,
               discountsLite: response_machine.data.data.machine_config.buttons.discounts.actvLite,

               pendingOrdersPlus: response_machine.data.data.machine_config.buttons.pendingOrders.actvPlus,
               pendingOrdersLite: response_machine.data.data.machine_config.buttons.pendingOrders.actvLite,

               returnPlus: response_machine.data.data.machine_config.buttons.return.actvPlus,
               returnLite: response_machine.data.data.machine_config.buttons.return.actvLite,

               menuLite: response_machine.data.data.machine_config.buttons.menu.actvLite,

            })
            // console.log(response_machine.data.data.machine_config);
            if (code_language != "es") {
               changeLanguage(code_language)
            }

         } catch (err) {

         }
      })()
   }, [])

   //btnLayout.menuLite buttons.menu.actvLite
   console.log("Contendor Main ", layoutConfig.mainContainer);
   console.log("Mesas:", btnLayout.pendingOrdersPlus);


   return (
      <>

         {/*0. Main Container  */}
         <main className="pos-container">
            <div className={
               //Condition add new class xl
               !layoutConfig.mainContainer
                  ? "container-pos-left-xl pos-container-view no-scroll"
                  : "container-pos-left pos-container-view  no-scroll"
            }

            >
               <div className="flex-container">
                  {/*1. Top Bar  */}
                  <header className='mx-1'>
                     <nav className={!layoutConfig.mainContainer
                        ? " px-1 fixed-top-sp border-bottom bg-white "
                        : "  px-1 fixed-top border-bottom bg-white"
                     }>
                        <div className="">
                           <div className="d-flex  align-items-center justify-content-between ">
                              <div className='d-flex  bd-highlight  align-items-center'>
                                 <a href="javascript:location.reload()"
                                    className="d-flex align-items-center flex-fill bd-highlight text-dark text-decoration-none">
                                    <img
                                       className="navbar-logo-post"
                                       src={logo_sp} alt="Logo Smart Pay" />
                                    <span className="h5 pt-2 me-3 fw-bold align-self-center  position-relative">
                                       POS
                                       {!layoutConfig.mainContainer
                                          ? <><span className="top-100 start-50 translate-middle ">
                                             <small className='text-muted fs-6  opacity-25 fw-lighter'>Lite </small></span></>
                                          : <><span className="top-100 start-50 translate-middle ">
                                             <small className='text-muted fs-6  opacity-25 fw-lighter'> Plus</small></span></>
                                       }
                                    </span>
                                 </a>

                                 {/*Buttons Group V. Plus*/}
                                 {
                                    layoutConfig.mainContainer

                                       ?
                                       <>
                                          <div className='flex-fill bd-highlight container-hidden-mobile my-1'>

                                             {

                                                btnLayout.discountsPlus
                                                   ? <>
                                                      <ModalDiscounts
                                                         actvPlus={btnLayout.discountsPlus}
                                                         actvLite={btnLayout.discountsLite}
                                                         productChecked={productChecked}
                                                         shoppingCart={shoppingCart}
                                                         shoppingCartCombos={shoppingCartCombos}
                                                         total={invoiceTotal}
                                                         subtotal={invoiceSubtotal}
                                                         itbms={invoiceTotalItbms}
                                                         discount={invoiceTotalDiscount}
                                                         setLoad={setEventHandler}
                                                         setShoppingCart={setShoppingCart}
                                                         setShoppingCartCombos={setShoppingCartCombos} setProductChecked={setProductChecked} taxesArrayAll={taxesArrayAll}
                                                         setCombosChecked={setCombosChecked}
                                                         combosChecked={combosChecked}
                                                         setdataDiscountApply={setdataDiscountApply}
                                                         dataDicountApply={dataDicountApply}
                                                         discount_validation={discount_validation}
                                                         codeParthner={codeParthner} />
                                                   </>
                                                   : <></>
                                             }

                                             {
                                                btnLayout.couponPlus
                                                   ? <>
                                                      <ModalCoupons
                                                         actvPlus={btnLayout.couponPlus}
                                                         actvLite={btnLayout.couponLite}

                                                         shoppingCart={shoppingCart}
                                                         shoppingCartCombos={shoppingCartCombos}
                                                         total={invoiceTotal}
                                                         subtotal={invoiceSubtotal}
                                                         itbms={invoiceTotalItbms}
                                                         discount={invoiceTotalDiscount}
                                                         setLoad={setEventHandler}
                                                         setShoppingCart={setShoppingCart}
                                                         taxesArrayAll={taxesArrayAll} />
                                                   </>
                                                   : <></>
                                             }

                                             {
                                                btnLayout.pendingOrdersPlus
                                                   ? <>

                                                      <ModalPendingOrders
                                                         actvPlus={btnLayout.pendingOrdersPlus}
                                                         actvLite={btnLayout.pendingOrdersLite}


                                                         preOrderInvoice={preOrderInvoice} setPreOrderInvoice={setPreOrderInvoice} setCheckDeliveryOrder={setCheckDeliveryOrder} setProductChecked={setProductChecked} setCombosChecked={setCombosChecked} setPageLoader={setPageLoader} setEventHandler={setEventHandler} shoppingCart={shoppingCart} shoppingCartCombos={shoppingCartCombos} setDv={setDv} setName={setName} setRuc={setRuc} setDataResponse={setDataResponse} setOptionalData={setOptionalData} setShoppingCart={setShoppingCart} setShoppingCartCombos={setShoppingCartCombos} setInvoiceSubtotal={setInvoiceSubtotal} setInvoiceTotal={setInvoiceTotal} setInvoiceTotalDiscount={setInvoiceTotalDiscount} setInvoiceTotalItbms={setInvoiceTotalItbms} setDataPreOrder={setDataPreOrder} setTableInfo={setTableInfo} setTaxesArrayAll={setTaxesArrayAll} setdataDiscountApply={setdataDiscountApply} setDataReferenceOrderMicros={setDataReferenceOrderMicros} codeParthner={codeParthner} />

                                                   </>
                                                   : <>

                                                   </>
                                             }

                                             {
                                                btnLayout.returnPlus
                                                   ? <>
                                                      <ModalReturn
                                                         actvPlus={btnLayout.returnPlus}
                                                         actvLite={btnLayout.returnLite}
                                                         devolution_validation={devolution_validation}
                                                         pos_integration_name={pos_integration_name}
                                                         codeParthner={codeParthner}
                                                      />
                                                   </>
                                                   : <></>
                                             }
                                             {
                                                parthners.length > 0 ? <><ModalParthners parthners={parthners} logo={businessLogo} selectRestaurant={selectRestaurant} setResetData={setResetData} calculateInvoiceTotals={calculateInvoiceTotals} shoppingCart={shoppingCart} shoppingCartCombos={shoppingCartCombos} codeParthner={codeParthner} setShoppingCart={setShoppingCart} setShoppingCartCombos={setShoppingCartCombos} /></> : <></>
                                             }
                                          </div>
                                       </>
                                       :
                                       <>
                                       </>
                                 }
                              </div>

                              {
                                 layoutConfig.mainContainer
                                    ?
                                    <>
                                       <div className='flex-fill bd-highlight me-2 search-bar'>
                                          <form className="button-container w-100">
                                             <div>
                                                <input
                                                   id='searchInput'
                                                   spellcheck="false"
                                                   className="form-control rounded-pill form-control-sm mx-2 bg-gray"
                                                   type="text"
                                                   step="any"
                                                   placeholder="Buscar productos..."
                                                   onChange={e => setSearchTerm(e.target.value)}
                                                />

                                             </div>
                                          </form>

                                       </div>

                                    </>
                                    :
                                    <>
                                    </>
                              }
                              <NavBar setPageLoader={setPageLoader}></NavBar>
                           </div>
                        </div>
                     </nav>
                  </header>

                  {/*2. Header Shopping Cart*/}
                  <div className="header-pos">
                     {/**/}
                     <div className='d-flex justify-content-between  align-items-center'>

                        <div className='fw-bold px-2 fs-label-1'>
                           <i className="uil uil-receipt-alt"></i>
                           Orden
                        </div>

                        <div>
                           {/* <button onClick={() => (handlerInputFocus())}
                           className="btn btn-outline-warning  border border-1 rounded-pill me-1" >
                           <i className="uil uil-qrcode-scan"></i>
                           Upc
                        </button> */}
                           {layoutConfig.mainContainer
                              ? <>
                                 <ModalCreatePreOrder
                                    checkDeliveryOrder={checkDeliveryOrder}
                                    dv={dv} name={name} ruc={ruc}
                                    setDv={setDv}
                                    setName={setName}
                                    setRuc={setRuc}
                                    dataResponse={dataResponse}
                                    optionalData={optionalData}
                                    setDataResponse={setDataResponse}
                                    setOptionalData={setOptionalData}
                                    shoppingCart={shoppingCart}
                                    shoppingCartCombos={shoppingCartCombos}
                                    total={invoiceTotal}
                                    subtotal={invoiceSubtotal}
                                    tax={invoiceTotalItbms} discount={invoiceTotalDiscount}
                                    setPageLoader={setPageLoader} dataPreOrder={dataPreOrder}
                                    taxesArrayAll={taxesArrayAll}
                                    cardGroup={cardGroup}
                                    dataDicountApply={dataDicountApply}
                                    paramsMicros={paramsMicros}
                                    parthner_order={parthner_order}

                                 />
                              </>
                              : <></>}


                           {/*  <button type="button" className="btn btn-danger bg-danger-subtle border-0 rounded-pill ms-1 text-danger"
                              onClick={clearShoppingCart} >
                              <small className='fw-bold '>
                                 <i className="uil uil-trash-alt buttons-label"></i>
                                  <span className='hidden-text'>
                                       Eliminar Todo
                                    </span> 
                              </small>
                           </button> */}
                        </div>
                        {/*
                           {/*
                           <div className=''>
                              <Link className='btn btn-outline-primary border btn-sm' to={`/ninyapp_transacction/${business_code}/${branchoffice_code}`}>
                                 <small className='fw-bold'>
                                    <i className="uil uil-receipt"></i>
                                    NinjaApp
                                 </small>
                              </Link>
                           </div> */}
                        <input id="input_upc" className='input-upc' placeholder="Buscar Upc..." ref={inputRefLog} inputMode="none" type="text" onKeyPress={(e) => handleChange(e)} autoFocus ></input>
                     </div>
                     {preOrderInvoice == true
                        ? <div className='text-center'>
                           <Badge rounded bg="warning" text="dark">
                              Precuenta activa
                           </Badge></div>
                        : <></>
                     }
                  </div>
                  {/*3. Shopping Cart*/}
                  <div className="item2 px-1  ">
                     <div className={
                        (shoppingCart.length + shoppingCartCombos.length == 0)
                           ? "   bd-highlight border-inset shopping-wrapper border-pos rounded no-scroll d-flex align-items-center justify-content-center bg-body-sp"
                           : "   bd-highlight  border-inset shopping-wrapper border-pos s rounded no-scroll  bg-body-sp"
                     }
                        onClick={() => { handlerInputFocus() }}>

                        <div >
                           {
                              (shoppingCart.length + shoppingCartCombos.length == 0)
                                 ? <Empty title="Vacío" msg="No hay ningun producto agregado en el carrito" img={empty_img} />
                                 : <>{shoppingCart.map((product, index) => (
                                    <div >
                                       <div className={product.discount || product.unit_discount > 0
                                          ? "d-flex border rounded border-warning border-2  mt-3 position-relative bg-white"
                                          : product.quantity < product.amount ? "d-flex border rounded border-danger border-2  mt-3 position-relative bg-white" : "d-flex rounded m-1 bg-white mt-1 card-pos"}
                                       >
                                          <div className="flex-shrink-0">
                                             <img className='m-1 rounded  bg-light border ' src={product.img === null || product.img === '' ? businessLogo : product.img} alt="..." width="80" height="80" />
                                          </div>
                                          <div className="flex-grow-1 m-1">
                                             <div className="d-flex bd-highlight">
                                                <div className={layoutConfig.mainContainer
                                                   ? "bd-highlight title-product-cart mt-1 text-dark fw-bold fs-label-1"
                                                   : "bd-highlight title-product-cart-xl mt-1 text-dark fw-bold fs-label-1"}
                                                >
                                                   {product.name}
                                                </div>
                                                <div className={layoutConfig.mainContainer
                                                   ? "ms-auto px-2 bd-highlight text-price-cart fs-label-1"
                                                   : "ms-auto px-2 bd-highlight text-price-cart-xl fs-label-1"}


                                                   translate='no'>$ {product.price.toFixed(2)}</div>
                                             </div>
                                             {/* <div className="d-flex bd-highlight text-muted opacity-75">
                                                <details open>
                                                   <summary> <small> Detalles </small> </summary>
                                                   {product.segments.map(item => (
                                                      <div className=" badge border border-light text-dark text-nowrap m-1">
                                                         <h6 className='d-block text-muted' >
                                                            {item.segment_name}:
                                                         </h6>
                                                         <h6> {item.name} <strong translate='no'>${item.price}</strong></h6>
                                                      </div>
                                                   ))}
                                                </details>
                                             </div> */}

                                             <div className={`d-flex bd-highlight text-muted opacity-75 position-relative d-flex justify-content-between align-items-center`}>

                                                <details close >
                                                   <summary className=''>
                                                      <span className='badge bg-light text-dark summary-text-pos  fw-bold border rounded-4  text-wrap '>
                                                         <i className="uil uil-info-circle  px-1 "></i>  Detalles
                                                      </span>
                                                   </summary>
                                                   {product.segments.map(item => (
                                                      <span className="badge bg-light text-dark  summary-text-pos m-2 border rounded-4 my-1 text-wrap ">
                                                         {/* <span className='kiosk-subtitle-s' >   </span> */}
                                                         <span className='text-muted m-0 p-0'>{item.segment_name}:</span>
                                                         <br />
                                                         <strong>
                                                            <span className='me-2'>{item.name} </span>
                                                            {parseFloat(item.price).toFixed(2)}
                                                         </strong>
                                                      </span>
                                                   ))}
                                                </details>

                                             </div>
                                             <RenderIf isTrue={product.note != ""}>
                                                <small className='text-danger p-1' >
                                                   Nota: {product.note}
                                                </small>
                                             </RenderIf>

                                             <RenderIf isTrue={product.quantity < product.amount}>
                                                <strong><small className='text-danger p-1' >
                                                   <i class="uil uil-exclamation-octagon"></i> Cantidad disponible: {product.quantity}
                                                </small></strong>
                                             </RenderIf>

                                             <div className="d-flex justify-content-between align-items-center">
                                                {/* <div className='px-1 d-flex justify-content-between align-items-center'>
                                                   <RenderIf isTrue={product.descuento == 0}><button
                                                      className="round-button decrement"
                                                      data-id={index}
                                                      onClick={e => decrementProductAmount(e.currentTarget.dataset.id)}>-</button></RenderIf>
                                                   <button
                                                      className="round-count">{product.amount}</button>
                                                   <RenderIf isTrue={product.descuento == 0}> <button
                                                      className="round-button increment"
                                                      data-id={index} onClick={e => incrementProductAmount(e.currentTarget.dataset.id)}>+</button></RenderIf>
                                                </div> */}

                                                <div className="d-flex align-items-center mt-3" role="group" aria-label="Basic outlined example">
                                                   {/*1*/}

                                                   <RenderIf isTrue={product.descuento == 0 || product.quantity < product.amount}>
                                                      <button type="button"
                                                         className={`btn-dash rounded-3`}
                                                         data-id={index}
                                                         onClick={e => decrementProductAmount(index, true)}>
                                                         {/* <i className="bi bi-dash"></i> */}
                                                         <span className='dash-icon-pos'>-</span>
                                                      </button>
                                                   </RenderIf>

                                                   {/*2*/}

                                                   <span className="btn  fw-bold border-0 count-items-pos fs-label-2  ">{product.amount}</span>

                                                   <RenderIf isTrue={product.descuento == 0}>
                                                      <button type="button"
                                                         className={`btn-plus rounded-3`}

                                                         data-id={index}
                                                         onClick={e => incrementProductAmount(index, true)}>
                                                         {/* <i className="bi bi-plus"></i> */}
                                                         <span className='plus-icon-pos fs-label-1 '>+</span>
                                                      </button>
                                                   </RenderIf>
                                                </div>
                                                {/* <div className=" ">
                                                      <span className='px-1 number-counter'>
                                                         <button
                                                         data-id={index} onClick={e => decrementProductAmount(e.currentTarget.dataset.id)}>-</button>
                                                         <p className='text-dark fw-bold'>{product.amount}</p>
                                                         <button data-id={index} onClick={e => incrementProductAmount(e.currentTarget.dataset.id)}>+</button>
                                                      </span>
                                                   </div>  */}
                                                {layoutConfig.mainContainer

                                                   ? <div className='mt-3'>
                                                      <small className='switch-scale text-capitalize mt-3'>
                                                         <Form.Switch
                                                            className='switch-scale fs-label-2'
                                                            type="switch"
                                                            id={product.group_id}
                                                            value={JSON.stringify(product)}
                                                            label={product.delivery_type}
                                                            defaultChecked={product.delivery_type === "comer aqui" ? true : false}
                                                            onChange={handleSwitchChange}
                                                         />
                                                      </small>
                                                   </div>

                                                   : <></>}

                                                <div className="bd-highlight fw-bold mt-3">
                                                   {preOrderInvoice == true ? <></> : <><button
                                                      className='btn btn-outline-danger btn-sm border-0 rounded-circle '
                                                      title="Eliminar Producto"
                                                      data-id={index}
                                                      onClick={e => deleteProduct(index, true)}>
                                                      <i className="uil uil-trash-alt icon-delete p-1"></i>
                                                   </button></>}

                                                </div>
                                             </div>

                                          </div>

                                          <span className={product.discount || product.unit_discount > 0
                                             ? "position-absolute top-0 start-50 translate-middle badge rounded-pill bg-warning"
                                             : product.quantity < product.amount ? "position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger" : "visually-hidden"} translate='no'>


                                             {product.quantity < product.amount ? <><i class="uil uil-exclamation-octagon"></i> Cantidad no disponible</> : <>Descuento aplicado: - {product.unit_discount}</>}
                                          </span>
                                       </div>
                                    </div>
                                 ))}
                                    {shoppingCartCombos.map((product, index) => (
                                       <div >
                                          <div className={product.discount || product.unit_discount > 0
                                             ? "d-flex border rounded border-warning border-2  mt-3 position-relative bg-white"
                                             : "d-flex rounded m-1 bg-white mt-1 card-pos"}
                                          >
                                             <div className="flex-shrink-0">
                                                <img className='m-1 rounded  bg-light border ' src={product.img === null || product.img === '' ? businessLogo : product.img} alt="..." width="80" height="80" />
                                             </div>
                                             <div className="flex-grow-1 m-1">
                                                <div className="d-flex bd-highlight">
                                                   <div className={layoutConfig.mainContainer
                                                      ? "bd-highlight title-product-cart mt-1 text-dark fw-bold"
                                                      : "bd-highlight title-product-cart-xl mt-1 text-dark fw-bold"}
                                                   >
                                                      {product.name}
                                                   </div>
                                                   <div className={layoutConfig.mainContainer
                                                      ? "ms-auto px-2 bd-highlight text-price-cart"
                                                      : "ms-auto px-2 bd-highlight text-price-cart-xl"}
                                                      translate='no'>$ {product.price.toFixed(2)}</div>
                                                </div>

                                                <div>
                                                   {product.setGroups.map((setGroup) => (
                                                      <div className="d-flex justify-content-between" key={setGroup.set_id}>
                                                         <details close >
                                                            <summary className=''>
                                                               <span className='badge bg-light text-dark summary-text-pos  fw-bold border rounded-4  text-wrap m-1 '>
                                                                  <i className="uil uil-info-circle  px-1 "> {setGroup.name}</i>
                                                               </span>
                                                            </summary>
                                                            {setGroup.groups.map((group) => (<>
                                                               {cardGroup(group)}
                                                            </>))}
                                                         </details>
                                                      </div>))}


                                                </div>
                                                <RenderIf isTrue={product.note != ""}>
                                                   <small className='text-danger p-1' >
                                                      Nota: {product.note}
                                                   </small>
                                                </RenderIf>

                                                <div className="d-flex justify-content-between align-items-center">
                                                   <div className="d-flex align-items-center" role="group" aria-label="Basic outlined example">
                                                      {/*1*/}

                                                      <RenderIf isTrue={product.descuento == 0}>
                                                         <button type="button"
                                                            className={`btn-dash rounded-3`}
                                                            data-id={index}
                                                            onClick={e => decrementProductAmount(index, false)}>
                                                            {/* <i className="bi bi-dash"></i> */}
                                                            <span className='dash-icon-pos'>-</span>

                                                         </button></RenderIf>

                                                      {/*2*/}

                                                      <span className="btn  fw-bold border-0 count-items-pos  ">{product.amount}</span>

                                                      <RenderIf isTrue={product.descuento == 0}>
                                                         <button type="button"
                                                            className={`btn-plus rounded-3`}

                                                            data-id={index}
                                                            onClick={e => incrementProductAmount(index, false)}>
                                                            {/* <i className="bi bi-plus"></i> */}
                                                            <span className='plus-icon-pos '>+</span>
                                                         </button>
                                                      </RenderIf>
                                                   </div>
                                                   {/* <div className=" ">
                                                      <span className='px-1 number-counter'>
                                                         <button
                                                         data-id={index} onClick={e => decrementProductAmount(e.currentTarget.dataset.id)}>-</button>
                                                         <p className='text-dark fw-bold'>{product.amount}</p>
                                                         <button data-id={index} onClick={e => incrementProductAmount(e.currentTarget.dataset.id)}>+</button>
                                                      </span>
                                                   </div>  */}
                                                   {layoutConfig.mainContainer

                                                      ? <div>
                                                         <small className='switch-scale text-capitalize'>
                                                            <Form.Switch
                                                               className='switch-scale'
                                                               type="switch"
                                                               id={product.group_id}
                                                               value={JSON.stringify(product)}
                                                               label={product.delivery_type}
                                                               defaultChecked={product.delivery_type === "comer aqui" ? true : false}
                                                               onChange={handleSwitchChange}
                                                            />
                                                         </small>
                                                      </div>

                                                      : <></>}


                                                   <div className="bd-highlight fw-bold">
                                                      <button
                                                         className='btn btn-outline-danger btn-sm border-0 rounded-circle '
                                                         title="Eliminar Producto"
                                                         data-id={index}
                                                         onClick={e => deleteProduct(index, false)}>
                                                         <i className="uil uil-trash-alt icon-delete p-1"></i>
                                                      </button>
                                                   </div>
                                                </div>

                                             </div>

                                             <span className={product.discount || product.unit_discount > 0
                                                ? "position-absolute top-0 start-50 translate-middle badge rounded-pill bg-warning"
                                                : "visually-hidden"} translate='no'>
                                                Descuento aplicado: - {product.unit_discount}
                                             </span>
                                          </div>
                                       </div>
                                    ))}</>
                           }
                        </div>
                     </div>

                     {/* MODULO */}
                  </div>


                  {/* 4.Totals and Delivery  */}
                  {layoutConfig.mainContainer
                     ? <>
                        {/* Delivery  */}
                        <div>
                           {/* MODULO */}
                           <div className='w-100 d-flex justify-content-center'>
                              <div className="btn-group  btn-group-sm " role="group" aria-label="Basic radio toggle button group">
                                 {buttonTypeOrdersDelivery()}
                              </div>
                           </div>
                        </div>
                        {/* Totals */}
                        <div className="item3 ">
                           <div className="mb-0 small lh-sm mt-1">
                              <div className="d-flex justify-content-between">
                                 {/* <div className="d-flex justify-content-between">
                                 <small className="text-muted h6 subtitle"> Descuento:</small>
                                 <span className="text-warning text-secondary h6 subtitle" translate='no'>
                                    <span className="px-2 subtitle">- $</span>
                                    <strong> {invoiceTotalDiscount.toFixed(2)} </strong>
                                 </span>
                              </div> */}
                                 <div className="d-flex m-1 text-center">
                                    <span className="text-muted border border-1 rounded-3 p-1 text-subtitel-order-pos  bg-light fs-label-1">
                                       Descuento: $
                                       <span translate='no' className='fw-bold'>{invoiceTotalDiscount.toFixed(2)}</span>
                                    </span>
                                 </div>
                                 {
                                    taxesArrayAll.map((tax) => (
                                       <>
                                          {
                                             tax.total.toFixed(2) > 0 ?

                                                <>
                                                   <div className="d-flex m-1 text-center ">
                                                      <span className="text-muted border border-1 rounded-3 p-1 text-subtitel-order-pos bg-light fs-label-1 bg-light">
                                                         Impuesto {tax.tax}:
                                                         <span translate='no' className='fw-bold text-center'>{tax.total.toFixed(2)}</span>
                                                      </span>
                                                   </div>
                                                   {/* 
                                          <div className="d-flex justify-content-between">
                                             <small className=" text-muted h6 subtitle"> Impuesto {tax.tax}:
                                             </small><span className="text-black text-secondary h6 subtitle" translate='no'>
                                                <span className="px-2 subtitle ">$</span>{tax.total.toFixed(3)}</span>
                                          </div> */}
                                                </>
                                                : <></>
                                          }
                                       </>
                                    ))}
                                 <div className="d-flex m-1 text-center">
                                    <span className="text-muted border border-1 rounded-3 p-1 text-subtitel-order-pos bg-light fs-label-1"> Subtotal: $
                                       <span translate='no' className='fw-bold text-center'>{invoiceSubtotal.toFixed(2)}</span>
                                    </span>
                                 </div>
                                 {/* <div className="d-flex justify-content-between ">
                                 <small className="text-muted h6 subtitle"> Subtotal: </small>
                                 <span className="text-black text-secondary h6 subtitle" translate='no'>
                                    <span className="px-2 subtitle">$</span>{invoiceSubtotal.toFixed(2)}</span>
                              </div> */}




                              </div>

                              <div className="d-flex justify-content-between total-shopping">
                                 <strong className="text-black fw-bold mt-1 subtitle fs-label-1"> Total <small>(+Impuestos)</small>: </strong>
                                 <span className="text-black fw-bold h5 subtitle fs-label-1" translate='no'>
                                    <span className="px-2 fs-label-1">$
                                    </span>{invoiceTotal.toFixed(2)}</span>
                              </div>
                           </div>
                           <div className='margin-btn-pay d-flex justify-content-center gab-md-1'>

                              <button
                                 className='btn btn-danger bg-danger bg-gradient btn-sm border-0 rounded-3 '
                                 title="Eliminar Producto"
                                 onClick={clearShoppingCart}  >
                                 {/* <i className="uil uil-trash-alt icon-delete p-1"></i> */}
                                 <span className='fs-label-1 fw-bold px-4'>Cancelar</span>
                              </button>

                              <ModalPaymet
                                 preOrderInvoice={preOrderInvoice}
                                 dv={dv} name={name} ruc={ruc}
                                 setDv={setDv} setName={setName}
                                 setRuc={setRuc}
                                 dataResponse={dataResponse}
                                 optionalData={optionalData}
                                 setDataResponse={setDataResponse}
                                 setOptionalData={setOptionalData}
                                 shoppingCart={shoppingCart}
                                 shoppingCartCombos={shoppingCartCombos}
                                 total={invoiceTotal}
                                 baseReducedTax={invoiceBaseReducedTax}
                                 subtotal={invoiceSubtotal}
                                 discount={invoiceTotalDiscount}
                                 tax={invoiceTotalItbms}
                                 setPageLoader={setPageLoader}
                                 dataPreOrder={dataPreOrder}
                                 checkDeliveryOrder={checkDeliveryOrder}
                                 registerTurnOrder={registerTurnOrder}
                                 setRegisterTurnOrder={setRegisterTurnOrder}
                                 client_phone={client_phone}
                                 setClient_phone={setClient_phone} locator={locator}
                                 setLocator={setLocator} dataClient={dataClient}
                                 setDataClient={setDataClient}
                                 listPaymentReady={listPaymentReady}
                                 setlistPaymentReady={setlistPaymentReady}
                                 amountPaymentReady={amountPaymentReady}
                                 setAmountPaymenReady={setAmountPaymenReady}
                                 tableInfo={tableInfo}
                                 taxesArrayAll={taxesArrayAll}
                                 dataDicountApply={dataDicountApply}
                                 invoiceTips={invoiceTips} setInvoiceTips={setInvoiceTips}
                                 moduleTips={moduleTips} recomendationTips={recomendationTips}
                                 totaltopay={totaltopay}
                                 setEventHandler={setEventHandler}
                                 pos_integration_name={pos_integration_name}
                                 dataReferenceOrderMicros={dataReferenceOrderMicros}
                                 parthner_order={parthner_order}
                                 codeParthner={codeParthner}
                                 yappy_transaction_id={yappy_transaction_id}
                                 setYappy_transaction_id={setYappy_transaction_id}

                              />
                           </div>

                           {/* Mobile menu Bar */}
                           <div className="item3 mobile-bar  my-0  positive-relative">
                              < div className=
                                 {
                                    !btnLayout.discountsPlus && !btnLayout.couponPlus && !btnLayout.pendingOrdersPlus && !btnLayout.returnPlus
                                       ? 'd-flex justify-content-center w-100 rounded border-top border-1 position-relative'
                                       : 'd-flex justify-content-around w-100 rounded border-top border-1 position-relative'
                                 }
                              >

                                 {
                                    btnLayout.discountsPlus
                                       ? <>
                                          <ModalDiscounts
                                             actvPlus={btnLayout.discountsPlus}
                                             productChecked={productChecked}
                                             shoppingCart={shoppingCart}
                                             shoppingCartCombos={shoppingCartCombos}
                                             total={invoiceTotal}
                                             subtotal={invoiceSubtotal}
                                             itbms={invoiceTotalItbms}
                                             discount={invoiceTotalDiscount}
                                             setLoad={setEventHandler}
                                             setShoppingCart={setShoppingCart}
                                             setShoppingCartCombos={setShoppingCartCombos} setProductChecked={setProductChecked} taxesArrayAll={taxesArrayAll}
                                             setCombosChecked={setCombosChecked}
                                             combosChecked={combosChecked}
                                             setdataDiscountApply={setdataDiscountApply}
                                             dataDicountApply={dataDicountApply}
                                             discount_validation={discount_validation}
                                             codeParthner={codeParthner} />
                                       </>
                                       : <></>
                                 }


                                 {
                                    btnLayout.couponPlus
                                       ? <>
                                          <ModalCoupons
                                             actvPlus={btnLayout.couponPlus}
                                             shoppingCart={shoppingCart}
                                             shoppingCartCombos={shoppingCartCombos}
                                             total={invoiceTotal}
                                             subtotal={invoiceSubtotal}
                                             itbms={invoiceTotalItbms}
                                             discount={invoiceTotalDiscount}
                                             setLoad={setEventHandler}
                                             setShoppingCart={setShoppingCart}
                                             taxesArrayAll={taxesArrayAll} />

                                       </>
                                       : <></>
                                 }
                                 <button className="btn btn-primary bg-brand-primary border-1 border-light   
                                 btn-menu-main rounded-4 py-3 px-4" type="button" onClick={() => setShow(true)}>
                                    <i className="uil uil-square-shape icon-mobile"></i>
                                    <small className='label-responsive  hidden-label-sm fw-bold'> Menú </small>
                                 </button>

                                 {
                                    btnLayout.pendingOrdersPlus
                                       ? <>
                                          <ModalPendingOrders
                                             actvPlus={btnLayout.pendingOrdersPlus}
                                             actvLite={btnLayout.pendingOrdersLite}


                                             preOrderInvoice={preOrderInvoice} setPreOrderInvoice={setPreOrderInvoice} setCheckDeliveryOrder={setCheckDeliveryOrder} setProductChecked={setProductChecked} setCombosChecked={setCombosChecked} setPageLoader={setPageLoader} setEventHandler={setEventHandler} shoppingCart={shoppingCart} shoppingCartCombos={shoppingCartCombos} setDv={setDv} setName={setName} setRuc={setRuc} setDataResponse={setDataResponse} setOptionalData={setOptionalData} setShoppingCart={setShoppingCart} setShoppingCartCombos={setShoppingCartCombos} setInvoiceSubtotal={setInvoiceSubtotal} setInvoiceTotal={setInvoiceTotal} setInvoiceTotalDiscount={setInvoiceTotalDiscount} setInvoiceTotalItbms={setInvoiceTotalItbms} setDataPreOrder={setDataPreOrder} setTableInfo={setTableInfo} setTaxesArrayAll={setTaxesArrayAll} setdataDiscountApply={setdataDiscountApply} setDataReferenceOrderMicros={setDataReferenceOrderMicros} codeParthner={codeParthner} />

                                       </>
                                       : <></>
                                 }
                                 {
                                    btnLayout.returnPlus
                                       ? <>
                                          <ModalReturn
                                             actvPlus={btnLayout.returnPlus}
                                             actvLite={btnLayout.returnLite}
                                             devolution_validation={devolution_validation}
                                             pos_integration_name={pos_integration_name}
                                             codeParthner={codeParthner}
                                          />
                                       </>
                                       : <></>
                                 }
                              </div>
                           </div>
                        </div>
                     </>
                     : <></>

                  }
                  {/* MODULO */}
                  {!layoutConfig.mainContainer
                     ? <>
                        <div className="item3 mobile-bar border-top shadow-sm py-2 ">
                           <div className="  ">
                              <div className="mb-0 small lh-sm mt-1">
                                 <div className="d-flex justify-content-between">
                                    <small className="text-muted h6 subtitle"> Descuento:</small>
                                    <span className="text-warning text-secondary h6 subtitle" translate='no'>
                                       <span className="px-2 subtitle">- $</span>
                                       <strong> {invoiceTotalDiscount.toFixed(2)} </strong>
                                    </span>
                                 </div>
                                 <div className="d-flex justify-content-between ">
                                    <small className="text-muted h6 subtitle"> Subtotal: </small>
                                    <span className="text-black text-secondary h6 subtitle" translate='no'>
                                       <span className="px-2 subtitle">$</span>{invoiceSubtotal.toFixed(2)}</span>
                                 </div>

                                 {taxesArrayAll.map((tax) => (
                                    <>
                                       {tax.total.toFixed(3) > 0 ?
                                          <div className="d-flex justify-content-between">
                                             <small className=" text-muted h6 subtitle"> Impuesto {tax.tax}:
                                             </small><span className="text-black text-secondary h6 subtitle" translate='no'>
                                                <span className="px-2 subtitle ">$</span>{tax.total.toFixed(3)}</span>
                                          </div> : <></>}
                                    </>
                                 ))}

                                 <div className="d-flex justify-content-between total-shopping">
                                    <strong className="text-black fw-bold h6 mt-1 subtitle"> Total <small>(Incluye itbms)</small>: </strong>
                                    <span className="text-black fw-bold h5 subtitle" translate='no'>
                                       <span className="px-2 ">$
                                       </span>{invoiceTotal.toFixed(2)}</span>
                                 </div>
                              </div>
                              <div className=' mb-0 '>
                                 <ModalPaymet
                                    preOrderInvoice={preOrderInvoice}
                                    dv={dv} name={name} ruc={ruc}
                                    setDv={setDv} setName={setName}
                                    setRuc={setRuc}
                                    dataResponse={dataResponse}
                                    optionalData={optionalData}
                                    setDataResponse={setDataResponse}
                                    setOptionalData={setOptionalData}
                                    shoppingCart={shoppingCart}
                                    total={invoiceTotal}
                                    subtotal={invoiceSubtotal}
                                    discount={invoiceTotalDiscount}
                                    tax={invoiceTotalItbms}
                                    setPageLoader={setPageLoader}
                                    dataPreOrder={dataPreOrder}
                                    checkDeliveryOrder={checkDeliveryOrder}
                                    registerTurnOrder={registerTurnOrder}
                                    setRegisterTurnOrder={setRegisterTurnOrder}
                                    client_phone={client_phone}
                                    setClient_phone={setClient_phone} locator={locator}
                                    setLocator={setLocator} dataClient={dataClient}
                                    setDataClient={setDataClient}
                                    listPaymentReady={listPaymentReady}
                                    setlistPaymentReady={setlistPaymentReady}
                                    amountPaymentReady={amountPaymentReady}
                                    setAmountPaymenReady={setAmountPaymenReady}
                                    tableInfo={tableInfo}
                                    taxesArrayAll={taxesArrayAll}
                                    dataDicountApply={dataDicountApply}
                                    invoiceTips={invoiceTips} setInvoiceTips={setInvoiceTips}
                                    moduleTips={moduleTips} recomendationTips={recomendationTips}
                                    totaltopay={totaltopay}
                                    setEventHandler={setEventHandler}
                                    pos_integration_name={pos_integration_name}
                                    dataReferenceOrderMicros={dataReferenceOrderMicros}
                                    parthner_order={parthner_order}
                                    codeParthner={codeParthner}
                                    yappy_transaction_id={yappy_transaction_id}
                                    setYappy_transaction_id={setYappy_transaction_id}
                                 />
                              </div>
                           </div>



                           {/* Mobile menu Bar */}
                           <div className='mobile-bar border-top py-2 overflow-auto'>
                              < div className='d-flex justify-content-center w-100 '>

                                 {
                                    btnLayout.discountsLite
                                       ? <>
                                          <ModalDiscounts
                                             actvPlus={btnLayout.discountsLite}
                                             productChecked={productChecked}
                                             shoppingCart={shoppingCart}
                                             shoppingCartCombos={shoppingCartCombos}
                                             total={invoiceTotal}
                                             subtotal={invoiceSubtotal}
                                             itbms={invoiceTotalItbms}
                                             discount={invoiceTotalDiscount}
                                             setLoad={setEventHandler}
                                             setShoppingCart={setShoppingCart}
                                             setShoppingCartCombos={setShoppingCartCombos} setProductChecked={setProductChecked} taxesArrayAll={taxesArrayAll}
                                             setCombosChecked={setCombosChecked}
                                             combosChecked={combosChecked}
                                             setdataDiscountApply={setdataDiscountApply}
                                             dataDicountApply={dataDicountApply}
                                             discount_validation={discount_validation}
                                             codeParthner={codeParthner} />
                                       </>
                                       : <></>
                                 }

                                 {
                                    btnLayout.couponLite
                                       ? <>
                                          <ModalCoupons
                                             actvPlus={btnLayout.couponLite}
                                             shoppingCart={shoppingCart}
                                             shoppingCartCombos={shoppingCartCombos}
                                             total={invoiceTotal}
                                             subtotal={invoiceSubtotal}
                                             itbms={invoiceTotalItbms}
                                             discount={invoiceTotalDiscount}
                                             setLoad={setEventHandler}
                                             setShoppingCart={setShoppingCart}
                                             taxesArrayAll={taxesArrayAll} />

                                       </>
                                       : <></>
                                 }
                                 {/* Menu Lite Model */}
                                 {btnLayout.menuLite ?
                                    <button className="btn btn-outline-primary btn-sm border border-1 rounded-pill me-1" type="button" onClick={() => setShow(true)}>
                                       <i className="uil uil-plus-circle display-6 buttons-label icon-mobile "></i>
                                       <span className='label-responsive  hidden-label-sm'>   Menú </span>
                                    </button>
                                    : <></>
                                 }

                                 {
                                    btnLayout.pendingOrdersLite
                                       ? <>
                                          <ModalPendingOrders
                                             actvPlus={btnLayout.pendingOrdersPlus}
                                             actvLite={btnLayout.pendingOrdersLite}


                                             preOrderInvoice={preOrderInvoice} setPreOrderInvoice={setPreOrderInvoice} setCheckDeliveryOrder={setCheckDeliveryOrder} setProductChecked={setProductChecked} setCombosChecked={setCombosChecked} setPageLoader={setPageLoader} setEventHandler={setEventHandler} shoppingCart={shoppingCart} shoppingCartCombos={shoppingCartCombos} setDv={setDv} setName={setName} setRuc={setRuc} setDataResponse={setDataResponse} setOptionalData={setOptionalData} setShoppingCart={setShoppingCart} setShoppingCartCombos={setShoppingCartCombos} setInvoiceSubtotal={setInvoiceSubtotal} setInvoiceTotal={setInvoiceTotal} setInvoiceTotalDiscount={setInvoiceTotalDiscount} setInvoiceTotalItbms={setInvoiceTotalItbms} setDataPreOrder={setDataPreOrder} setTableInfo={setTableInfo} setTaxesArrayAll={setTaxesArrayAll} setdataDiscountApply={setdataDiscountApply} setDataReferenceOrderMicros={setDataReferenceOrderMicros} codeParthner={codeParthner}
                                          />

                                       </>
                                       : <></>
                                 }

                                 {
                                    btnLayout.returnLite
                                       ? <>
                                          <ModalReturn
                                             actvPlus={btnLayout.returnPlus}
                                             actvLite={btnLayout.returnLite}
                                             devolution_validation={devolution_validation}
                                             pos_integration_name={pos_integration_name}
                                             codeParthner={codeParthner}
                                          />
                                       </>
                                       : <></>
                                 }
                              </div>
                           </div>

                        </div>
                     </>
                     : <></>

                  }
                  {/* MODULO */}
               </div>
            </div>
            {/* MODULO */}
            <div className="container-pos-right no-scroll ">
               <section className='h-100'>

                  {layoutConfig.mainContainer
                     ?
                     <>
                        {layoutConfig.mainContainer && <>
                           <div className="flex-container">
                              <div className="item1 mt-5">
                                 <div className='d-flex justify-content-between'>
                                    <span className='fw-bold px-2'>
                                       <i className="uil uil-apps"></i>
                                       <small className='fw-bold mx-1 menu-label'>Menú</small>
                                    </span>
                                 </div>

                                 {
                                    !categories.length
                                       ? ''//No hay categorias Asignadas
                                       : <>
                                          <div className="bg-light   position-relative categories-list-post rounded border border-inset ">
                                             <div className="container-list no-scroll mx-1">
                                                <ul>
                                                   <li>
                                                      <div className="button-container ">
                                                         <input type="radio" className="btn-check"
                                                            name="options-outlined"
                                                            key={0} id="cateforieAll"
                                                            value={"todos"}
                                                            onChange={(e) => selectCategorie(e.target.value)}
                                                         />
                                                         <label className="btn btn-outline-primary rounded-3 border" htmlFor="cateforieAll">
                                                            <img className='rounded-circle' src={all_products} alt="Imagen 1" />
                                                            Ver Todos
                                                         </label>
                                                      </div>
                                                   </li>
                                                   <li>
                                                      <div className="button-container ">
                                                         <input type="radio" className="btn-check"
                                                            name="options-outlined"
                                                            id="promociones"
                                                            value={"promociones"}
                                                            onChange={(e) => selectCategorie(e.target.value)}
                                                         />
                                                         <label className="btn btn-outline-primary rounded-3 border" htmlFor="promociones">
                                                            <img className='rounded-circle' src={promotion_img} alt="Imagen 2" />
                                                            Promociones
                                                         </label>
                                                      </div>
                                                   </li>

                                                   {listCategories()}
                                                </ul>
                                             </div>
                                             <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-secondary">
                                                Categorias
                                             </span>
                                          </div>
                                       </>
                                 }

                              </div>

                              <div className="item2 py-0 px-1">
                                 <div className=" px-1  bd-highlight shopping-wrapper border  border-inset rounded no-scroll bg-body pt-3" >
                                    {/* <div className='row g-2  no-scroll pt-1'>
                                          {!filteredProducts.length
                                             ? <Empty title="Vacío" msg="No hay ningun producto asignado en esta categoria" img={sad_products} />
                                             : filteredProducts.map((product) => (
                                                <>
                                                   <div className="col-4 col-md-4 col-lg-2"
                                                      data-product={JSON.stringify(product)}
                                                      onClick={(e) => (setShowModalSelectItem(true), selectProductoInfo(product))}
                                                   >
                                                      <div className="card border shadow-sm ">
                                                         <img src={product.img} className="card-img-top-sp rounded" alt="Producto" />

                                                         <div className="card-body">
                                                            <h5 className="title-product-pos h6 text-center text-dark"> {product.name}</h5>
                                                            <h6 className="card-text text-center text-success fw-bold" translate='no'>${product.price}</h6>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </>
                                             ))
                                          }
                                       </div> */}
                                    <div className='row  g-2  no-scroll menu-pre '>
                                       {!filteredProducts.length
                                          ? <Empty title="Vacío" msg="No hay ningun producto asignado en esta categoria" img={sad_products} />
                                          : filteredProducts.map((product) => (
                                             <>
                                                <div className="col-4 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 col-xxl-2"
                                                   data-product={JSON.stringify(product)}
                                                   onClick={(e) => (getInventory(product))}
                                                >
                                                   <div className="card border overflow-hidden ">
                                                      <img src={product.img === null || product.img === '' ? businessLogo : product.img} className="card-img-pre-acoount" alt="Product" loading='lazy' />

                                                      <div className="card-body">
                                                         <h5 className="card-title text-center fw-bold text-truncate text-dark"> {product.name}</h5>
                                                         <h6 className="card-text text-center text-success fw-bold">{product.group_id ? <>${product.price}</> : <></>}</h6>
                                                      </div>
                                                   </div>
                                                </div>
                                             </>
                                          ))
                                       }
                                    </div>
                                 </div>
                              </div>
                              <div className="item3 d-flex justify-content-end text-muted ">
                                 <small className='brand-text position-absolute bottom-0 end-0'>
                                    Powered By Smart Retail Group
                                 </small>
                              </div>
                           </div>
                        </>}
                     </>
                     :
                     <>
                        <div className="flex-container">
                           <header className='mx-1'>
                              <nav className="px-1 fixed-top-sp border-bottom">
                                 <div className="">
                                    <div className="d-flex  align-items-center justify-content-between">
                                       <div className='d-flex  bd-highlight  align-items-center'>
                                          <span className="h5 pt-2 me-3 fw-bold align-self-center title-1">Gestión de Transacciones</span>
                                       </div>
                                       <div>
                                          {
                                             jsonConfig.buttons.menu.topNav

                                                ?
                                                <>
                                                   <button className='btn btn-outline-primary btn-sm border border-1 rounded-pill mx-1' onClick={() => setShow(true)}>
                                                      <i className="uil uil-bars"></i>
                                                      <small className='fw-bold fw-bold mx-1 menu-label'>Productos</small>
                                                   </button>
                                                </>
                                                :
                                                <>
                                                </>
                                          }
                                       </div>
                                    </div>
                                 </div>
                              </nav>
                           </header>
                           {/* <button className='btn btn-primary btn-sm'
                                 onClick={toggleContenedor1} >

                              Menu lIte de bootnes de menu

                              </button> */}
                           <div className="item1">
                              <div className="d-flex flex-row  justify-content-center overflow-auto border-end border-start border-2">
                                 {

                                    !layoutConfig.mainContainer

                                       ?
                                       <>

                                          {
                                             btnLayout.menuLite

                                                ?
                                                <>
                                                   <div className="  m-1  bg-white border rounded">
                                                      <button className="btn btn-outline-primary  py-2 border-0" type="button"
                                                         onClick={() => setShow(true)}
                                                      >
                                                         <i className="uil uil-list-ul buttons-label icon-mobile"></i>
                                                         <span className='h5 fw-bold mx-1 d-block text-center'>
                                                            Menú
                                                         </span>
                                                      </button>
                                                   </div>
                                                </>
                                                :
                                                <>
                                                </>
                                          }

                                          {/* Btn Lite Version  */}
                                          {
                                             btnLayout.discountsLite
                                                ?
                                                <>
                                                   <ModalDiscounts actvLite={btnLayout.discountsLite} productChecked={productChecked} shoppingCart={shoppingCart} shoppingCartCombos={shoppingCartCombos} total={invoiceTotal} subtotal={invoiceSubtotal} itbms={invoiceTotalItbms} discount={invoiceTotalDiscount} setLoad={setEventHandler} setShoppingCart={setShoppingCart} setShoppingCartCombos={setShoppingCartCombos} setProductChecked={setProductChecked} taxesArrayAll={taxesArrayAll} setCombosChecked={setCombosChecked} combosChecked={combosChecked} setdataDiscountApply={setdataDiscountApply}
                                                      dataDicountApply={dataDicountApply} discount_validation={discount_validation} codeParthner={codeParthner} />
                                                </>
                                                :
                                                <>
                                                </>
                                          }



                                          {/* Btn Lite Version  */}
                                          {
                                             btnLayout.couponLite
                                                ?
                                                <>
                                                   <ModalCoupons actvLite={btnLayout.couponLite} shoppingCart={shoppingCart} shoppingCartCombos={shoppingCartCombos} total={invoiceTotal} subtotal={invoiceSubtotal} itbms={invoiceTotalItbms} discount={invoiceTotalDiscount} setLoad={setEventHandler} setShoppingCart={setShoppingCart} taxesArrayAll={taxesArrayAll} />
                                                </>
                                                :
                                                <>
                                                </>
                                          }


                                          {/* Btn Lite Version  */}
                                          {
                                             btnLayout.pendingOrdersLite
                                                ?
                                                <>
                                                   <ModalPendingOrders
                                                      actvPlus={btnLayout.pendingOrdersPlus}
                                                      actvLite={btnLayout.pendingOrdersLite}


                                                      preOrderInvoice={preOrderInvoice} setPreOrderInvoice={setPreOrderInvoice} setCheckDeliveryOrder={setCheckDeliveryOrder} setProductChecked={setProductChecked} setCombosChecked={setCombosChecked} setPageLoader={setPageLoader} setEventHandler={setEventHandler} shoppingCart={shoppingCart} shoppingCartCombos={shoppingCartCombos} setDv={setDv} setName={setName} setRuc={setRuc} setDataResponse={setDataResponse} setOptionalData={setOptionalData} setShoppingCart={setShoppingCart} setShoppingCartCombos={setShoppingCartCombos} setInvoiceSubtotal={setInvoiceSubtotal} setInvoiceTotal={setInvoiceTotal} setInvoiceTotalDiscount={setInvoiceTotalDiscount} setInvoiceTotalItbms={setInvoiceTotalItbms} setDataPreOrder={setDataPreOrder} setTableInfo={setTableInfo} setTaxesArrayAll={setTaxesArrayAll} setdataDiscountApply={setdataDiscountApply} setDataReferenceOrderMicros={setDataReferenceOrderMicros} codeParthner={codeParthner} />
                                                </>
                                                :
                                                <>
                                                </>
                                          }

                                          {/* Btn Lite Version  */}
                                          {
                                             btnLayout.returnLite
                                                ?
                                                <>
                                                   <ModalReturn
                                                      actvPlus={btnLayout.returnPlus}
                                                      actvLite={btnLayout.returnLite}
                                                      devolution_validation={devolution_validation}
                                                      pos_integration_name={pos_integration_name}
                                                      codeParthner={codeParthner}
                                                   />
                                                </>
                                                :
                                                <>
                                                </>
                                          }

                                       </>
                                       :
                                       <>

                                       </>
                                 }
                                 {/* <div className="  m-1  bg-white border rounded">
                                       <button className="btn btn-outline-primary  py-2 border-0" type="button" onClick={toggleContenedor1}>
                                          <i className="uil uil-list-ul buttons-label icon-mobile h3"></i>
                                          <span className='h5 fw-bold mx-1 d-block text-center'>
                                             Menú
                                          </span>
                                       </button>
                                    </div> */}
                                 {/* <div className=" m-1 bg-white border rounded ">
                                       <button className="btn btn-outline-primary  py-2 border-0" type="button">
                                          <i className="uil uil-percentage buttons-label icon-mobile h3 "></i>
                                          <span className='h5 fw-bold mx-1 d-block text-center'>  Descuentos </span>
                                       </button>
                                    </div>
                                    <div className="m-1 bg-white border rounded ">
                                       <button className="btn btn-outline-primary  py-2 border-0" type="button">
                                          <i className="il uil-history-alt buttons-label icon-mobile h3 "></i>
                                          <span className='h5 fw-bold mx-1 d-block text-center'>
                                             Devolución
                                          </span>
                                       </button>
                                    </div> */}
                                 {/* <div className="m-1 bg-white border rounded">
                                       <button className="btn btn-outline-primary  py-2 border-0" type="button">
                                          <i className="uil uil-ticket buttons-label icon-mobile h3 "></i>
                                          <span className='h5 fw-bold mx-1 d-block text-center d-block text-center'>
                                             Cupones
                                          </span>
                                       </button>
                                    </div>

                                    <div className="m-1 bg-white border rounded">
                                       <button className="btn btn-outline-primary py-2 border-0" type="button">
                                          <i className="uil uil-book-reader buttons-label icon-mobile h3 "></i>
                                          <span className='h5 fw-bold mx-1 d-block text-center'>
                                             Mesas
                                          </span>
                                       </button>
                                    </div> */}
                              </div>
                           </div>
                           <div className='item2 '>

                           </div>
                           <div className="item3 m-1 py-0 px-1 border mx-2 shadow-sm bg-white">
                              <div className='m-2'>
                                 <h4 className='fw-bold  text-primary'>
                                    <i className="uil uil-shopping-cart"></i> Información de Orden
                                 </h4>

                                 <Totals
                                    count={shoppingCart}
                                    discount={invoiceTotalDiscount}
                                    subtotal={invoiceSubtotal}
                                    itbms={invoiceTotalItbms}
                                    total={invoiceTotal}
                                 />
                                 <div className=' mb-0'>
                                    <ModalPaymet
                                       preOrderInvoice={preOrderInvoice}
                                       dv={dv} name={name} ruc={ruc}
                                       setDv={setDv} setName={setName}
                                       setRuc={setRuc}
                                       dataResponse={dataResponse}
                                       optionalData={optionalData}
                                       setDataResponse={setDataResponse}
                                       setOptionalData={setOptionalData}
                                       shoppingCart={shoppingCart}
                                       total={invoiceTotal}
                                       baseReducedTax={invoiceBaseReducedTax}
                                       subtotal={invoiceSubtotal}
                                       discount={invoiceTotalDiscount}
                                       tax={invoiceTotalItbms}
                                       setPageLoader={setPageLoader}
                                       dataPreOrder={dataPreOrder}
                                       checkDeliveryOrder={checkDeliveryOrder}
                                       registerTurnOrder={registerTurnOrder}
                                       setRegisterTurnOrder={setRegisterTurnOrder}
                                       client_phone={client_phone}
                                       setClient_phone={setClient_phone} locator={locator}
                                       setLocator={setLocator} dataClient={dataClient}
                                       setDataClient={setDataClient}
                                       listPaymentReady={listPaymentReady}
                                       setlistPaymentReady={setlistPaymentReady}
                                       amountPaymentReady={amountPaymentReady}
                                       setAmountPaymenReady={setAmountPaymenReady}
                                       tableInfo={tableInfo}
                                       taxesArrayAll={taxesArrayAll}
                                       dataDicountApply={dataDicountApply}
                                       invoiceTips={invoiceTips} setInvoiceTips={setInvoiceTips}
                                       moduleTips={moduleTips} recomendationTips={recomendationTips}
                                       totaltopay={totaltopay}
                                       setEventHandler={setEventHandler}
                                       pos_integration_name={pos_integration_name}
                                       dataReferenceOrderMicros={dataReferenceOrderMicros}
                                       parthner_order={parthner_order}
                                       codeParthner={codeParthner}
                                       yappy_transaction_id={yappy_transaction_id}
                                       setYappy_transaction_id={setYappy_transaction_id}
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </>
                  }
               </section>
            </div>
            {/* MODULO */}
         </main>


         {/* Modal-Products Information*/}
         <Modal
            className='bg-blur-sp'
            show={showModalSelectItem}
            size="xl"
            centered
            onHide={() => (setShowModalSelectItem(false), setPreviewSegment([]), setComboSelect([]), setActiveTab(0), clearDataInfoProduct())}>
            <Modal.Body >


               <div className="d-flex justify-content-center border-bottom-0">
                  <h2 className="modal-title fw-bold fs-5">
                     <i class="uil uil-label"></i>
                     {selectProduct.name}
                  </h2>
               </div>
               <div class="d-flex align-items-center border rounded-3 shadow-sm p-1">
                  <div class="flex-shrink-0">
                     <img className='rounded-sp border bg-secondary ' width="80px" height="80px"
                        src={selectProduct.img === null || selectProduct.img === '' ? businessLogo : selectProduct.img} alt="img-product "
                     />
                  </div>
                  <div class="flex-grow-1 ms-3 text-description-info">
                     {selectProduct.description}
                  </div>
                  <div className='product-count'>

                     {/*temporal renderif for change price product*/}
                     {/* <RenderIf isTrue={selectProduct.name == "Medicamentos"}>
                     <div className='m-5'>
                        <h3 >Modificar precio</h3>
                        <div className="col-6 col-md-8">

                           <label>Precio</label>
                           <input type="text" className="form-control" placeholder={"$ " + selectProduct.price} onChange={(e) => (handlerchangePriceProductGeneric(e))} />
                        </div>
                        <div className="col-6 col-md-8">
                           <label>Impuesto $</label>
                           <input type="text" className="form-control" placeholder={"$" + selectProduct.taxtValue} onChange={(e) => (handlerchangeTaxProductGeneric(e))} />
                        </div>

                     </div>
                  </RenderIf>
                  <h6 className="fw-bold text-center mb-2" >Precio base: <p translate='no'>${parseFloat(selectProduct.price).toFixed(2)}</p> </h6>
                  <h5 className="fw-bold text-center mb-2" translate='no'>{"$ " + previewPriceItemsegmentSelect.toFixed(2)}</h5> */}

                     <div className='px-1 d-flex justify-content-center align-items-center'>
                        <button
                           className="round-button decrement m-1"
                           onClick={decrementNumber}>-</button>
                        <button
                           className="round-count">{number}</button>
                        <button
                           className="round-button increment  m-1" onClick={incrementNumber}>+</button>
                     </div>
                     {/* {viewSelectSegmentItem()} */}
                  </div>
               </div>
               <div className='flex-container'>
                  {/* Product's Suggestions */}
                  {segments.length > 0 ? <>
                     <div className='item2'>
                        {/* <div className='suggestions-over'>*/}
                        <div className='item2 border-inset bg-light-subtle border border-2 border-1 border-light-subtle rounded-3 list-discount-framer-2 align-self-center  w-100'>
                           {contentSegments()}
                        </div>
                        {/*  </div> */}
                     </div>
                  </> : <></>}

                  {selectProduct.combo_id ? <>

                     {
                        <div className="bg-light   position-relative categories-list-post rounded border border-inset ">
                           <div className="container-list no-scroll mx-1">
                              <ul>
                                 {selectProduct.setGroups.map((setGroup, index) => (
                                    <>
                                       <li>
                                          <button
                                             key={index}
                                             className={setGroup.ready == true ? 'btn btn-success rounded-3 border m-1' : index === activeTab ? 'btn btn-primary rounded-3 border m-1' : 'btn btn-outline-primary rounded-3 border '}
                                             onClick={() => changeTab(index)}
                                          >
                                             {setGroup.name} {setGroup.ready == true ? <><i class="uil uil-check-circle"></i></> : <></>}
                                          </button>

                                       </li>

                                    </>
                                 ))}

                                 <div className='item2'>
                                    {/* <div className='suggestions-over'>*/}

                                    <div className=''>
                                       <div>
                                          <h5 className='pt-1 fw-bold text-underline'>
                                             <u>{selectProduct.setGroups[activeTab].name}</u>
                                          </h5>
                                       </div>
                                       <div className=''>
                                          <div className="mt-1 ms-1 overflow-auto d-flex gap-3">
                                             {selectProduct.setGroups[activeTab].groups.map((group) => (
                                                <>
                                                   <article className='my-1 list-info-products-inputs  border-0 card-product-segments-kiosk' onClick={() => actionCheckIngradent(group, "setGroup" + selectProduct.setGroups[activeTab].set_id + group.group_id, true)} >


                                                      <input
                                                         className="list-group-products-check "
                                                         type={selectProduct.setGroups[activeTab].count > 1 ? "checkbox" : "radio"}
                                                         name={"setGroup" + selectProduct.setGroups[activeTab].set_id}
                                                         id={"setGroup" + selectProduct.setGroups[activeTab].set_id + group.group_id}
                                                         value=""
                                                         checked={false}
                                                      />
                                                      <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-white p-1 shadow-sm position-relative h-100" >
                                                         {group.name}
                                                         <div className="d-flex align-items-center gap-3 py-3 rounded" >
                                                            <img src={group.img === null ? img_default : group.img}
                                                               alt="Product" width="50" height="50" className="rounded-3 flex-shrink-0 " />
                                                            <div className="d-flex gap-2 justify-content-around">

                                                               <div className='my-2'>
                                                                  <span className=" d-block mb-0 fw-normal text-wrap ">
                                                                     {group.description}
                                                                  </span>

                                                               </div>
                                                            </div>
                                                         </div>
                                                         <span class="badge bg-success-subtle text-success-emphasis rounded-pill bottom-0 end-0 position-absolute m-1">
                                                            {group.price == 0 ? "+ $0.00" : "+ $" + group.price.toFixed(2)}</span>
                                                      </label>


                                                   </article>
                                                </>
                                             ))}
                                          </div>
                                       </div>
                                    </div>
                                    {/*  </div> */}
                                 </div>

                              </ul>
                           </div>
                           <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-secondary">
                              Pasos
                           </span>


                           {comboSelect.length > 0 ? <>
                              <div className='m-3'>
                                 <h5 className='pt-1 fw-bold text-underline'>
                                    Resumen del combo
                                 </h5>
                                 <div className="mt-1 ms-1 overflow-auto d-flex gap-3">
                                    {comboSelect.map((setGroup) => (
                                       <>
                                          {setGroup.groupsselected.map((group, index) => (<>
                                             <article className='my-1 list-info-products-inputs  border-0 card-product-segments-kiosk'  >



                                                <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-white p-1 shadow-sm position-relative h-100" >
                                                   {setGroup.name}: {group.name}
                                                   <div className="d-flex align-items-center gap-3 py-3 rounded mb-2" >
                                                      <img src={group.img === null ? img_default : group.img}
                                                         alt="Product" width="50" height="50" className="rounded-3 flex-shrink-0 " />
                                                      <div className="d-flex gap-2 justify-content-around">

                                                         <div className='my-2'>
                                                            <span className=" d-block mb-0 fw-normal text-wrap ">
                                                               {group.description}
                                                            </span>

                                                            <div className={`d-flex bd-highlight text-muted opacity-75 position-relative justify-content-between align-items-center p-1`}>
                                                               <details close >
                                                                  <summary className=''>
                                                                     <span className='badge bg-light text-dark summary-text-pos  fw-bold border rounded-4  text-wrap '>
                                                                        <i className="uil uil-info-circle  px-1 "></i>  Detalles
                                                                     </span>
                                                                  </summary>
                                                                  <div className='overflow-auto'>
                                                                     {group.segments.map(item => (
                                                                        <span className="badge bg-light text-dark  summary-text-pos m-2 border rounded-4 my-1 text-wrap ">
                                                                           {/* <span className='kiosk-subtitle-s' >   </span> */}
                                                                           <span className='text-muted m-0 p-0'>{item.segment_name}:</span>
                                                                           <br />
                                                                           <strong>
                                                                              <span className='me-2'>{item.name} </span>
                                                                              {parseFloat(item.price).toFixed(2)}
                                                                           </strong>
                                                                        </span>
                                                                     ))}</div>
                                                               </details>

                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>

                                                   <div className="bd-highlight fw-bold bottom-0 end-0 position-absolute m-1">
                                                      <span class="badge bg-success-subtle text-success-emphasis rounded-pill  m-1">
                                                         {group.price == 0 ? "+ $0.00" : "+ $" + group.price.toFixed(2)}</span>

                                                      <button
                                                         className='btn btn-warning btn-sm border-0 rounded-circle '
                                                         title="Modificar Producto"
                                                         data-id={index}
                                                         onClick={() => (setActiveTab(setGroup.activeTab), getdataGroup(group, false, true))}
                                                      >
                                                         <i className="uil uil-edit text-wrap"></i>
                                                      </button>
                                                      <button
                                                         className='btn btn-outline-danger btn-sm border-0 rounded-circle '
                                                         title="Eliminar Producto"
                                                         data-id={index}
                                                         onClick={() => deleteSetGroupOfComboSelected(setGroup.activeTab)}>
                                                         <i className="uil uil-trash-alt text-wrap"></i>
                                                      </button>
                                                   </div>

                                                </label>


                                             </article>
                                          </>))}
                                       </>
                                    ))}
                                 </div>
                              </div></> : <></>}

                        </div>


                     }
                  </> : <></>}
                  <div>
                     <Form>
                        <Form.Group className="m-3" controlId="exampleForm.ControlTextarea1">
                           <Form.Label>Notas:</Form.Label>
                           <Form.Control as="textarea" rows={1} onChange={(e) => (setNotesItem(e.target.value))} />
                        </Form.Group>
                     </Form>
                  </div>

                  <div className='d-block mt-lg-1 mt-0'>
                     <div className='d-flex justify-content-end mt-2'>
                        <button className='btn bg-danger-subtle text-danger  border-0 fw-bold '
                           onClick={() => (setShowModalSelectItem(false), setPreviewSegment([]), setComboSelect([]), setActiveTab(0), clearDataInfoProduct(true))}> Cancelar </button>

                        {selectProduct.combo_id ? <><button className='btn bg-success-subtle text-success fw-bold border-0  ms-2'
                           onClick={() => stepSaveCombos(true)}> Siguiente </button></> : <><button className='btn bg-success-subtle text-success fw-bold border-0  ms-2'
                              onClick={e => (setShowModalSelectItem(false), setShow(false), addProduct(e))}> Agregar al carrito </button></>}

                     </div>
                  </div>
               </div>
            </Modal.Body>
         </Modal>


         {/* Modal Mobile */}
         <Modal
            show={show}
            className='rouneded'
            onHide={() => setShow(false)}
            size="xl"
            centered
            aria-labelledby="example-custom-modal-styling-title"
         >

            <Modal.Body className='p-2 m-0'>

               <div className="d-flex justify-content-center border-bottom-0">
                  <h1 className="modal-title fw-bold h1">
                     <i className="uil uil-receipt"></i>
                     Menú
                  </h1>
                  {/* <button type="button" className="btn-close rounded"
                     onClick={() => (setShow(false), setProductChecked([]))}></button> */}
               </div>

               <div className='flex-container'>
                  <div className='d-flex justify-content-between'>
                     <p className='p-0  text-primary fw-bold my-1'>Productos </p>
                     <form className="button-container">
                        <div>
                           <input
                              spellcheck="false"
                              className="form-control rounded-pill form-control-sm mx-2 bg-gray"
                              type="text"
                              step="any"
                              placeholder="Buscar productos..."
                              onChange={e => setSearchTerm(e.target.value)}
                           />
                        </div>
                     </form>

                  </div>
                  <div>
                     {
                        !categories.length
                           ? ''
                           : <>
                              <div className="bg-light   position-relative categories-list-post rounded border border-inset ">
                                 <div className="container-list no-scroll mx-1">
                                    <ul>
                                       <li>
                                          <div className="button-container">
                                             <input type="radio" className="btn-check"
                                                name="options-outlined-modal"
                                                key={0} id="cateforieAllx"
                                                value={"todos"}
                                                onChange={(e) => selectCategorie(e.target.value)}
                                             />
                                             <label className="btn btn-outline-primary rounded-3 border"
                                                htmlFor="cateforieAllx">
                                                <img className='rounded-circle' src={all_products} alt="Imagen 1" loading='lazy' />
                                                Ver Todos
                                             </label>
                                          </div>
                                       </li>

                                       <li>
                                          <div className="button-container ">
                                             <input type="radio" className="btn-check"
                                                name="options-outlined-modal"
                                                id="promocionesx"
                                                value={"promociones"}
                                                onChange={(e) => selectCategorie(e.target.value)}
                                             />
                                             <label className="btn btn-outline-primary rounded-3 border"
                                                htmlFor="promocionesx">
                                                <img className='rounded-circle' src={promotion_img} alt="Imagen 2" loading='lazy' />
                                                Promociones
                                             </label>
                                          </div>
                                       </li>


                                       {categories.map((categorie) => (
                                          <>
                                             {/* <option value={categorie.category_id} key={categorie.category_id}  >{categorie.category_name}</option> */}
                                             {/* <a className="nav-link"> {categorie.category_name}</a> */}
                                             <li>
                                                <div className="button-container">
                                                   <input type="radio" className="btn-check"
                                                      id={categorie.category_id + 'x'}
                                                      name='options-outlined-modal'
                                                      value={JSON.stringify(categorie)} autocomplete="off" onChange={(e) => selectCategorie(categorie)} />
                                                   <label className="btn btn-outline-primary rounded-3 border"
                                                      htmlFor={categorie.category_id + 'x'}>
                                                      <img className='rounded-circle' src={categorie.img === null ? img_default : categorie.img} alt="Imagen 1" />
                                                      {categorie.category_name}
                                                   </label>
                                                </div>
                                             </li>
                                          </>
                                       ))}
                                    </ul>
                                 </div>

                              </div>
                           </>
                     }

                  </div>

                  <div className={"row item2  border-inset bg-light-subtle border border-2 border-1 border-light-subtle rounded-3 list-menu-framer align-self-center  w-100"}>
                     {/*<div className='row  g-2  no-scroll menu-pre '>
                        {!filteredProducts.length
                           ? <Empty title="Vacío" msg="No hay ningun producto asignado en esta categoria" img={sad_products} />
                           : filteredProducts.map((product) => (
                              <>
                                 <div className="col-12 col-xs-2 col-sm-3 col-md-2 col-lg-3 mt-1"
                                    data-product={JSON.stringify(product)}
                                    onClick={(e) => (selectProductoInfo(product))}
                                 >
                                    <div className="card border-0 shadow-sm ">
                                       <img src={product.img} className="card-img-pre-acoount" alt="Product" loading='lazy' />

                                       <div className="card-body">
                                          <h5 className="title-product-pos h6 text-center text-dark"> {product.name}</h5>
                                          <h6 className="card-text text-center text-success fw-bold">${product.price}</h6>
                                       </div>
                                    </div>
                                 </div>
                              </>
                           ))}
                     </div>*/}
                     <div className='row item2  border-inset bg-light-subtle border border-2 border-1 border-light-subtle rounded-3 list-menu-framer align-self-center  w-100'>
                        {!filteredProducts.length
                           ? <Empty title="Vacío" msg="No hay ningun producto asignado en esta categoria" img={sad_products} />
                           : filteredProducts.map((product) => (
                              <>
                                 <div className="col-6 col-xs-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-xxl-2 border-product mt-2"
                                    data-product={JSON.stringify(product)}
                                    onClick={(e) => (getInventory(product))}
                                 >
                                    <div className="card border-0 shadow-sm  ">
                                       <img src={product.img} className="card-img-pre-acoount" alt="Product" loading='lazy' />

                                       <div className="card-body">
                                          <h5 className="card-title text-center fw-bold text-truncate text-dark"> {product.name}</h5>
                                          <h6 className="card-text text-center text-success fw-bold">{product.group_id ? <>${product.price}</> : <></>}</h6>
                                       </div>
                                    </div>
                                 </div>
                              </>
                           ))
                        }
                     </div>
                  </div>
               </div >
               <button type="button" className="btn-danger btn  rounded mt-2"
                  onClick={() => (setShow(false), setProductChecked([]), setCombosChecked([]))}>Cerrar</button>
            </Modal.Body>
         </Modal>

         <ModalWaiters showWaiters={showWaiters} setShowWaiters={setShowWaiters} businessLogo={businessLogo} parthners={parthners} logo={businessLogo} selectRestaurant={selectRestaurant} setResetData={setResetData} calculateInvoiceTotals={calculateInvoiceTotals} shoppingCart={shoppingCart} shoppingCartCombos={shoppingCartCombos} codeParthner={codeParthner} setShoppingCart={setShoppingCart} setShoppingCartCombos={setShoppingCartCombos} ></ModalWaiters>
         <ModalIngredient setShowModalIngredient={setShowModalIngredient} showModalIngredient={showModalIngredient} groupNow={groupNow} businessLogo={businessLogo} formatJsonToAddShoppingCart={formatJsonToAddShoppingCart} segmentsNow={segmentsNow} img_default={img_default} />
      </>
   )
}

export default PaymentPost