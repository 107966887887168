import React, { useState, useEffect } from 'react'
import CardPayment from './CardPayment'
import QRCode from "react-qr-code";

import { FilterOrderFetchAPI } from '../../api/Paymentpost/FilterOrder';
import { PaymentMethodFetchAPI } from '../../api/Paymentpost/PaymentMethod';
import { AuthDgiFetchAPI } from '../../api/Paymentpost/AuthDgi';
import { RequestLocalFetchAPI } from '../../api/Paymentpost/RequestLocal';
import { PrintOrderLocalFetchAPI } from '../../api/Paymentpost/PrintOrder';
import { OrderNumberFetchAPI } from '../../api/Paymentpost/OrderNumber';
import { CashTempPosFetchAPI } from '../../api/Paymentpost/OrderTemporalCash';
import { CategriesPosFetchAPI } from '../../api/Paymentpost/CategoriesPos';
import { DeletedTempOrdersFetchAPI } from '../../api/DeletedTemporalOrders';
import { ClientsFetchAPI } from '../../api/Clients';
import { YappyFetchAPI } from '../../api/Paymentpost/Yappy'
import ModalPhoneSaveYappy from './ModalPhoneSaveYappy';
import Modal from 'react-bootstrap/Modal'
import ModalConfirmation from './ModalConfirmation';
import ModalPaymentMixto from './ModalPaymentMixto';
import ModalPaymentManual from './ModalPaymentManual';
import ModalYappyQrPos from './ModalYappyQrPos';
import Modalclient from './ModalClients';
import ModuleTips from './ModuleTips';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ErrorAlert from '../Alerts/ErrorAlert';
import LoadingAlert from '../Alerts/LoadingAlert';
import ConfirmationAlert from '../Alerts/ConfirmationAlert';
import { RenderIf } from '../utils/RenderIf';
import { PreOrderFetchAPI } from '../../api/Paymentpost/PreOrder';
import Cashierpost from '../cahsier/Cashierpost';
import { MachinesFetchAPI } from '../../api/Machines';
import { PrinterFetchAPI } from '../../api/Printer';
import { MicrosFetchAPI } from '../../api/Micros';
import { OrdersFetchAPI } from "../../api/Orders"
import { InventoryFechtAPI } from '../../api/Inventory';
import { RequestAlohaFetchAPI } from '../../api/Paymentpost/RequestAloha';

import ModalDonlee from './ModalDonlee';
//Sweet alert
import Swal from "sweetalert2";

//Img
import empty_img from './img/empty_pays.png';
import checkimg from './img/check.png';
import cancel from './img/cancel.png'
import sadImg from './img/sad.png'
import loading from './img/loading.gif'
//We import the process payment function
import { ProcessPayment } from '../../utils/ProcessPayment';
import { PaymentOperations } from '../../utils/PaymentOperations';

//Icons 
import { FaCcVisa, FaPhoneAlt } from 'react-icons/fa';
import Empty from '../utils/Empty';
import SuccessAlert from '../Alerts/SuccessAlert';


const PaymentPost = ({ baseReducedTax, preOrderInvoice, dv, name, ruc, setDv, setName, setRuc, dataResponse, optionalData, setDataResponse, setOptionalData, shoppingCart, shoppingCartCombos, total, subtotal, discount, tax, setPageLoader, dataPreOrder, checkDeliveryOrder, registerTurnOrder, setRegisterTurnOrder, client_phone, setClient_phone, locator, setLocator, dataClient, setDataClient, listPaymentReady, setlistPaymentReady, amountPaymentReady, setAmountPaymenReady, tableInfo, taxesArrayAll, dataDicountApply, invoiceTips, setInvoiceTips, moduleTips, recomendationTips, totaltopay, setEventHandler, pos_integration_name, dataReferenceOrderMicros, parthner_order, codeParthner, yappy_transaction_id, setYappy_transaction_id }) => {

  const [paymentMethod, setPaymentMethod] = useState([])
  const [show, setShow] = useState(false);
  const [showModalProcess, setShowModalProcess] = useState(false);
  const [showModalPhone, setShowModalPhone] = useState(false);
  const [showModalPageYappy, setShowModalPageYappy] = useState(false);
  const [printers, setPrinters] = useState([]);
  const [microsModule, setMicrosModule] = useState(0)
  const [dataPrint, setDataPrint] = useState({})




  const [showEditBill, setShowEditBill] = useState(false);
  const handleEditBillClose = () => setShowEditBill(false);
  const handleEditBillShow = () => setShowEditBill(true);


  //cashier states
  const [modalShow, setModalShow] = useState(false);
  const [modalShowErrorRegisterOrder, setModalShowErrorRegisterOrder] = useState(false)
  const [detailOrder, setDetailOrder] = useState();
  const [amountOrder, setAmountOrder] = useState();
  const [showModalInsufficientFunds, setShowModalInsufficientFunds] = useState(false);
  const [insufficientMessage, setInsufficientMessage] = useState("");

  const [paymentInfo, setPaymentInfo] = useState({ payment: "" })
  const [showModalMixto, setShowModalMixto] = useState(false)
  const [showPaymentManual, setPaymentManual] = useState(false)
  const [datatransaction_code, setDataTransaction_code] = useState("");
  const [order_id, setOrder_id] = useState("");
  const [order_code, setOrderCode] = useState("");
  const [orderNumber, setOrderNumber] = useState("0")
  const [orderNumberNow, setOrderNumberNow] = useState("0")
  const [orderNumbeTemp, setOrderNumberTemp] = useState({ code: "", temp_id: "" })
  const [responseQr, setResponseQr] = useState(false)
  const [paymentApproval, setPaymentApproval] = useState({ status: 0, description: "Procesando pago" })
  const [phoneClientYappy, setPhoneClientYappy] = useState("")
  const [windows_ip, setWindows_ip] = useState("");
  const [infoPrinter, setInfoPrinter] = useState({ printer_comanda: "", printer_ticket: "" })
  const [EMVStreamResponse, setEMVStreamResponse] = useState({ EMVStreamResponse: "" })
  const [change, setChange] = useState(0);
  const [tempMethodPayment, setTempMethodPayment] = useState(0)
  const [amountPending, setAmountPending] = useState(0)
  const [lastOrder_id, setLastOrder_id] = useState("");

  //Cashback states
  const [cashback, setCashback] = useState(0);
  const [cashbackModal, setCashbackModal] = useState(false);

  //Yappy QR
  const [statePayment, setStatePayment] = useState(false);
  const [modalYappyQr, setModalYappyQr] = useState(false);
  const [dataYappy, setDataYappy] = useState({});
  const [dataHash, setDataHash] = useState("");
  const [statusRequestYappy, setStatusRequestYappy] = useState(false);

  const [contAuthRuc, setCountAucthRuc] = useState(0)

  let ventanaYappy = 0;

  //avi-ina
  const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);

  let machine = localStorage.getItem('machine');
  let business = localStorage.getItem('business');

  //We validate if the machine is logged in
  if (business !== null && business !== undefined && business !== '') {
    business = JSON.parse(localStorage.getItem('business'))
  }

  //We validate if the machine is logged in
  if (machine !== null && machine !== undefined && machine !== '') {
    machine = JSON.parse(localStorage.getItem('machine'))
  }


  let instervalCash = 0

  //Functions to show and hide the modal cashier
  const handleCloseModal = () => setModalShow(false);
  const handleShowModal = () => setModalShow(true)

  //get client by business 
  const getClientsByBusiness = async () => {
    let token = localStorage.getItem('x-access-machine-token');
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ClientsFetchAPI.getClientsByBusiness(business.id, token);
        resolve(response.data.data);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }

  //create client in the business
  const createClient = async (name, dv, typeRuc) => {
    let token = localStorage.getItem('x-access-machine-token');
    let data = {
      "phone": "6000-0000",
      "name": name,
      "email": "exmaple@exmaple.com",
      "business_code": business.code,
      "client_type_id": 1,
      "gender": "Masculino",
      "client_ruc_type": typeRuc,
      "ruc": ruc,
      "dv": dv,
      "location": "",
      "is_validated": 1
    }
    try {
      const response = await ClientsFetchAPI.createClient(data, token)
    } catch (err) {
      console.log(err);
    }
  }


  //Validate that the client already exists, if it does not exist, send it to validate with the dgi
  const validDataClient = async () => {
    let getClients = await getClientsByBusiness();
    let ruc_client = ruc
    const result = getClients.find(({ ruc }) => ruc === ruc_client);

    if (result === undefined) {
      authenticationDgi()
    } else {
      setName(result.name)
      setDv(result.dv)
      setDataResponse(true)
    }
  }


  //function to authentication of ruc
  const authenticationDgi = async () => {
    let message = ""
    let title = ""
    let icon = ""
    if (ruc == "") {
      message = "No se ha ingresado ningun ruc."
      title = "Debes ingresar un ruc."
      icon = "warning"
      ErrorAlert(message, title, icon)
    } else {
      LoadingAlert()
      let token = localStorage.getItem('x-access-machine-token');
      let data = { ruc: ruc }
      try {
        const response = await AuthDgiFetchAPI.authenticationRuc(token, data)
        let dataDgi = response.data
        if (dataDgi.dgi_response.gResProc) {
          if (dataDgi.dgi_response.gResProc.dCodRes == "0680") {
            let dvDgi = dataDgi.dgi_response.dDV
            if (dvDgi.length < 2) { dvDgi = "0" + dvDgi }
            setName(dataDgi.dgi_response.dNomb)
            setDv(dvDgi)
            message = "Datos del clientes obtenidos por la DGI"
            title = "Datos obtenido correctamente"
            icon = "success"
            ErrorAlert(message, title, icon)
            setDataResponse(true);
            createClient(dataDgi.dgi_response.dNomb, dvDgi, dataDgi.dgi_response.dTipoRuc)
            setCountAucthRuc(0)
          } else {

            if (contAuthRuc < 1) {
              message = "Intente nuevamente."
              title = "Datos no encontrados"
              icon = "warning"
              ErrorAlert(message, title, icon)
              setCountAucthRuc((contAuthRuc + 1))
            } else {
              setOptionalData(true)
              message = "Ingrese los datos manualmente."
              title = "Datos no encontrados"
              icon = "warning"
              ErrorAlert(message, title, icon)
              setCountAucthRuc(0)
            }

            message = "Ingrese los datos manualmente."
            title = "Datos no encontrados"
            icon = "warning"
            ErrorAlert(message, title, icon)

          }
        }
      } catch (err) {
        console.log(err);
        setOptionalData(true)
        message = "Ingrese los datos manualmente."
        title = "Datos no encontrados"
        icon = "warning"
        ErrorAlert(message, title, icon)
        setCountAucthRuc(0)
      }
    }
  }

  // get order number in process
  const getOrderNumber = async (branchoffice_id, token) => {
    try {
      const response = await OrderNumberFetchAPI.getOrderNumber(branchoffice_id, token)
      console.log(response)
      console.log((parseInt(response.data.last_transaction_code) + 1));
      setOrderNumber((parseInt(response.data.last_transaction_code) + 1));
      setOrderNumberNow((parseInt(response.data.last_transaction_code) + 1));
    } catch (err) {
      console.log(err);
    }
  }

  //function get payment method by machine
  const getPaymentMethod = async (machine_id, token) => {
    try {
      const response = await PaymentMethodFetchAPI.getPaymentMethod(machine_id, token);
      setPaymentMethod(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  //save info of payment
  const selectPayment = (e) => {
    let token = localStorage.getItem('x-access-machine-token');
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))


    getOrderNumber(branchoffice.id, token)
    if (e.target.checked == true) {
      let payment = JSON.parse(e.target.value)
      setPaymentInfo(payment)
    }
  }

  //function process payment of the order
  const processPayment = async () => {
    let title = 'Cargando....'
    let message = 'Cargando información....<br/> <br/>'
    let namePayment = paymentInfo.payment
    //-----------------------------------------------------
    setBotonDeshabilitado(true);
    if (namePayment != "") {
      if ((shoppingCart.length + shoppingCartCombos.length) > 0) {
        if (namePayment == 'Efectivo') {
          title = 'Esperando aprobacion....'
          message = 'El administrador esta aprobando el pago....<br/> <br/>'
          //LoadingAlert(title, message)
          //createOrderCash("Efectivo")

          if (paymentInfo.integration.id == 8) {
            setCashValidator()
          } else {
            setDetailOrder({ total: totaltopay, code: "0000" })
            handleShowModal()
          }


        } else if (namePayment == 'Credito') {
          title = 'Generando orden a credito....'
          message = 'Espere por favor....<br/> <br/>'
          LoadingAlert(title, message)
          registernewOrder(0)
        } else if (namePayment == 'Yappy') {
          setShowModalPhone(true)
          setChange(0);
        } else if (namePayment == 'Yappy QR') {
          setShowModalProcess(true);
          GenerateYappyTransaction()
        } else if (namePayment == "Mixto") {
          setAmountPending((parseFloat(total).toFixed(2) - parseFloat(amountPaymentReady).toFixed(2)))
          setShowModalMixto(true)

        } else {
          if (paymentInfo.integration.id == 1) {
            //console.log("Integracion de bac")
            requestPyament();
          } else if (paymentInfo.integration.id == 3) {
            //console.log("Integracion de Manual")
            setPaymentManual(true)
          } else if (paymentInfo.integration.id == 6) {
            //console.log("Integracion de Evertech")
            //We check if the payment is cash 
            if (namePayment == 'Cash') {
              //We continue the cash process payment 
              setDetailOrder({ total: totaltopay, code: "0000" })
              handleShowModal()
            } else {
              let cashback = paymentInfo.params.find((element) => element.name === "iscashback");
              //We check if the cashbak is enabled
              if (cashback !== undefined && cashback.value === "yes") {
                setCashbackModal(true);
              } else {
                requestPaymentEvertech(paymentInfo);
              }
            }
          } else if (paymentInfo.integration.id == 4) {
            //console.log("Integracion de Banesco")
            requestPaymentBanesco(paymentInfo);
          }

          setChange(0);
        }

        setTimeout(() => {
          setTempMethodPayment(1);
        }, 15000);
      } else {
        ErrorAlert("No hay productos en el carrito", "Agrega un producto", "info");
      }

    } else {
      ErrorAlert("No hay metodo de pago.", "Selecciona un metodo de pago", "info");
      setBotonDeshabilitado(false)
    }
  }

  //decimal reduction
  const decimalReduction = (value) => {

    let string = value.toString()
    let arrayString = string.split(".");
    let newDecimalString = []
    let sample = ''
    if (arrayString[1] != undefined) {
      newDecimalString = arrayString[1].slice(0, 2);
      sample = arrayString[0] + "." + newDecimalString

    } else {
      sample = string;
    }

    let endValue = parseFloat(sample)
    return endValue
  }

  //genrate yappy transaction
  const GenerateYappyTransaction = async () => {
    if (statePayment == false) {
      setStatePayment(true)
      LoadingAlert()
      let token = localStorage.getItem('x-access-machine-token');
      let machine = JSON.parse(localStorage.getItem('machine'));
      let data = {
        "machine_id": machine.id,
        "total": decimalReduction(totaltopay).toString(),
        "subtotal": decimalReduction(totaltopay).toString(),
        "tax": "0",
        "discount": "0",
        "transaction_code": orderNumber,
        "description": "Smartpay - orden:" + orderNumber,
        "params": paymentInfo.params
      }
      try {
        const response = await YappyFetchAPI.newYappyQr(data, token)
        setStatePayment(false)
        ErrorAlert("", "Todo listo para que realices el pago", "info")
        setDataYappy(response.data);
        setYappy_transaction_id(response.data.yappy_transaction.body.transactionId)
        setDataHash(response.data.yappy_transaction.body.hash)
        setModalYappyQr(true)
        setStatusRequestYappy(true)
        //getYappyTransaction(response.data)

      } catch (err) {
        setStatePayment(false)
        ErrorAlert("", "Lo sentimos yappy no esta disponible", "warning")
        console.log(err);
      }
    } else {
      console.log("peticion en proceso");
    }
  }

  const hadleCancelYappy = (text) => {

    if (text != undefined) {
      setPaymentApproval({ status: 2, description: text });
    } else {
      setPaymentApproval({ status: 2, description: "CANCELADO" });
    }

    setYappy_transaction_id("")
    setModalYappyQr(false);
    setStatusRequestYappy(false)

    setTimeout(() => {
      setShowModalProcess(false);
      setPaymentInfo({ payment: "", payment_id: 0 })

      setPaymentApproval({ status: 0, description: "Procesando pago..." })
    }, 3000);
  }

  const getYappyTransaction = async () => {
    let token = localStorage.getItem('x-access-machine-token');

    try {
      let yappy_transaction_id = dataYappy.yappy_transaction.body.transactionId

      const response = await YappyFetchAPI.getYappyTransaction(yappy_transaction_id, token);

      let status = response.data.yappy_transaction.body.status;

      switch (status) {
        case 'PENDING':
          //console.log("Transaccion pendiente");
          break;

        case 'COMPLETED':
          //console.log("Transaccion aprobada");

          setModalYappyQr(false)
          setStatusRequestYappy(false)
          setPaymentApproval({ status: 1, description: "APROBADO" })
          setShow(false)
          setTimeout(() => {

            registernewOrder(0);
            setShowModalProcess(false);
            setPaymentApproval({ status: 0, description: "Procesando pago..." })
          }, 3000);

          break;

        case 'DECLINED':
          setPaymentInfo({ payment: "" })
          setDataHash("")
          setYappy_transaction_id("")
          hadleCancelYappy('DECLINED')

          break;

        case 'EXPIRED':
          setPaymentInfo({ payment: "" })
          setDataHash("");
          setYappy_transaction_id("")
          hadleCancelYappy('EXPIRED')
          break;

        case 'FAILED':
          setPaymentInfo({ payment: "" })
          setDataHash("")
          setYappy_transaction_id("")
          hadleCancelYappy('FAILED')
          break;

        case 'VOIDED':
          setPaymentInfo({ payment: "" })
          setDataHash("")
          setYappy_transaction_id("")
          hadleCancelYappy('VOIDED')
          break;

      }

    } catch (err) {
      console.log(err);
      setPaymentInfo({ payment: "" })
      setDataHash("")
      setYappy_transaction_id("")
      hadleCancelYappy("ERROR DE CONSULTA")
    }

  }
  //---------------================================================================



  //next step phone number yappy
  const nextStepYappy = () => {
    setShowModalPhone(false)
    setShowModalProcess(true);

    //request c# yappy
    requestPyament(orderNumber);
    //createOrderCash("Yappy")
  }


  const getCategoriesByprinter = async (printer) => {

    try {
      const response = await CategriesPosFetchAPI.getCategoriesByPrinters(printer.printer_id, localStorage.getItem('x-access-machine-token'))
      printer.categories = response.data.categories

    } catch (err) {
      printer.categories = []
      console.log(err);
    }
    arrayPrintersTemp.push(printer)

  }

  //function action print order
  let arrayPrintersTemp = []
  const getPrinter = async () => {
    try {
      const response = await PrinterFetchAPI.getPrinter(localStorage.getItem('x-access-machine-token'), machine.id);

      let arrayPrinters = response.data.printers;
      let printer_Comanda = "";
      let printer_ticket = "";
      let printer_zebra = "";
      for (let i = 0; i < arrayPrinters.length; i++) {
        getCategoriesByprinter(arrayPrinters[i])
        if (arrayPrinters[i].printer_type == 'Tickets') {
          printer_ticket = arrayPrinters[i].printer_name
        } else if (arrayPrinters[i].printer_type == 'Comanda') {
          printer_Comanda = arrayPrinters[i].printer_name
        } else if (arrayPrinters[i].printer_type == 'Zebra') {
          printer_zebra = arrayPrinters[i].printer_name;
        }
      }

      setPrinters(arrayPrintersTemp)
      setInfoPrinter({ printer_comanda: printer_Comanda, printer_ticket: printer_ticket, printer_zebra: printer_zebra });

    } catch (err) {
      console.log(err);
    }
  }


  //function of cash status
  const statusCash = (status) => {
    if (status == 1) {//aprobed

      //We check if the payment is cash evertech 
      if (paymentInfo.integration.id == 6) {
        //We send the request to the evertech payment
        requestPaymentEvertech(paymentInfo);
      } else {
        //We just call the function register order
        registernewOrder(0)
      }
      handleCloseModal();
    } else if (status == 2) {//cancel
      handleCloseModal()
    }
  }

  //get order
  const getLastOrderMachineNow = async () => {
    let token = localStorage.getItem('x-access-machine-token');
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
    let business = JSON.parse(localStorage.getItem('business'));

    try {
      const response = await FilterOrderFetchAPI.getLastOrderOfMachine(machine.id, token)
      let order_last_number = orderNumberNow.toString()

      if (order_last_number.length < 4) {
        order_last_number = "0" + order_last_number
      }

      if (order_last_number == response.data.last_order.transaction_code && total == response.data.last_order.total) {
        setModalShowErrorRegisterOrder(false)
        setDataTransaction_code(response.data.last_order.transaction_code)
        setShow(false)
        setResponseQr(true);
        let title = "ORDEN: " + response.data.last_order.transaction_code
        let text = "Total de la orden: $" + total.toFixed(2)

        if (dataPreOrder.pre_Order == true) { deleteTempPreOrder(response.data.last_order.transaction_code, response.data.last_order.order) }
        //ConfirmationAlert(title, text, setPageLoader)
        printOrder(response.data.last_order.order_id, response.data.last_order.transaction_code);
        setTempMethodPayment(0);
        setRegisterTurnOrder(1);

        //We show a success message when the order is approved when the type of payment is credit 
        if (paymentInfo.payment_id === 10) {
          SuccessAlert("Orden a credito generada", "Orden generada", "success")
        } else {
          SuccessAlert("Orden registrada con exito", "Orden generada", "success")
        }
      } else {
        registernewOrder(0)
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.error === "No existe la ordern") {
        //registernewOrder(0)
      }
    }
  }

  const setCashValidator = async () => {//set cash validator
    console.log("CASH VALIDATOR" + statePayment);
    if (statePayment == false) {
      setStatePayment(true)
      setShowModalProcess(true);
      let data = { "Terminal": "Autoservicio 1", "Monto": totaltopay.toFixed(2), "recibido": "0.00", "cambio": "0.00" }
      try {
        const response = await RequestLocalFetchAPI.postCashMethod(data);
        setStatePayment(false)
        console.log("CASH ---------------");
        console.log(response);
        if (response.data.status == "APPROVED") {
          setPaymentApproval({ status: 1, description: "Aprobado" });
          setShow(false);
        } else {
          setPaymentApproval({ status: 2, description: "Error en el validador de efectivo" });

        }

        setTimeout(() => {
          if (response.data.status == "APPROVED") {
            registernewOrder(0);
          } else {
            cancelMethodPayment()
          }

          setShowModalProcess(false);
          setPaymentApproval({ status: 0, description: "Procesando pago..." })
        }, 3000);

      } catch (err) {
        setPaymentApproval({ status: 2, description: "Error en el validador de efectivo" });

        setTimeout(() => {
          cancelMethodPayment()
          setShowModalProcess(false);
          setPaymentApproval({ status: 0, description: "Procesando pago..." })
        }, 3000);
      }
    }
  }

  //function action request pyment local
  const requestPyament = async (yappy_Temp_id) => {
    setShowModalProcess(true);
    let JsonTr = {}

    if (paymentInfo.payment == "Yappy") {
      JsonTr = [{
        Tipo: 'SALE',
        Producto: 'COBRAR',
        Itbms: tax.toFixed(2),
        Tips: invoiceTips,
        Descuento: discount.toFixed(2),
        Monto: totaltopay.toFixed(2),
        TipoVenta: paymentInfo.payment.toUpperCase(),
        paymentMethod: paymentInfo,
        orderid: yappy_Temp_id,
        tel: "507" + phoneClientYappy,
        total: totaltopay.toFixed(2),
        subtotal: subtotal.toFixed(2),
        taxes: tax.toFixed(2),
      }];
    } else {

      let methodPayment = ""
      if (paymentInfo.payment == "MasterCard") {
        methodPayment = "MC"
      } else {
        methodPayment = paymentInfo.payment
      }

      JsonTr = [{
        Tipo: 'SALE',
        Producto: 'COBRAR',
        Itbms: tax.toFixed(2),
        Descuento: discount.toFixed(2),
        Tips: invoiceTips,
        Monto: totaltopay.toFixed(2),
        TipoVenta: methodPayment.toUpperCase(),
        paymentMethod: paymentInfo,
        orderid: orderNumber,
      }];
    }

    try {

      const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, localStorage.getItem('x-access-machine-token'));
      setWindows_ip(response_machine.data.data.windows_ip);

      const response = await RequestLocalFetchAPI.postPaymentMethod(JsonTr, response_machine.data.data.windows_ip);


      setEMVStreamResponse(response.data.data.EMVStreamResponse)
      let responseCode = response.data.data.EMVStreamResponse.responseCode;
      let description = response.data.data.EMVStreamResponse.responseCodeDescription

      if (paymentInfo.payment != "Yappy") {
        if (responseCode == "00") {
          setPaymentApproval({ status: 1, description: description });
          setShow(false);
        } else {
          setPaymentApproval({ status: 2, description: description });
        }
      } else {
        if (responseCode == "20") {
          setPaymentApproval({ status: 1, description: description });
          setShow(false);
        } else {
          setPaymentApproval({ status: 2, description: description });
        }
      }

      setTimeout(() => {
        if (paymentInfo.payment != "Yappy") {
          if (responseCode == "00") {
            registernewOrder(response.data.data.EMVStreamResponse);
          } else {
            cancelMethodPayment()
          }
        } else {
          if (responseCode == "20") {
            registernewOrder(0);
          } else {
            cancelMethodPayment()
          }
        }
        setShowModalProcess(false);
        setPaymentApproval({ status: 0, description: "Procesando pago..." })
      }, 3000);


      //origin yappy selection
      {/*console.log(response)
      if (paymentInfo.payment != "Yappy") {
        setEMVStreamResponse(response.data.data.EMVStreamResponse)
        let responseCode = response.data.data.EMVStreamResponse.responseCode;
        let description = response.data.data.EMVStreamResponse.responseCodeDescription

        if (responseCode == "00") {
          setPaymentApproval({ status: 1, description: description });
          setShow(false);
        } else {
          setPaymentApproval({ status: 2, description: description });
        }

        setTimeout(() => {
          if (responseCode == "00") {
            registernewOrder(response.data.data.EMVStreamResponse);
          }
          setShowModalProcess(false);
          setPaymentApproval({ status: 0, description: "Procesando pago..." })
        }, 3000);
      } else {

        if (response.data.data == "") {
          setPaymentApproval({ status: 3, description: "Ocurrio un error." });
          setEMVStreamResponse({ EMVStreamResponse: "" });


        } else {
          ventanaYappy = window.open(response.data.data, "test", "");
        }

      }*/}

      setBotonDeshabilitado(false);
    } catch (err) {
      cancelMethodPayment()
      console.log(err);
    }
  }

  //Function to request the payment with evertech
  const requestPaymentEvertech = async (paymentInfo) => {
    let response;
    //We get the user
    let user = JSON.parse(localStorage.getItem('user'));
    //We show the modal of the process
    setShowModalProcess(true);
    try {
      //We make the request to the evertech in base of the payment info
      if (paymentInfo.payment !== "Nota de credito" && paymentInfo.payment !== "Refund" && paymentInfo.payment !== "EBT Refund" && paymentInfo.payment !== "Cash Refund") {
        //We add to the paymentInfo some data that we need to make the payment
        paymentInfo.sECRStationID = machine.code;
        paymentInfo.sCashierID = user.id;
        paymentInfo.sRefNumber = orderNumberNow;
        paymentInfo.tips = invoiceTips;
        paymentInfo.total = total;
        paymentInfo.subtotal = subtotal;
        paymentInfo.tax = tax;
        paymentInfo.basereducedtax = baseReducedTax;
        paymentInfo.discount = discount;
        paymentInfo.taxes = taxesArrayAll;
        //We make the payment request
        response = await ProcessPayment(paymentInfo);
      } else {
        //We make the refund request
        response = await PaymentOperations({
          operationType: "refund",
          integrationType: 'Evertech',
          params: paymentInfo.params,
          taxes: taxesArrayAll,
          tax: tax,
          discount: discount,
          payment: paymentInfo.payment,
          tips: invoiceTips,
          total: total,
          basereducedtax: baseReducedTax,
          subtotal: subtotal,
          sECRStationID: machine.code,
          sCashierID: user.id,
          sRefNumber: orderNumberNow,
        });
      }

      //We check the response of evertech
      if (response && response.data !== undefined && response.data.response_message === "APPROVED") {
        setPaymentApproval({ status: 1, description: "APROBADO" });
        setShow(false);
        setTimeout(() => {
          registernewOrder(response.data);
          setShowModalProcess(false);
          setPaymentApproval({ status: 0, description: "Procesando pago..." })
        }, 3000);
      } else if (response && response.data !== undefined && response.data.response_message === "PINPAD TIMEOUT") {
        setPaymentApproval({ status: 2, description: "Tiempo agotado" });
        setShowModalProcess(false);
      } else if (response && response.data !== undefined && response.data.response_message === "DUPLICATED TRANSACTION.") {
        setPaymentApproval({ status: 2, description: "Transaccion duplicada" });
        setShow(false);
        setTimeout(() => {
          setShowModalProcess(false);
          setPaymentApproval({ status: 0, description: "Procesando..." })
        }, 3000);
      } else if (response && response.data !== undefined && response.data.response_message === "DECLINED (Incorrect PIN)") {
        //console.log("PIN Incorrecto");
        setPaymentApproval({ status: 2, description: " PIN Incorrecto" });
        setShow(false);
        setTimeout(() => {
          setPaymentApproval({ status: 0, description: "Procesando..." })
          setShowModalProcess(false);
        }, 3000);
      } else if ((response && response.Informacion !== undefined) || (response && response.data && response.data.response_message === "Connection Error - LOGON")) {
        setPaymentApproval({ status: 2, description: "Pin pad no disponible" });
        setShow(false);
        setTimeout(() => {
          setShowModalProcess(false);
          setPaymentApproval({ status: 0, description: "Procesando..." })
        }, 3000);
      }
      else {
        setPaymentApproval({ status: 2, description: response.data.response_message !== undefined ? response.data.response_message : "Declinado, Error inesperado" });
        setShow(false);
        setTimeout(() => {
          setShowModalProcess(false);
          setPaymentApproval({ status: 0, description: "Procesando..." });

          //We check if the payment is EBT
          if ((paymentInfo.payment === "EBT" || paymentInfo.payment === "EBT Cash" || paymentInfo.payment === "EBT Unica" || paymentInfo.payment === "EBT Cash Unica" || paymentInfo.payment === "EBT Cash Advance")) {
            setInsufficientMessage(response.data.receipt_output.merchant + response.data.receipt_output.customer ? response.data.receipt_output.merchant + response.data.receipt_output.customer : null)
            setShowModalInsufficientFunds(true)
          }
        }, 6000);

      }
    } catch (err) {
      // Manejamos el error de manera segura
      console.error("Error durante el proceso de pago:", err);

      // Verificamos si el error tiene una respuesta o si viene formateado correctamente
      if (err.status && err.data) {
        setPaymentApproval({ status: 2, description: "Error: " + err.data });
      } else {
        setPaymentApproval({ status: 2, description: "Error inesperado: " + err.message });
      }

      // Ocultamos el modal de proceso después del error
      setShowModalProcess(false);
    }
  }


  //Function to request the payment with banesco
  const requestPaymentBanesco = async (paymentInfo) => {
    let response;
    //We get the user
    let user = JSON.parse(localStorage.getItem('user'));
    //We show the modal of the process
    setShowModalProcess(true);
    try {
      //We make the request to the evertech in base of the payment info
      if (paymentInfo.payment !== "Nota de credito" && paymentInfo.payment !== "Refund") {
        //We add to the paymentInfo some data that we need to make the payment
        paymentInfo.tips = invoiceTips;
        paymentInfo.total = total;
        paymentInfo.subtotal = subtotal;
        paymentInfo.tax = tax;
        paymentInfo.discount = discount;
        //We make the payment request
        response = await ProcessPayment(paymentInfo);
      } else { 
        //We make the refund request
        response = await PaymentOperations({
          operationType: "refund",
          integrationType: 'Banesco',
          params: paymentInfo.params,
          tax: tax,
          discount: discount,
          payment: paymentInfo.payment,
          tips: invoiceTips,
          total: total,
          subtotal: subtotal
        });
      }

      //We check the response of banesco
      if (response && response.data !== undefined && response.data.TxnRspCode === "00") {
        setPaymentApproval({ status: 1, description: "APROBADO" });
        setShow(false);
        setTimeout(() => {
          registernewOrder(response.data);
          setShowModalProcess(false);
          setPaymentApproval({ status: 0, description: "Procesando pago..." })
        }, 3000);
      } else {
        setPaymentApproval({ status: 2, description: response.data.TxnRspCode !== undefined ? `Declinado, error #${response.data.TxnRspCode}` : "Declinado, Error inesperado" });
        setShow(false);
        setTimeout(() => {
          setShowModalProcess(false);
          setPaymentApproval({ status: 0, description: "Procesando..." });
        }, 6000);
      }
    } catch (err) {
      // Manejamos el error de manera segura
      console.error("Error durante el proceso de pago:", err);

      // Verificamos si el error tiene una respuesta o si viene formateado correctamente
      if (err.status && err.data) {
        setPaymentApproval({ status: 2, description: "Error: " + err.data });
      } else {
        setPaymentApproval({ status: 2, description: "Error inesperado: " + err.message });
      }

      // Ocultamos el modal de proceso después del error
      setShowModalProcess(false);
    }
  }

  //create order cash temporal
  let type = 0
  const createOrderCash = async (type_method) => {
    let token = localStorage.getItem('x-access-machine-token');
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
    let machine = JSON.parse(localStorage.getItem('machine'))
    type = 0
    let json = {
      "client_name": "Estimado cliente",
      "localizador": "",
      "client_phone": "",
      "dgi_qr": "",
      "Orden": shoppingCart,
      "Descuento": discount,
      "Total": total,
      "itbms": tax,
      "taxes": taxesArrayAll,
      "subtotal": subtotal,
      "machine_id": machine.id,
      "branchoffice_id": branchoffice.id,
      "tipoOrden": "comer aqui",
      "paymethod": paymentInfo.id,
      "is_discount_notification": false,
      "type_method": type_method,
      "transaction_code": orderNumber,


    }

    try {
      const response = await CashTempPosFetchAPI.createTempCash(json, token)

      if (type_method == "Yappy") {

        requestPyament(response.data.newTempOrder.code);
        setOrderNumberTemp({ code: response.data.newTempOrder.code, temp_id: response.data.newTempOrder._id })
      }

      console.log(response);
      getOrdersTempCash();
    } catch (err) {
      console.log(err.response.data.error);
      type = 1
      console.log("Hay una temporal");
      getOrdersTempCash();
      /*let erro_progress = err.response.data.error
      //!Error : Machine has an order in progress
      if (erro_progress === 'La maquina ya tiene una orden en progreso') {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'La máquina ya tiene una orden en progreso',
        })
      }*/
      console.log(err);
    }
  }

  //get order temp cash
  let countGetCash = 0 // count to limit get orders
  const getOrdersTempCash = async () => {

    let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
    let machine = JSON.parse(localStorage.getItem('machine'))
    let business = JSON.parse(localStorage.getItem('business'))
    clearInterval(instervalCash)
    try {
      const response = await CashTempPosFetchAPI.getTempCash(machine.code, business.code, branchoffice.code)
      console.log(response);
      let code = response.data.order.code
      let message = ""
      let title = ""
      let icon = ""

      if (type == 0) {
        if (countGetCash == 0) {
          if (paymentInfo.payment != "Yappy") {
            handleShowModal();
          }
          setDetailOrder(response.data.order);
        }

        if (response.data.order.status_id == 1) {
          if (countGetCash <= 100) {
            instervalCash = setInterval(getOrdersTempCash, 5000)
            countGetCash++
          } else {
            message = "Tiempo de espera agotado"
            title = "Advertencia"
            icon = "warning"

            if (paymentInfo.payment == "Yappy") {
              ventanaYappy.close();
              setPaymentApproval({ status: 3, description: "Tiempo de espera agotado." });
            }
            handleCloseModal()
            updateOrderDiscount(code, 5, message, title, icon)
            countGetCash = 0
          }

          //!Fixed Eroor: Correjir mensjae y  poner loading 
        } else if (response.data.order.status_id == 8) {//order temporal is cancel

          title = "Operacion cancelada"
          message = "Orden temporal cancelada"
          icon = "error"

          setBotonDeshabilitado(false);

          if (paymentInfo.payment == "Yappy") {
            ventanaYappy.close();
            setPaymentApproval({ status: 3, description: "Ocurrio un error." });
          }
          updateOrderDiscount(code, 5, message, title, icon)


        } else if (response.data.order.status_id == 7) {//order temporal is confirm
          message = "El pago fue aprobado."
          title = "Pago exitoso"
          icon = "success"

          if (paymentInfo.payment == "Yappy") {
            ventanaYappy.close();
            setPaymentApproval({ status: 1, description: "Pago exitoso." });
          }

          if (paymentInfo.payment == "Efectivo") {
            setShow(false)
          }
          updateOrderDiscount(code, 3, message, title, icon)

          //function register order
          registernewOrder(0)
        }
      } else if (type == 1) {
        console.log("Consulte la temporal remanante");
        updateOrderDiscount(code, 5, message, title, icon)
      }
    } catch (err) {
      console.log(err);
    }
  }

  //cancel temporal transaction yappy
  const cancelTempYappy = () => {
    let message = "Orden temporal cancelada"
    let title = "Operacion cancelada"
    let icon = "error"
    updateOrderDiscount(orderNumbeTemp.code, 5, message, title, icon)
  }

  //cancel method payment in process
  const cancelMethodPayment = () => {
    setTempMethodPayment(0);
    setPaymentApproval({ status: 0, description: "Procesando pago..." })

    //hidden screen modal 
    setStatePayment(false)
    setShowModalProcess(false);

    //if condition payments
    {/*if (paymentInfo.payment == "Yappy") {
      let message = "Orden temporal cancelada"
      let title = "Operacion cancelada"
      let icon = "error"
      updateOrderDiscount(orderNumbeTemp.code, 5, message, title, icon)
    } else {
      setShowModalProcess(false);
    }*/}
  }

  //function try again yappy
  const try_again_yappy = () => {
    setPaymentApproval({ status: 0, description: "Procesando pago..." })
    requestPyament(orderNumbeTemp.temp_id);
  }

  //update orders temporal discount
  const updateOrderDiscount = async (code, status, message, title, icon) => {
    console.log(code);
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
    let business = JSON.parse(localStorage.getItem('business'))

    let jsonUpdate = {
      "order_code": code,
      "business_code": business.code,
      "branchoffice_code": branchoffice.code,
      "status": status
    }
    try {
      const response = await CashTempPosFetchAPI.updateOrderTempCash(jsonUpdate)
      console.log(paymentInfo.payment);

      if (type == 0) {
        if (paymentInfo.payment == "Yappy") {
          setTimeout(() => {
            setShowModalProcess(false);
            setPaymentApproval({ status: 0, description: "Procesando pago..." });
          }, 3000);
        }

        if (status == 5) {
          ErrorAlert(message, title, icon)
        }

      } else if (type == 1) {
        console.log("Solicite crear nuevamente la temporal");
        createOrderCash(paymentInfo.payment)
      }
    } catch (err) {
      console.log(err);
    }

  }

  //function to compare data micros and update
  const handlerComparativeNewData = (ip, RvcRef, employeeNum, host_url, micros_params, token, order_id) => {
    let preOrderComparativeOld = JSON.parse(localStorage.getItem('preOrderComparativeOld'))
    let OrderGroupsOld = preOrderComparativeOld.groups
    let OrderCombosOld = preOrderComparativeOld.combos
    let newGroups = []
    let newCombos = []
    //comparative groups
    shoppingCart.forEach(group => {
      let groupOld = OrderGroupsOld.find(item => item.group_id == group.group_id && item.segments_string == group.segments_string)

      if (groupOld != undefined) {
        if (groupOld.amount != group.amount) {
          let newGroup = groupOld
          newGroup.amount = (group.amount - groupOld.amount)
          newGroup.cantidadItem = (group.amount - groupOld.amount)
          newGroups.push(newGroup)

          console.log(newGroup);
        }
      } else {
        newGroups.push(group)
      }
    });

    //Add string data to compare
    shoppingCartCombos.forEach(combo => {
      let stringdata = combo.combo_id + "";
      combo.setGroups.forEach(setGroup => {
        stringdata += setGroup.set_id;
        setGroup.groups.forEach(group => {
          stringdata += group.group_id;
          group.segments.forEach(segment => {
            stringdata += segment.segment_id + segment.item.item_id;
          });
        });
      });
      combo.combo_string = stringdata;
    });

    //Add string data to compare
    OrderCombosOld.forEach(comboOld => {
      let stringdataOld = comboOld.combo_id + "";
      comboOld.setGroups.forEach(setGroup => {
        stringdataOld += setGroup.set_id;
        setGroup.groups.forEach(group => {
          stringdataOld += group.group_id;
          group.segments.forEach(segment => {
            stringdataOld += segment.segment_id + segment.item.item_id;
          });
        });
      });
      comboOld.combo_string = stringdataOld;
    });

    //==========================================
    //Comparative combos
    shoppingCartCombos.forEach(combo => {
      let comboOld = OrderCombosOld.find(comboOld => comboOld.combo_id == combo.combo_id && comboOld.combo_string == combo.combo_string)

      if (comboOld != undefined) {
        if (comboOld.amount != combo.amount) {
          let newCombo = comboOld
          newCombo.amount = (combo.amount - comboOld.amount)
          newCombo.cantidadItem = (combo.amount - comboOld.amount)
          newCombos.push(newCombo)
        }
      } else {
        newCombos.push(combo)
      }
    });

    let dataUpdateOrder = formatDataMicrosCheck(ip, RvcRef, employeeNum, host_url, newGroups, newCombos, "PUT")
    validTotalsMicros(ip, RvcRef, employeeNum, host_url, micros_params, token, order_id, "PUT", dataUpdateOrder)
  }

  //format data to send to micros
  const formatDataMicrosCheck = (ip, RvcRef, employeeNum, host_url, groups, combos, action) => {
    //Json general format
    let jsonMicrosCheck = {
      RvcRef: RvcRef,
      employeeNum: employeeNum,
      host_url: host_url,
      localMicrosServerIp: ip,
      products: [],
      automatic_payment: 1,
      delivery: false,
      client: { name: name, phone: "00" },
      paymentmethods: [{
        "name": paymentInfo.payment,
        "paymenthod_id": paymentInfo.payment_id,
        "total": total
      }],
    }

    if (action == "PUT") {
      let preOrderOld = JSON.parse(localStorage.getItem('preOrderComparativeOld'))
      jsonMicrosCheck.checkSeq = preOrderOld.micros_reference.checkSeq
      jsonMicrosCheck.checkNum = preOrderOld.micros_reference.checkNum
    }

    let data = []
    //json product format
    groups.forEach(product => {
      let dataDetail = {
        "code": product.group_id,
        "name": product.name,
        "price": product.price,
        "cant": product.amount,
        "tax": product.taxes[0].tax,
        "total_discount": product.discount,
        "exist_product": false,
        "total_product": product.price,
        "external_reference": product.external_reference,
        "type": "product",
      }

      let dataSegments = []
      product.segments.forEach(segment => {
        let detail = {
          "external_reference": segment.external_reference,
          "name": segment.name,
          "amount": segment.amount,
        }
        dataSegments.push(detail)
      })
      dataDetail.condiments = dataSegments
      data.push(dataDetail)
    });


    console.log(combos);
    //----------------------------------------------
    //format combos
    combos.forEach(combo => {
      console.log(combo);
      let dataDetailCombo = {
        "type": "combo",
        "code": combo.combo_id,
        "tax": combo.taxes[0].tax,
        "total_discount": combo.discount,
        "exist_product": false,
        "total_product": combo.price,
        "external_reference": combo.referenced_group.external_reference,
        "price": combo.price,
        "name": combo.name,
        "cant": combo.amount,
        "mainDetail": {},
        "sideDetails": [],
      }
      let setGroupsData = []
      combo.setGroups.forEach(setGroup => {
        setGroup.groups.forEach(group => {
          //json group format
          let dataDetail = {
            "name": group.name,
            "external_reference": group.external_reference,
            "isMainProduct": false,
          }

          let dataSegments = []
          group.segments.forEach(segment => {
            let detail = {
              "external_reference": segment.external_reference,
              "name": segment.name,
              "amount": segment.amount,
            }
            dataSegments.push(detail)
          })
          //add data segments to group
          dataDetail.condiments = dataSegments

          if (setGroup.isMainSetGroup == 1) {
            //add data to main detail
            dataDetail.isMainProduct = true
            dataDetailCombo.mainDetail = dataDetail
          } else {
            //add data to side details
            dataDetail.price = group.price
            dataDetail.cant = group.amount
            dataDetail.total = group.price
            setGroupsData.push(dataDetail)
          }
        })
        //add data side details to combo
        dataDetailCombo.sideDetails = setGroupsData
      })
      //add combo to products
      data.push(dataDetailCombo)
    })
    jsonMicrosCheck.products = data

    //return data format
    return (jsonMicrosCheck)
  }

  //validate totals micros
  const validTotalsMicros = async (ip, RvcRef, employeeNum, host_url, orderData, token, order_id, action, dataUpdateOrder) => {
    let order = formatDataMicrosCheck(ip, RvcRef, employeeNum, host_url, shoppingCart, shoppingCartCombos, action)
    try {
      let data = {
        Ruta: `http://${ip}:9472/api/micros/totals/checks?RvcRef=${RvcRef}&employeeNum=${employeeNum}&host_url=${host_url}`,
        Type: "POST",
        Header: { "x-access-token": token },
        Body: { products: order.products, tips: invoiceTips, localMicrosServerIp: ip }
      }

      console.log(data);

      const response = await RequestLocalFetchAPI.postMicros(data);
      let micros_response = response.data.micros_response.CalculateTransactionTotalsResponse.pTotalsResponse
      dataUpdateOrder.paymentmethods[0].total = micros_response.TotalsTotalDue._text
      //Create and update check in micros
      createCheckMicrosOrderLocal(ip, RvcRef, employeeNum, host_url, dataUpdateOrder, token, order_id, action)
    } catch (err) {
      console.log(err);
    }
  }

  // create transacction in micros
  const createCheckMicrosOrderLocal = async (ip, RvcRef, employeeNum, host_url, orderData, token, order_id, type) => {
    setMicrosModule(1)
    let data = {
      Ruta: `http://${ip}:9472/api/micros/checks?RvcRef=${RvcRef}&employeeNum=${employeeNum}&host_url=${host_url}`,
      Type: type,
      Header: { "x-access-token": token },
      Body: orderData
    }

    console.log(data);
    let micros_response;
    try {
      micros_response = await RequestLocalFetchAPI.postMicros(data);

      try {
        //We validate if the micros response is success
        let params_Micros_Respose = {}
        //determines what type of parameter to look for
        if (type == "PUT") {
          params_Micros_Respose = micros_response.data.micros_response.AddToExistingCheckExResponse
        } else {
          params_Micros_Respose = micros_response.data.micros_response.PostTransactionExResponse
        }

        if (params_Micros_Respose.pTotalsResponse.OperationalResult.ErrorCode._text !== undefined && params_Micros_Respose.pTotalsResponse.OperationalResult.ErrorCode._text === "Success") {
          console.log("vamos a actualizar")
          //We add the data that we want to update 
          let data_to_update = {
            micros_reference: {
              checkNum: params_Micros_Respose.pGuestCheck.CheckNum._text,
              checkSeq: params_Micros_Respose.pGuestCheck.CheckSeq._text,
            }
          }
          //We make the update of the order 
          const response = await OrdersFetchAPI.updateOrder(order_id, data_to_update, token);
        }
      } catch (err) {
        console.log(err)
      }
    } catch (err) {
      console.log(err);
    }
  }

  //Register order aprobed
  const registernewOrder = async (detailEMVStreamResponse) => {
    console.log("DATA PRE ORDER======================================");
    console.log(dataPreOrder);

    console.log(detailEMVStreamResponse);

    let token = localStorage.getItem('x-access-machine-token')
    LoadingAlert("Registrando orden", "Espere un momento por favor...")
    /*setTimeout(() => {
      LoadingAlert("Registrando orden", "Espere un momento por favor...")
    }, 2000);*/


    if (registerTurnOrder == 0) {
      setModalShowErrorRegisterOrder(false)
      let user = JSON.parse(localStorage.getItem('user'));
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let machine = JSON.parse(localStorage.getItem('machine'));
      let json = {
        "user_id": user.id,
        "EMVStreamResponse": detailEMVStreamResponse,
        "transaction_code": orderNumber,
        "client_name": name,
        "id_Client": 0,
        "ruc": ruc,
        "dv": dv,
        "localizador": locator,
        "client_phone": client_phone,
        "dgi_qr": "0",
        "Orden": shoppingCart,
        "combos": shoppingCartCombos,
        "Descuento": discount,
        "Total": total.toFixed(2),
        "itbms": tax.toFixed(2),
        "tips": invoiceTips,
        "taxes": taxesArrayAll,
        "subtotal": subtotal.toFixed(2),
        "machine_id": machine.id,
        "branchoffice_id": branchoffice.id,
        "tipoOrden": checkDeliveryOrder,
        "paymethod": paymentInfo.payment_id,
        "paymethods": listPaymentReady,
        "discounts": dataDicountApply,
        "paymenthod_integration_id": paymentInfo.integration.id,
        "table_name": tableInfo.table_name,
        "table_id": tableInfo.table_id,
        "table_code": tableInfo.table_code,
        "discounts": dataDicountApply,
        "parthner_order": parthner_order
      }

      //We check if the payment integration is evertech 
      if (paymentInfo.integration.id === 6) {
        if (detailEMVStreamResponse.amounts.tip !== undefined) {
          //IF the payment integration is evertech we search the tip info and add it to the order
          json.tips = detailEMVStreamResponse.amounts.tip.split("$")[1];
        }
      }

      if (yappy_transaction_id != "") {
        json.yappy_transaction_id = yappy_transaction_id
      }

      if (dataPreOrder.pre_Order == true) {
        json.pre_order = true
        json.pre_order_code = dataPreOrder.code
      }
      try {

        const response = await OrderNumberFetchAPI.registerOrder(json, token)
        setDataTransaction_code(response.data.data.transaction_code)
        setOrder_id(response.data.data.order_id)
        setOrderCode(response.data.data.order_code)
        setShow(false)
        setResponseQr(true);
        let title = "ORDEN: " + response.data.data.transaction_code
        let text = "Total de la orden: $" + total.toFixed(2)

        if (dataPreOrder.pre_Order == true) { deleteTempPreOrder(response.data.data.transaction_code, response.data.data.order_code) }
        //ConfirmationAlert(title, text, setPageLoader)
        printOrder(response.data.data.order_id, response.data.data.transaction_code);
        setTempMethodPayment(0);
        setRegisterTurnOrder(1);

        //type integration micros
        if (response.data.data.micros_params != null) {
          //params micros
          let micros_params = response.data.data.micros_params;
          let ip = micros_params.localMicrosServerIp
          if (dataPreOrder.pre_Order == true) {

            if (dataReferenceOrderMicros.checkNum != "" && dataReferenceOrderMicros.checkSeq != "") {
              handlerComparativeNewData(ip, micros_params.RvcRef, micros_params.employeeNum, micros_params.host_url, micros_params, token, response.data.data.order_id);
            } else {
              //createCheckMicrosOrderLocal(ip, micros_params.RvcRef, micros_params.employeeNum, micros_params.host_url, micros_params, token, response.data.data.order_id, "POST");
              validTotalsMicros(ip, micros_params.RvcRef, micros_params.employeeNum, micros_params.host_url, micros_params, token, order_id, "POST", micros_params)
            }
          } else {
            //createCheckMicrosOrderLocal(ip, micros_params.RvcRef, micros_params.employeeNum, micros_params.host_url, micros_params, token, response.data.data.order_id, "POST");
            validTotalsMicros(ip, micros_params.RvcRef, micros_params.employeeNum, micros_params.host_url, micros_params, token, order_id, "POST", micros_params)
          }
        } else {
          console.log("no hay parametros de micros");
        }

        //We show a success message when the order is approved when the type of payment is credit 
        if (paymentInfo.payment_id === 10) {
          SuccessAlert("Orden a credito generada", "Orden generada", "success")
        } else {
          SuccessAlert("Orden registrada con exito", "Orden generada", "success")
        }
      } catch (err) {
        //valid to id of transaction 
        try {
          let token = localStorage.getItem('x-access-machine-token');
          const response = await MachinesFetchAPI.lastOrderMachine(machine.id, token);

          if (response.data.last_order._id == lastOrder_id) {
            console.log("La transaccion no habia sido regitrada")
            console.log(err);
            registerTurnOrder = 0;
            setRegisterTurnOrder(registerTurnOrder)
            ErrorAlert("", "Ocurrio un error", "error")
            setModalShowErrorRegisterOrder(true)
          } else {
            console.log("La transaccion ya habia sido regitrada")
            setDataTransaction_code(response.data.last_order.transaction_code);
            setOrder_id(response.data.last_order.order_id)
            setOrderCode(response.data.last_order.order_code)
            console.log(response);
            setShow(false)
            setResponseQr(true);
            let title = "ORDEN: " + response.data.last_order.transaction_code
            let text = "Total de la orden: $" + total.toFixed(2)

            if (dataPreOrder.pre_Order == true) { deleteTempPreOrder(response.data.last_order.transaction_code, response.data.last_order.order_code) }
            //ConfirmationAlert(title, text, setPageLoader)
            printOrder(response.data.last_order.order_id, response.data.last_order.transaction_code);
            setTempMethodPayment(0);
            setRegisterTurnOrder(1);

            //We show a success message when the order is approved when the type of payment is credit 
            if (paymentInfo.payment_id === 10) {
              SuccessAlert("Orden a credito generada", "Orden generada", "success")
            } else {
              SuccessAlert("Orden registrada con exito", "Orden generada", "success")
            }
          }

        } catch (err) {
          console.log(err)
        }

        /*
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
  
        swalWithBootstrapButtons.fire({
          title: 'Lo sentimos ocurrio un error',
          text: "La orden no se registro correctamente.",
          icon: 'warning',
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonText: 'Intentar nuevamente',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            registernewOrder(0);
          } else if (
            
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              'La transaccion no fue salvada',
              'No podras recueperar la transaccion',
              'error'
            )
          }
        })*/
      }
    } else {
      console.log("Hay una orden en proceso");
    }
  }

  //print order
  const printOrder = async (id_Orden_Imprimir, transaction_code, micros) => {
    let type_ticket = ""
    let imprimir_comanda = ""
    if (preOrderInvoice == true) {
      //type_ticket = "factura_precuenta"
      type_ticket = "factura"
      imprimir_comanda = "no"
    } else {
      type_ticket = "factura"
      imprimir_comanda = "si"
    }

    let arrayPrinters = []
    if (dataPreOrder.pre_Order == true) {
      printers.forEach((printer) => {
        if (printer.printer_type == "Tickets") {
          arrayPrinters.push(printer)
        }
      });

    } else {
      printers.forEach((printer) => {
        arrayPrinters.push(printer)
      });
    }

    let JsonImprimir = [{
      "type_ticket": type_ticket,
      "Printers": arrayPrinters,
      "Nombre_maquina_impresora": infoPrinter.printer_ticket,
      "Nombre_cocina_impresora": infoPrinter.printer_comanda,
      "Nombre_zebra_impresora": infoPrinter.printer_zebra,
      "Id_Orden_Imprimir": id_Orden_Imprimir,
      "token": localStorage.getItem('x-access-machine-token'),
      "transaction_code": transaction_code,
      "amount_item": shoppingCart.length,
      "imprimir_comanda": imprimir_comanda,
    }]

    try {
      const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, localStorage.getItem('x-access-machine-token'));

      if (micros == true) {
        let dataRequestMicros = {
          "body": JsonImprimir, "method": "POST", "url": "http://localhost:9090/impresion"
        }
        const response = await RequestAlohaFetchAPI.requestLocalIp(dataRequestMicros, response_machine.data.data.windows_ip)
      } else {
        const response = await PrinterFetchAPI.printOrder(JsonImprimir, response_machine.data.data.windows_ip)
      }


    } catch (err) {
      console.log(err);
    }
  }

  //delete pre order
  const deleteTempPreOrder = async (transaction_code, orden_code) => {

    let token = localStorage.getItem('x-access-machine-token');
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice'));
    let business = JSON.parse(localStorage.getItem('business'));
    let user = JSON.parse(localStorage.getItem('user'));


    let business_code = business.code
    let branch_code = branchoffice.code

    if (codeParthner != 0) {

      business_code = codeParthner.business_code
      branch_code = codeParthner.branchoffice_code
    }

    let jsonUpdate = {
      "order_code": dataPreOrder.code,
      "business_code": business_code,
      "branchoffice_code": branch_code,
      "deleted": false,
      "status": 3,
      "user_id": user.id,
      "finished_order_transaction_code": transaction_code,
      "finished_order_code": orden_code
    }
    try {

      //We save the preorder
      await DeletedTempOrdersFetchAPI.newDeletedTempOrder(jsonUpdate, token);

      const response = await PreOrderFetchAPI.deletePreOrder(jsonUpdate, token)

    } catch (err) {
      console.log(err);
    }

  }

  //get last order in this machine
  const lastOrderMachineRequest = async () => {
    let token = localStorage.getItem('x-access-machine-token');
    try {
      const response = await MachinesFetchAPI.lastOrderMachine(machine.id, token);

      setLastOrder_id(response.data.last_order._id)

    } catch (err) {
      console.log(err)
    }
  }

  // const listmethod = () => {
  //   console.log(paymentMethod);
  //   const listMethods = paymentMethod.map((info) => (
  //     <>
  //       {/* <div className=' col-md-4 m-2'></div> */}
  //     </>
  //   ));
  //   return (listMethods)
  // }

  const functionGetRequest = async (turn) => {
    //Variables 
    let token = localStorage.getItem('x-access-machine-token');
    let branchoffice = localStorage.getItem('branchoffice') !== '' ? JSON.parse(localStorage.getItem('branchoffice')) : ''
    let machine = localStorage.getItem('machine') !== '' ? JSON.parse(localStorage.getItem('machine')) : ''
    try {
      //We check the data on the local storage
      if (machine !== '' && machine !== null && machine !== undefined) {
        getPaymentMethod(machine.id, token)
        if (turn == 1) {
          getOrderNumber(branchoffice.id, token)
        }

      }

      //------------------------------------------------

    } catch (err) {
      console.log(err);
    }
  }


  const getInventory = async () => {
    let array = []
    shoppingCart.forEach(product => {
      if (product.group_id && product.group_id != null) {
        if (product.group_id == "limited") {
          array.push({ "group_id": product.group_id })
        }
      }
    });
    let data = { "products": array }

    if (array.length > 0) {
      try {
        const response = await InventoryFechtAPI.getInventoryByProducts(data, localStorage.getItem("x-access-machine-token"))
        let dataInventory = response.data.inventory

        let arrayProducts = []
        shoppingCart.forEach((product, index) => {
          dataInventory.forEach(group => {
            if (group.group_id === product.group_id) {

              let filter = shoppingCart.filter(group => group.group_id == product.group_id)

              let amountNow = 0
              if (filter.length > 0) {
                filter.forEach(group => { amountNow = (amountNow + group.amount) })
              } else {
                amountNow = product.amount
              }

              if (amountNow <= group.quantity) {

              } else {
                let dataProduct = product
                dataProduct.quantity = group.quantity
                dataProduct.index = index

                arrayProducts.push(dataProduct)

              }
            }
          })


        });

        if (arrayProducts.length > 0) {

          shoppingCart.forEach((item) => {
            let filter = arrayProducts.filter(group => group.group_id == item.group_id)
            let quantity = 0
            if (filter != undefined && filter.length > 0) {
              filter.forEach(group => { quantity = (group.quantity) })
              item.quantity = quantity
            }
          })

          setEventHandler(1)

          ErrorAlert("", "Hay productos que requieren ser revisados", "warning")
          //setdataProductInventoryFauld(arrayProducts)
          //setShowModalDetailInventoryByGroup(true)
        } else {
          nextGreenInventory();
        }
      } catch (err) {
        nextGreenInventory();
        console.log(err);
      }
    } else {
      nextGreenInventory();
    }

    //continue with the flow of the transaction
    function nextGreenInventory() {
      setShow(true)
      getPrinter()
      setBotonDeshabilitado(false)
      setPaymentInfo({ payment: "" })
      lastOrderMachineRequest()
      functionGetRequest()
    }

  }


  useEffect(() => {
    (async () => {
      functionGetRequest(1)
    })();
  }, [])

  useEffect(() => {
    let timer = 0
    if (statusRequestYappy == true) {
      timer = setInterval(async () => getYappyTransaction(), 5000); //INTERVAL GET YAPPY STATUS TRANSACTION

      return () => clearInterval(timer)
    } else {
      return () => clearInterval(timer) //CLEAR INTERVAL
    }

  }, [statusRequestYappy, dataYappy]);

  const [fullscreen, setFullscreen] = useState(true);
  return (
    <>

      <button
        className='btn btn-success bg-brand-smartpay px-5 btn-lg w-100 border-0'
        onClick={() => (getInventory())}>
        <strong className='fs-label-1'>Pagar: </strong>
        <strong className='fs-label-1' translate='no'>${total.toFixed(2)}</strong>
      </button>

      <Modal
        show={show}
        size='xl'
        centered
        onHide={() => setShow(false)}

      >


        <Modal.Body className='p-2'>
          <section className='flex-container'>
            <div className="d-flex justify-content-center border-bottom-0">
              <h2 className="modal-title fw-bold fs-5">
                <i class="uil uil-transaction"></i>
                Centro de Pagos
              </h2>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
              <span className='text-success fw-bold'> Métodos de pago</span>
              <button className='btn bg-primary-subtle btn-sm text-primary  border-0 fw-bold' onClick={handleEditBillShow}>
                Personalizar Factura:
                <br />
                {name}
              </button>
            </div>

            <div className={
              !paymentMethod.length
                ? "d-block item2 border-inset bg-light-subtle border border-2 border-light-subtle rounded-3 list-discount-framer align-self-center overflow-auto w-100 d-flex align-items-center justify-content-center"
                : "row item2 border-inset bg-light-subtle border border-2 border-light-subtle rounded-3 list-modal-pays-framer   align-self-center  w-100"

            }>

              {
                !paymentMethod.length
                  ? <></>
                  : paymentMethod.map(option => (

                    <article className='col-12 col-xl-3 col-lg-4 col-md-6 my-1 list-pre-orders-inputs-pays' key={option.id + option.integration.id}>

                      <input
                        className="list-group-item-check-pay "
                        type="radio"
                        name="option_payments" id={option.id + "payment" + option.id + option.integration.id} value={JSON.stringify(option)} onChange={(e) => (selectPayment(e))}
                      />

                      <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-white rounded-2 p-1 shadow-sm"
                        htmlFor={option.id + "payment" + option.id + option.integration.id}
                      >
                        <div className="d-flex align-items-center gap-3 py-1 rounded" >
                          <img src={option.img}
                            alt="Product" width="80" height="80" className="rounded border flex-shrink-0" />
                          <div className="d-flex gap-2 justify-content-around py-2">
                            <span className="mb-3 text-wrap">
                              {option.payment}
                            </span>
                          </div>
                        </div>
                        <span class="badge bg-secondary-subtle text-secondary-emphasis rounded-pill bottom-0 end-0 position-absolute m-1">
                          {option.integration.name}
                        </span>
                      </label>
                    </article>
                  ))
              }
            </div>

            <div>
              <RenderIf isTrue={business.id == 13}>
                <ModalDonlee client_phone={client_phone} setClient_phone={setClient_phone} locator={locator} setLocator={setLocator} dataClient={dataClient} setDataClient={setDataClient}></ModalDonlee>
              </RenderIf>
              <RenderIf isTrue={moduleTips == 1}>
                <ModuleTips invoiceTips={invoiceTips} setInvoiceTips={setInvoiceTips} recomendationTips={recomendationTips} setEventHandler={setEventHandler}></ModuleTips>
              </RenderIf>
            </div>

            <span className='d-flex justify-content-between p-0 mt-1 text-success fw-bold'>Detalles de Pago</span>
            <div className="d-block d-lg-flex justify-content-between order-total mt-0 mb-auto border border-2 rounded-3 p-lg-2">
              <div className=' fs-label-2   mx-1 d-flex justify-content-between d-lg-inline ' >
                Descuento:
                <span>$ <span className='fw-bold'>{discount.toFixed(2)}</span></span> </div>
              <div className=' fs-label-2 fw-medium  mx-1 d-flex justify-content-between d-lg-inline'>
                Subtotal:

                <span>$ <span className='fw-bold'>{subtotal.toFixed(2)}</span></span></div>

              <div className=' fs-label-2   mx-1 d-flex justify-content-between d-lg-inline' >
                Impuestos:
                <span>$ <span className='fw-bold'>{tax.toFixed(2)}</span></span> </div>
              <div className=' fs-label-2   mx-1 d-flex justify-content-between d-lg-inline' >
                Total con impuesto:
                <span>$ <span className='fw-bold'>{total.toFixed(2)}</span></span></div>


              {invoiceTips > 0 ? <>
                <div className=' fs-label-2   mx-1 d-flex justify-content-between d-lg-inline' >
                  Total con propina:
                  <span>$ <span className='fw-bold'>{totaltopay.toFixed(2)}</span></span></div>
              </> : <></>}
            </div>
          </section>


          <div className='d-block mt-lg-1 mt-0'>
            <div className='d-flex justify-content-end mt-2'>
              <button className='btn  btn-lg btn-danger  border-0 fw-bold '
                onClick={() => (setShow(false))}> Cancelar </button>
              <button className='btn btn-lg bg-success-subtle
              bg-brand-smartpay
               text-success fw-bold border-0  ms-2'
                onClick={() => (processPayment())}>
                <span className='text-white'>Realizar Pago </span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div > {responseQr == true ? <ModalConfirmation setResponseQr={setResponseQr} orderCode={order_code} setPageLoader={setPageLoader} orderNumber={datatransaction_code} total={total} change={change} setChange={setChange} totaltopay={totaltopay} invoiceTips={invoiceTips} microsModule={microsModule} datatransaction_code={datatransaction_code} order_id={order_id} printOrder={printOrder} /> : ""}</div>


      {/* Modals */}
      <Modal show={showEditBill} onHide={handleEditBillClose} className='bg-blur-sp' centered>
        <Modal.Body>
          <section className='flex-container'>
            <div className="d-flex justify-content-center border-bottom-0">
              <h2 className="modal-title fw-bold fs-5">
                <i class="uil uil-edit"></i>
                Personalizar factura
              </h2>
            </div>
          </section>
          <div className=''>


            <div>{dataResponse == false ?

              <Form>
                <p className='p-0  text-primary fw-bold my-1'>
                  Carga de datos
                </p>

                {optionalData == true ? <>
                  <div className='d-flex align-items-center justify-content-center'>
                    <p className='text-size-order' > <b>Ruc:</b>  <span>{ruc}</span></p>
                  </div>

                  <div className='border shdaow-sm p-1 rounded-3 '>
                    <Form.Group className="mb-3 " controlId="formBasicText">
                      <Form.Label >Ingresa un nombre</Form.Label>
                      <Form.Control placeholder="Nombre" onChange={(e) => (setName(e.target.value))} />
                    </Form.Group>
                    <div className='col-xs-2'>
                      <Form.Group className="mb-3 " controlId="formBasicText">
                        <Form.Label >Ingresa un dv</Form.Label>
                        <Form.Control placeholder="00" onChange={(e) => (setDv(e.target.value))} />
                      </Form.Group>
                    </div>

                    <div className='d-flex align-items-center justify-content-center'>
                      <button className='btn bg-danger-subtle text-danger' type="button" onClick={() => (setOptionalData(false))}>
                        Cancelar
                      </button>
                      <button className='btn bg-info-subtle text-info ms-1' type="button" onClick={() => (setDataResponse(true))} >
                        Cargar datos
                      </button></div>
                  </div></>
                  :
                  <div>

                    <div className='d-flex align-items-center justify-content-center'>

                      <form className="w-100">
                        <span className='fw-bold text-primary'>Ingrese su RUC o cédula </span>
                        <div className="mb-3">
                          <input type="text" className="form-control border border-2 rounded-3"
                            placeholder="0-000-000" onChange={(e) => (setRuc(e.target.value))} />
                        </div>

                        <button className="w-100 mb-2 btn btn-lg rounded-3 btn-primary" type="button" onClick={() => (validDataClient())} >
                          Validar {contAuthRuc > 0 ? <>nuevamente: {contAuthRuc}</> : <></>}
                        </button>

                        <hr className="my-4" />
                        <h2 className="fs-5 fw-bold mb-3">O busque su cliente </h2>



                        <Modalclient getClientsByBusiness={getClientsByBusiness} setRuc={setRuc} setName={setName} setDv={setDv} setDataResponse={setDataResponse}></Modalclient>


                      </form>

                      {/* <Form.Group className="mb-3 " controlId="formBasicEmail">
                        <Form.Label >Ingresa un RUC</Form.Label>
                        <Form.Control placeholder="0-000-000-0" onChange={(e) => (setRuc(e.target.value))} />
                      </Form.Group>
                      <button className='btn btn-primary m-1 mt-3' variant="primary" type="button" onClick={() => (validDataClient())}>
                        Validar {contAuthRuc > 0 ? <>nuevamente: {contAuthRuc}</> : <></>}
                      </button> */}

                    </div>
                    {/* <div className=''>

                      <Modalclient getClientsByBusiness={getClientsByBusiness} setRuc={setRuc} setName={setName} setDv={setDv} setDataResponse={setDataResponse}></Modalclient>

                    </div> */}
                  </div>}
              </Form> : <>
                <span className='fw-bold text-primary'>Datos de Usuario</span>
                <div className='border bg-light-subtle text-primary-emphasis rounded-3'>
                  <div className="m-1">
                    <p className='text-size-order' >
                      <span>Nombre:
                        <strong> {name} </strong>
                      </span>
                    </p>
                    <p className='text-size-order' >
                      <span>RUC:</span> <b>{ruc}</b>
                      <span className='ms-2'>Dv:</span> <b>{dv}</b>
                    </p>
                  </div>
                </div>
                <button className='btn bg-primary-subtle text-primary fw-bold border-0 mt-1' type="button" onClick={() => (setDataResponse(false))}>Cambiar datos </button>

              </>
            }
            </div>

          </div>

          <div className='d-block mt-lg-1 mt-0'>
            <div className='d-flex justify-content-end mt-2'>

              <button className='btn bg-danger-subtle text-danger  border-0 fw-bold mx-2'
                onClick={handleEditBillClose}>
                Cerrar
              </button>
              <button className='btn bg-success-subtle text-success  border-0 fw-bold '
                onClick={handleEditBillClose}>
                Guardar datos de usuario
              </button>
            </div>
          </div>

        </Modal.Body>
      </Modal>



      {/* Modal Cashierpost */}
      <Modal
        fullscreen
        centered
        backdrop="static"
        show={modalShow}
        onHide={handleCloseModal}
        className='bg-blur-sp'
      >
        <Modal.Body className='p-2'>
          <h3 className="text-center  fw-bold ">
            <i class="uil uil-money-bill"></i>
            Área de Transacciones
          </h3>
          <div class="d-flex flex-row mb-3 gap-1 ">
            <div class="p-2 rounded border shadow-sm"> Orden: <b>{!datatransaction_code ? "0000" : datatransaction_code}</b> </div>
            <div class="p-2 rounded border shadow-sm">Monto a pagar: <b>${amountOrder}</b> </div>
          </div>
          <Cashierpost setChange={setChange} setAmountOrder={setAmountOrder} setOrderNumber={setOrderNumber} setModalShow={setModalShow} order_data={detailOrder} statusCash={statusCash} />
        </Modal.Body>
      </Modal>


      {/*Process payment Loading Modal */}
      <Modal
        fullscreen
        show={showModalProcess}
      >

        <Modal.Header className='border-0'>
          <strong> PAGOS </strong>
        </Modal.Header>
        <Modal.Body className='bg-pay-met'>
          <main className='d-flex justify-content-center align-items-center h-100 '>

            <section className='border rounded p-4 w-50 position-relative bg-white'>
              <div className='sections-messages'>
                <RenderIf isTrue={paymentApproval.status == 0}>
                  <div className='px-4 pb-1 mt-2 text-center'>
                    <img className='mx-auto mb-4 ' src={loading} width='100px' height='100px' />
                    <h2 className="display-6 fw-bold">
                      {paymentApproval.description}
                    </h2>
                    {tempMethodPayment == 1 ? <Button onClick={() => (cancelMethodPayment())}>Cancelar</Button> : ""}
                  </div>
                  <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-secondary">
                    Método de Pago: {paymentInfo.payment}
                  </span>
                </RenderIf>
                <RenderIf isTrue={paymentApproval.status == 1}>
                  <div className='px-4 pb-1 mt-5 text-center'>
                    <img className='mx-auto mb-4 ' src={checkimg} width='100px' height='100px' />
                    <h2 className="display-6 fw-bold">
                      ¡Éxito!
                    </h2>
                    <p> Su pago ha sido procesado correctamente.</p>
                  </div>
                  <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-success">
                    Método de Pago: {paymentInfo.payment}
                  </span>
                </RenderIf>
                <RenderIf isTrue={paymentApproval.status == 2}>
                  <div className='px-4 pb-1 mt-5 text-center'>
                    <img className='mx-auto mb-4 ' src={cancel} width='100px' height='100px' />
                    <h2 className="fw-bold">
                      {paymentApproval.description}
                    </h2>
                    <p> Por favor, verifique la información e intente nuevamente.</p>
                  </div>
                  <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
                    Método de Pago: {paymentInfo.payment}
                  </span>

                </RenderIf>

                <RenderIf isTrue={paymentApproval.status == 3}>
                  <div className='px-4 pb-1 mt-5 text-center'>
                    <img className='mx-auto mb-4 ' src={cancel} width='100px' height='100px' />
                    <h2 className="fw-bold">
                      {paymentApproval.description}
                    </h2>
                    <p>Por favor, verifique la información e intente nuevamente.</p>
                  </div>
                  <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
                    Método de Pago: {paymentInfo.payment}
                  </span>

                  <div className='d-flex justify-content-center alight-item-center m-2'>
                    <Button className='m-2' onClick={() => (cancelTempYappy())}>Volver</Button>
                    <Button className='m-2 ' onClick={() => (try_again_yappy())}> Intentar de nuevo</Button>
                  </div>

                </RenderIf>
              </div>
            </section>

          </main>
        </Modal.Body>
      </Modal>


      {/*input phone client */}
      <ModalPhoneSaveYappy showModalPhone={showModalPhone} setShowModalPhone={setShowModalPhone} setPhoneClientYappy={setPhoneClientYappy} nextStepYappy={nextStepYappy}></ModalPhoneSaveYappy>

      {/*Modal payment manual*/}
      <ModalPaymentManual showPaymentManual={showPaymentManual} setPaymentManual={setPaymentManual} paymentInfo={paymentInfo} registernewOrder={registernewOrder} statusActivity={true}></ModalPaymentManual>

      {/*Modal error register order */}
      <Modal
        size='lg'
        show={modalShowErrorRegisterOrder}
        onHide={() => setModalShowErrorRegisterOrder(false)}
        backdrop="static"
        centered>
        <Modal.Body>
          <h1 className='text-center m-3'>Lo sentimos ocurrio un error</h1>
          <h5 className='text-center'>La orden no pudo ser registrada</h5>
          <div className='d-flex alight-item-center justify-content-center'>
            <button className='m-3 btn btn-danger btn-lg' onClick={() => setModalShowErrorRegisterOrder(false)}> Cancelar </button>
            <button className=' m-3 btn btn-success btn-lg' onClick={() => getLastOrderMachineNow()}> Intentar nuevamente</button>
          </div>
        </Modal.Body>
      </Modal>

      {/** Modal to cashback */}
      <Modal
        show={cashbackModal}
        onHide={() => setCashbackModal(false)}
        backdrop="static"
        centered>
        <Modal.Body>
          <h1 className='text-center m-3'>Ingresar el monto de cashback</h1>
          <input type="text" className='form-control' onChange={e => setCashback(e.target.value)} />
          <div className='d-flex alight-item-center justify-content-center'>
            <button className='m-3 btn btn-danger btn-lg' onClick={() => setCashbackModal(false)}> Cancelar </button>
            <button className=' m-3 btn btn-success btn-lg' onClick={() => {
              //We set the cashback value to the paymentInfo object
              paymentInfo.cashback = cashback;
              //We validate the payment method to make the payment
              if (paymentInfo.integration.id == 6) {
                //We make the payment with the cashback
                requestPaymentEvertech(paymentInfo);
                setChange(0);
                //We close the modal
                setTimeout(() => {
                  setCashbackModal(false)
                  setTempMethodPayment(1);
                }, 15000);
              } else {
                ErrorAlert("", "El metodo de pago no es valido", "error");
                setCashbackModal(false)
              }
            }} > Finalizar</button>
          </div>
        </Modal.Body>
      </Modal>

      <ModalYappyQrPos modalYappyQr={modalYappyQr} setModalYappyQr={setModalYappyQr} dataYappy={dataYappy} setDataHash={setDataHash} setYappy_transaction_id={setYappy_transaction_id} dataHash={dataHash} hadleCancelYappy={hadleCancelYappy} total={total} orderNumber={orderNumber}></ModalYappyQrPos>
      <ModalPaymentMixto showModalMixto={showModalMixto} setShowModalMixto={setShowModalMixto} paymentMethod={paymentMethod} total={total} subtotal={subtotal} discount={discount} tax={tax} orderNumber={orderNumber} registernewOrder={registernewOrder} listPaymentReady={listPaymentReady} setlistPaymentReady={setlistPaymentReady} amountPaymentReady={amountPaymentReady} setAmountPaymenReady={setAmountPaymenReady} shoppingCart={shoppingCart} amountPending={amountPending} setAmountPending={setAmountPending} aprovedManualPaymentacardMixto={0} ></ModalPaymentMixto>
      {/**Modal of insuficient message */}
      <Modal show={showModalInsufficientFunds} onHide={() => setShowModalInsufficientFunds(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Información</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex alight-item-center justify-content-center">
            <RenderIf isTrue={insufficientMessage !== null}>
              <div>
                <pre>{insufficientMessage}</pre>
              </div>
            </RenderIf>

            <RenderIf isTrue={insufficientMessage === null}>
              <div>
                <pre>No se genero voucher</pre>
              </div>
            </RenderIf>

          </div>
          <div className='row'>
            <div className="d-flex justify-content-center">
              <button className="btn btn-info" onClick={() => setShowModalInsufficientFunds(false)}>Cerrar</button>
              <button className="btn btn-success" onClick={() => setShowModalInsufficientFunds(false)}>imprimir</button>
            </div>
          </div>
        </Modal.Body >
      </Modal >
    </>
  )
}

export default PaymentPost



