import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { FilterOrderFetchAPI } from '../../api/Paymentpost/FilterOrder';
import { OrderReturnFetchAPI } from '../../api/Paymentpost/OrderReturn';
import LoadingAlert from '../Alerts/LoadingAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import { FaHistory } from 'react-icons/fa';
import { WhatsappFetchAPI } from '../../api/Whatsapp';
import { PaymentOperations } from "../../utils/PaymentOperations"
import { MachinesFetchAPI } from '../../api/Machines';
import { PrinterFetchAPI } from '../../api/Printer';
import { OrdersFetchAPI } from '../../api/Orders';
import { RequestLocalFetchAPI } from '../../api/Paymentpost/RequestLocal';
import { CategriesPosFetchAPI } from '../../api/Paymentpost/CategoriesPos';
import { RenderIf } from '../utils/RenderIf';
import { PluxeeFetchAPI } from '../../api/Pluxee';
import { RequestAlohaFetchAPI } from '../../api/Paymentpost/RequestAloha';
import imgCheck from "./img/check.png"

function ModalReturn({ actvLite, actvPlus, devolution_validation, pos_integration_name, codeParthner }) {
   const [datatransaction_code, setDataTransaction_code] = useState("");
   const [ModalConfirmation, setModalConfirmation] = useState(false)
   const [order_id, setOrder_id] = useState("");
   const [show, setShow] = useState(false);
   const [transaction_code, setTransaction_code] = useState("")
   const [order, setOrder] = useState({ order: "" })
   const [orderTaxes, setOrderTaxes] = useState([])
   const [orderReturnSize, setOrderReturnSize] = useState(0)
   const [authorizationNumber, setAuthorizationNumber] = useState("")
   const [transactionId, setTransactionId] = useState("")
   const [referenceNumber, setReferenceNumber] = useState("")
   const [infoPrinter, setInfoPrinter] = useState({ printer_comanda: "", printer_ticket: "" })
   const [bank_voucher_response, setBank_voucher_response] = useState({ bank_voucher_response: "" })
   const [detailPayment, setDetailPayment] = useState([]);
   const [printers, setPrinters] = useState([]);
   let machine = localStorage.getItem('machine');
   let token = localStorage.getItem('x-access-machine-token');
   let user = localStorage.getItem('user');
   let datageneral = localStorage.getItem("generalData");
   //get machine
   if (user !== '' && user !== null && user !== undefined) {
      user = JSON.parse(localStorage.getItem('user'))
   }

   if (machine !== '' && machine !== null && machine !== undefined) {
      machine = JSON.parse(localStorage.getItem('machine'))
   }

   if (datageneral !== '' && datageneral !== null && datageneral !== undefined) {
      datageneral = JSON.parse(localStorage.getItem("generalData"));
   }

   //get order
   const getOrder = async () => {
      console.log("Estoy en consultar orden");
      let token = localStorage.getItem('x-access-machine-token');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'));

      let business_code = business.code
      let branch_code = branchoffice.code

      if (codeParthner != 0) {
         business_code = codeParthner.business_code
         branch_code = codeParthner.branchoffice_code
      }

      try {

         const response = await FilterOrderFetchAPI.getOrder(transaction_code, branch_code, business_code, token)
         console.log(response);
         let getorder = response.data.order
         getorder.paymentmethod_params = response.data.paymentmetod
         //format json 
         for (let i = 0; i < getorder.groups.length; i++) {
            for (let s = 0; s < getorder.groups[i].segments.length; s++) {
               getorder.groups[i].segments[s].item_id = getorder.groups[i].segments[s].item.item_id
               getorder.groups[i].segments[s].name = getorder.groups[i].segments[s].item.name
               getorder.groups[i].segments[s].cantidadItem = getorder.groups[i].segments[s].item.amount
               getorder.groups[i].segments[s].description = getorder.groups[i].segments[s].item.description
               getorder.groups[i].segments[s].img = getorder.groups[i].segments[s].item.img
            }
            getorder.groups[i].DetalleSegmentos = getorder.groups[i].segments
            getorder.groups[i].cantidadItem = getorder.groups[i].amount
            getorder.groups[i].descuento = getorder.groups[i].discount
            //getorder.groups[i].taxtValue = getorder.groups[i].tax.amount
            //getorder.groups[i].tax = getorder.groups[i].tax.percentage
         }

         //Format the taxes json
         let groupedTaxes = Object.values(
            getorder.taxes.reduce((acc, item) => {
               if (!acc[item.tax]) {
                  acc[item.tax] = { tax: item.tax, total: 0 };
               }
               acc[item.tax].total += item.total;
               return acc;
            }, {})
         )

         if (getorder.bank_voucher_response != "0" && getorder.order_type != "devolution") {
            getorder.bank_voucher_response = JSON.parse(getorder.bank_voucher_response)
         }

         setOrder(getorder);
         setOrderTaxes(groupedTaxes)
         console.log(response);
         console.log(response.data.order.bank_voucher_response);
         setBank_voucher_response(response.data.order.bank_voucher_response);
         setDetailPayment(response.data.paymentmetod)
      } catch (err) {
         ErrorAlert('', 'Error de consulta', 'error')
         console.log(err);
      }
   }

   //get printer 
   let arrayPrintersTemp = []
   const getPrinter = async () => {
      try {
         const response = await PrinterFetchAPI.getPrinter(localStorage.getItem('x-access-machine-token'), machine.id);

         let arrayPrinters = response.data.printers;
         let printer_Comanda = "";
         let printer_ticket = ""
         let printer_zebra = "";

         for (let i = 0; i < arrayPrinters.length; i++) {

            getCategoriesByprinter(arrayPrinters[i])

            if (arrayPrinters[i].printer_type == 'Tickets') {
               printer_ticket = arrayPrinters[i].printer_name
            } else if (arrayPrinters[i].printer_type == 'Comanda') {
               printer_Comanda = arrayPrinters[i].printer_name
            }
         }

         setPrinters(arrayPrintersTemp)
         setInfoPrinter({ printer_comanda: printer_Comanda, printer_ticket: printer_ticket });

      } catch (err) {
         console.log(err);
      }
   }

   const getCategoriesByprinter = async (printer) => {

      try {
         const response = await CategriesPosFetchAPI.getCategoriesByPrinters(printer.printer_id, localStorage.getItem('x-access-machine-token'))
         printer.categories = response.data.categories

      } catch (err) {
         printer.categories = []
         console.log(err);
      }
      arrayPrintersTemp.push(printer)

   }

   //Function to create a temp order return 
   const [stateRequest, setStateRequest] = useState(false)
   const createOrderTempReturn = async () => {
      if (stateRequest == false) {
         LoadingAlert()
         setStateRequest(true)
         let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
         let machine = JSON.parse(localStorage.getItem('machine'));

         let jsonDiscountTemp = {
            "client_name": order.client_name,
            "client_id": order.client_id !== undefined && order.client_id != 0 ? order.client_id : 0,
            "client_ruc": order.client_ruc,
            "client_dv": order.client_dv,
            "cupon_code": "0",
            "is_cupon": false,
            "localizador": "0",
            "client_phone": "0",
            "dgi_qr": "0",
            "Orden": order.groups,
            "combos": order.combos,
            "Descuento": order.discount,
            "Total": order.total,
            "itbms": order.tax,
            "taxes": order.taxes,
            "subtotal": order.subtotal,
            "machine_id": machine.id,
            "branchoffice_id": branchoffice.id,
            "tipoOrden": order.order_type,
            "paymethod": order.paymenthod,
            "is_discount_notification": false,
            "is_devolution_notification": true,
            "order_code_reference": transaction_code,
            "user": {
               id: user.id,
               name: user.name,
               code: user.user_code
            },

         }

         //wE CHECK IF THE ORDER HAS MICROS PARAMS TO SEND 
         if (datageneral[0].data.business && datageneral[0].data.business.pos_integration_name === "Micros" && datageneral[0].data.business.pos_integration_params !== null) {
            jsonDiscountTemp.micros_reference = {
               checkSeq: order.micros_reference.checkSeq,
               checkNum: order.micros_reference.checkNum
            }
         }
         //We check if the order is already a devolution 
         if (order.order_type === "devolution") {
            ErrorAlert('Este numero de orden, corresponde a una devolución', 'Error', 'warning')
         } else {
            try {
               //We create the temp order 
               await OrderReturnFetchAPI.createTempReturn(jsonDiscountTemp, token)

               if (devolution_validation === 0) {
                  LoadingAlert("Devolucion en proceso", "Espere un momento por favor...")
               } else {
                  LoadingAlert("Solicitud de devolución", "Espere un mientras el administrador aprueba la devolucion")
               }

               getOrderTempReturn()

               try {
                  const response = await WhatsappFetchAPI.sentDiscountNotification(jsonDiscountTemp, token)
                  console.log(response)
               } catch (err) {
                  console.log(err)
               }
            } catch (err) {
               console.log(err);
               setStateRequest(false);
               ErrorAlert(err.response.data.error, 'Error', 'warning')
               //getOrderTempReturn(0)
            }
         }
      }
   }

   //get order temporal return
   let instervalReturn = 0
   let countReturn = 0
   let orderSize = 0
   const getOrderTempReturn = async () => {
      let token = localStorage.getItem('x-access-machine-token');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'));
      let machine = JSON.parse(localStorage.getItem('machine'))

      let business_code = business.code
      let branch_code = branchoffice.code

      if (codeParthner != 0) {
         business_code = codeParthner.business_code
         branch_code = codeParthner.branchoffice_code
      }

      clearInterval(instervalReturn)
      try {
         const response = await OrderReturnFetchAPI.getTempReturn(machine.code, business_code, branch_code, transaction_code, token)
         console.log(response);
         console.log(response.data.order.groups.length);
         orderSize = response.data.order.groups.length
         setOrderReturnSize(response.data.order.groups.length)
         //-----------------------------------------------------------------

         let code = response.data.order.code
         let message = ""
         let title = ""
         let icon = ""


         if (devolution_validation === 0) {
            approvedReturn()
         } else {
            if (response.data.order.status_id == 1) {
               if (countReturn <= 5) {
                  instervalReturn = setInterval(getOrderTempReturn, 5000)
                  countReturn++
               } else {
                  message = "Tiempo de espera agotado"
                  title = "Advertencia"
                  icon = "warning"
                  updateTemReturn(code, 5, message, title, icon) //state 5 delete order dicount
                  setStateRequest(false);
                  setShow(false)
                  countReturn = 0
               }
            } else if (response.data.order.status_id == 8) {//order temporal is cancel
               message = "Orden devolucion cancelada."
               title = "Operacion denegada"
               icon = "error"
               setStateRequest(false);
               updateTemReturn(code, 5, message, title, icon)
               setShow(false)
            } else if (response.data.order.status_id == 9) {//order temporalsuccess
               approvedReturn()

            }
         }


         function approvedReturn() {
            message = "Devolucion aplicada con exito."
            title = "Operacion exitosa"
            icon = "success"
            console.log("lo que tiene orden es", order);
            //wE CHECK IF THE ORDER HAS MICROS PARAMS TO SEND 
            if (datageneral[0].data.business && datageneral[0].data.business.pos_integration_name === "Micros" && datageneral[0].data.business.pos_integration_params !== null) {
               let host_url = datageneral[0].data.business.pos_integration_params.host_url;
               let employeeNum = datageneral[0].data.business.pos_integration_params.employeeNum;
               let RvcRef = datageneral[0].data.business.pos_integration_params.RvcRef;
               let localMicrosServerIp = datageneral[0].data.business.pos_integration_params.localMicrosServerIp;
               //We make the void on micros
               voidCheckMicrosOrderLocal(localMicrosServerIp, RvcRef, employeeNum, host_url, response.data.order.micros_reference.checkSeq, response.data.order.micros_reference.checkNum, token)
            }
            //We check if the payment method integration is 6 (Evertech)
            if (order.paymenthod_integration_id === 6) {
               voidEvertech(code, message, title, icon);
               //Integration banesco
            } else if(order.paymenthod_integration_id === 4){ 
               voidBanesco(code, message, title, icon);
               //Integration pluxee
            }else if (order.paymenthod_integration_id === 7) {
               //We check if the payment method is pluxee
               if (order.paymenthod_id === 21) {
                  reversalPluxee(code, message, title, icon, order.machine_id);
               } else {
                  reversalPluxeeVoucher(code, message, title, icon, order.machine_id);
               }
            }
            //We check if the payment method is credit or debit
            else if (bank_voucher_response == "0" || order.paymenthod.toUpperCase() === "CLAVE" || order.paymenthod.toUpperCase() === "YAPPY" || order.paymenthod.toUpperCase() === "YAPPY QR" || order.paymenthod.toUpperCase() === "MIXTO") {
               let newVoucher = { EMVStreamResponse: "0" };
               registerOrderTempReturn(code, 3, message, title, icon, newVoucher, response.data.order.devolution_order_reference.approved_by);
            }
            else {
               voidCreditDebit(code, message, title, icon);
            }


            //updateTemReturn(code, 3, message, title, icon);
            setAuthorizationNumber(order.bank_voucher_response.authorizationNumber);
            setTransactionId(order.bank_voucher_response.transactionId)
            setReferenceNumber(order.bank_voucher_response.referenceNumber)
            //We clean the state of the products checked 
            setShow(false)
            setStateRequest(false);
         }
         //-----------------------------------------------------------------
      } catch (err) {
         setStateRequest(false)
         console.log(err);
         ErrorAlert("Error de transaccion de la temporal.", "Error de temporal", "error")
      }
   }

   //We make the void on micros  
   const voidCheckMicrosOrderLocal = async (ip, RvcRef, employeeNum, host_url, checkSeq, checkNum, token) => {
      let data = {
         Ruta: `http://${ip}:9472/api/micros/check?RvcRef=${RvcRef}&employeeNum=${employeeNum}&host_url=${host_url}`,
         Type: "post",
         Header: { "x-access-token": token },
         Body: {
            "checkNum": checkNum,
            "checkSeq": checkSeq
         }
      }
      let micros_response;
      console.log("entre a peticion local micros");

      try {
         micros_response = await RequestLocalFetchAPI.postMicros(data);
         console.log(micros_response);
      } catch (err) {
         console.log(err);
      }


   }


   //register order temporal thwn is aprobed
   const registerOrderTempReturn = async (code, status, message, title, icon, data_bank_voucher_response, approved_by) => {
      let token = localStorage.getItem('x-access-machine-token');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'));

      let business_code = business.code
      let branch_code = branchoffice.code

      if (codeParthner != 0) {
         business_code = codeParthner.business_code
         branch_code = codeParthner.branchoffice_code
      }


      try {
         const response = await OrderReturnFetchAPI.registerOrderReturn(transaction_code, business_code, branch_code, token, data_bank_voucher_response.EMVStreamResponse, approved_by);
         setStateRequest(false)
         updateTemReturn(code, status, message, title, icon);
         printOrder(response.data.data.order_id, response.data.data.transaction_code);

         setDataTransaction_code(response.data.data.transaction_code);
         setOrder_id(response.data.data.order_id);
         setModalConfirmation(true)
      } catch (err) {
         setStateRequest(false)
         console.log(err);
      }
   }

   // update order temporal return to delete
   const updateTemReturn = async (code, status, message, title, icon) => {
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'))

      let business_code = business.code
      let branch_code = branchoffice.code

      if (codeParthner != 0) {
         business_code = codeParthner.business_code
         branch_code = codeParthner.branchoffice_code
      }

      let jsonUpdate = {
         "order_code": code,
         "business_code": business_code,
         "branchoffice_code": branch_code,
         "status": status
      }
      try {
         await OrderReturnFetchAPI.updateOrderTempReturn(jsonUpdate, token)

         setOrder({ order: "" })
         ErrorAlert(message, title, icon)

      } catch (err) {
         console.log(err);
      }
   }

   //print order
   const printOrder = async (id_Orden_Imprimir, transaction_code_order, micros) => {

      let JsonImprimir = [{
         "type_ticket": "Nota a credito",
         "Printers": printers,
         "Nombre_maquina_impresora": infoPrinter.printer_ticket,
         "Nombre_cocina_impresora": infoPrinter.printer_comanda,
         "Nombre_zebra_impresora": "",
         "Id_Orden_Imprimir": id_Orden_Imprimir,
         "token": localStorage.getItem('x-access-machine-token'),
         "transaction_code": transaction_code_order,
         "amount_item": orderSize,
         "imprimir_comanda": "no",
      }]

      try {

         const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, localStorage.getItem('x-access-machine-token'));


         if (micros == true) {
            let dataRequestMicros = {
               "body": JsonImprimir, "method": "POST", "url": "http://localhost:9090/impresion"
            }
            const response = await RequestAlohaFetchAPI.requestLocalIp(dataRequestMicros, response_machine.data.data.windows_ip)
            setModalConfirmation(false)
            ErrorAlert("", "Se envio a imprimir", "success")
         } else {
            const response = await PrinterFetchAPI.printOrder(JsonImprimir, response_machine.data.data.windows_ip)
            console.log(response);
         }

      } catch (err) {
         console.log(err);
      }
   }

   const voidCreditDebit = async (code, message, title, icon) => {
      console.log(bank_voucher_response);

      let authorizationNumber = bank_voucher_response.authorizationNumber;
      let referenceNumber = bank_voucher_response.referenceNumber;
      let systemTraceNumber = bank_voucher_response.systemTraceNumber;

      try {
         //request get ip windows
         const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, localStorage.getItem('x-access-machine-token'));
         //request return total of the transaction
         const json = [{ Tipo: 'VOID', TipoVenta: "VOID", authorizationNumber: authorizationNumber, referenceNumber: referenceNumber, systemTraceNumber: systemTraceNumber, paymentMethod: { id: order.paymenthod_id, payment: order.paymenthod, payment_id: order.paymenthod_id, machine_id: machine.id, params: detailPayment } }];
         const response = await RequestLocalFetchAPI.postPaymentMethod(json, response_machine.data.data.windows_ip);
         console.log(response.data.data);
         let newVoucher = { EMVStreamResponse: response.data.data.EMVStreamResponse }
         console.log("hola");

         if (response.data.data.EMVStreamResponse.responseCodeDescription) {
            if (response.data.data.EMVStreamResponse.responseCodeDescription == "APROBADA") {
               registerOrderTempReturn(code, 3, message, title, icon, newVoucher);
            } else {
               ErrorAlert("No se aplico la devlucion.", "Error en la devolucion", "error")
            }
         } else {
            ErrorAlert("No se aplico la devlucion.", "Error en la devolucion", "error")
         }

      } catch (err) {
         console.log(err);
      }
   }

   //void evertech
   const voidEvertech = async (code, message, title, icon) => {
      try {

         //request get machine info of the order 
         const response_machine = await MachinesFetchAPI.getmachineByid(order.machine_id, localStorage.getItem('x-access-machine-token'));

         //We make the refund request
         const response = await PaymentOperations({
            operationType: "void",
            integrationType: 'Evertech',
            params: order.paymentmethod_params,
            referenceVoid: order.transaction_code,
            sECRStationID: response_machine.data.data.code,
            sCashierID: order.user.id,
            tips: 0,
            token: token
         });
         //We check if the response is success
         if (response && response.data && response.data.approval_code === "00") {
            console.log("void de evertech", response);
            let newVoucher = {
               EMVStreamResponse: response.data
            };
            registerOrderTempReturn(code, 3, message, title, icon, newVoucher);
         } else {
            console.log("Error en el void de evertech", response);
            //We show an error alert
            ErrorAlert("No se aplico la devlucion.", "Error en la devolucion", "error")
         }

      } catch (err) {
         console.log(err);
         ErrorAlert('', 'Error en el void de evertech', 'error')
      }
   }

   //Void banesco 
   const voidBanesco = async (code, message, title, icon) => {
      try {

         //We make the refund request
         const response = await PaymentOperations({
            operationType: "void",
            integrationType: 'Banesco',
            params: order.paymentmethod_params,
            invoice: order.transaction_code,
            token: token
         });
         //We check if the response is success
         if (response && response.data && response.data.TxnRspCode === "00") {
            console.log("void de banesco", response);
            let newVoucher = {
               EMVStreamResponse: response.data
            };
            //We register the order return
            registerOrderTempReturn(code, 3, message, title, icon, newVoucher);
         } else {
            console.log("Error en el void de banesco", response);
            //We show an error alert
            ErrorAlert("No se aplico la devlucion.", "Error en la devolucion", "error")
         }

      } catch (err) {
         console.log(err);
         ErrorAlert('', 'Error en el void de banesco', 'error')
      }
   }

   //Reversal pluxee qr
   const reversalPluxee = async (code, message, title, icon, machine_id) => {
      try {
         let pluxee_transaction_id = order.pluxee.pluxee_transaction_id;
         let pluxee_instance_id = order.pluxee.pluxee_instance_id;
         console.log(pluxee_transaction_id, pluxee_instance_id, machine_id, token);
         //We get the pluxee transaction
         const response = await PluxeeFetchAPI.getPluxeeTransaction(pluxee_transaction_id, pluxee_instance_id, machine_id, token);
         //We check if the response is success
         if (response && response.data && response.data.data && response.data.data.responseData) {
            //We get the auth code
            let authId = response.data.data.responseData.authId;
            let siteId = response.data.data.responseData.merchant.siteId;
            //We make the reverse on pluxee 
            let reversal = await PluxeeFetchAPI.reversePluxeeTransaction(siteId, authId, machine_id, token);
            console.log(reversal);
            //We check if the response is success
            if (reversal && reversal.data) {
               let newVoucher = {
                  EMVStreamResponse: JSON.stringify({ authID_c: response.data.data.authID_c, source: response.data.data.source })
               };
               registerOrderTempReturn(code, 3, message, title, icon, newVoucher);
            } else {
               console.log("Error en el reverse de pluxee", response);
               //We show an error alert
               ErrorAlert("No se aplico la devlucion.", "Error en la devolucion", "error")
            }
         }
      } catch (err) {
         console.log("En el catch Error en el reverse de pluxee", err);
         //We show an error alert
         ErrorAlert("No se aplico la devlucion.", "Error en la devolucion", "error")
      }
   }

   //Reversal pluxee voucher 
   const reversalPluxeeVoucher = async (code, message, title, icon, machine_id) => {
      try {
         let pluxee_authID = order.pluxee_voucher.pluxee_authID;
         //We make the reverse on pluxee 
         let reversal = await PluxeeFetchAPI.reversePluxeeVoucherTransaction(pluxee_authID, machine_id, token);
         //We check if the response is success
         if (reversal && reversal.data) {
            let newVoucher = {
               EMVStreamResponse: JSON.stringify({ responseData: reversal.data.data.responseData })
            };
            registerOrderTempReturn(code, 3, message, title, icon, newVoucher);
         } else {
            console.log("Error en el reverse voucer de pluxee", reversal);
            //We show an error alert
            ErrorAlert("No se aplico la devolucion.", "Error en la devolucion", "error")
         }
      } catch (err) {
         console.log("En el catch Error en el reverse voucher de pluxee", err);
         //We show an error alert
         ErrorAlert("No se aplico la devolucion.", "Error en la devolucion", "error")
      }
   }
   // view get order to return
   const cardGetOrder = () => {
      const cardOrder = order.groups.map((product) => (
         <>
            <div className="d-flex text-muted pt-3 px-xs-2 border-bottom">
               <img className="bd-placeholder-img flex-shrink-0 me-2 rounded bg-secondary" src={product.img} width="42" height="42" />
               <div className="pb-3 mb-0  lh-sm  w-100">
                  <div className="d-flex justify-content-between ">
                     {/* Name */}
                     <span className="text-dark fw-bold title-product "> {product.name} </span>
                     {/* Cantidad/ Quantity*/}
                     <span className='px-1 number-counter'>
                        <p>{product.amount}</p>
                     </span>
                     <label className="fw-bold" >$ {product.price.toFixed(2)} </label>
                  </div>
                  {/** Segment items */}
                  <details open>
                     <summary> <small> Detalles </small> </summary>
                     {product.segments.map(item => (
                        <div>
                           <small className=''>{item.segment_name}: {item.name} - ${item.price}</small>
                        </div>
                     ))}
                  </details>
               </div>
            </div>
         </>
      ));
      return (cardOrder)
   }

   //Template card Group of setGroup
   const cardGroup = (product) => {
      return (
         <div className="card-group">
            <div className="card">
               <div className='d-flex justify-constent- between'>
                  <img src={product.img === null ? '' : product.img} className='m-2' alt="..." width="50" height="50" />
                  <div className="card-body">
                     <h5 className="card-title">{product.name}</h5>
                     <details close >
                        <summary className=''>
                           <span className='badge bg-light text-dark summary-text-pos  fw-bold border rounded-4  text-wrap '>
                              <i className="uil uil-info-circle  px-1 "></i>  Detalles
                           </span>
                        </summary>
                        {product.segments.map(item => (
                           <span className="badge bg-light text-dark  summary-text-pos m-2 border rounded-4 my-1 text-wrap ">
                              {/* <span className='kiosk-subtitle-s' >   </span> */}
                              <span className='text-muted m-0 p-0'>{item.name}:</span>
                              <br />
                              <strong>
                                 <span className='me-2'>{item.item.name} </span>
                                 {parseFloat(item.item.price).toFixed(2)}
                              </strong>
                           </span>
                        ))}
                     </details>
                  </div>
               </div>
            </div>
         </div>
      )
   }

   const orderCombos = () => {
      let items = order.combos.map((product) => (
         <div className="d-flex text-muted pt-3 px-xs-2" key={product.combo_id}>
            <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={product.img} width="42" height="42" />
            <div className="pb-3 mb-0  lh-sm  w-100">
               <div className="d-flex justify-content-between ">
                  {/* Name */}
                  <span className="text-dark fw-bold">{product.name} </span>
                  {/* Cantidad/ Quantity*/}
                  <div>
                     <span className='px-3'>x {product.amount} </span>
                     <label className="fw-bold" translate='no'>$ {product.price.toFixed(2)}</label>
                  </div>

               </div>
               {/** Segment items */}
               <>
                  <div className="d-flex justify-content-between">
                     {/* Name */}


                     {/* Cantidad/ Quantity*/}

                  </div>
                  {product.setGroups.map((setGroup) => (
                     <div className="d-flex justify-content-between" key={setGroup.set_id}>
                        <details close >
                           <summary className=''>
                              <span className='badge bg-light text-dark summary-text-pos  fw-bold border rounded-4  text-wrap m-1 '>
                                 <i className="uil uil-info-circle  px-1 "> {setGroup.name}</i>
                              </span>
                           </summary>
                           {setGroup.groups.map((group) => (<>
                              {cardGroup(group)}
                           </>))}
                        </details>
                     </div>))}

                  <div>
                     <div>
                        <small className='text-primary p-1' >
                           Entrega: {product.delivery_type}
                        </small>
                     </div>
                     <div>
                        <RenderIf isTrue={product.note != ""}>
                           <small className='text-danger p-1' >
                              Nota: {product.note}
                           </small>
                        </RenderIf>
                     </div>
                  </div>




               </>
            </div>
         </div>
      ));
      return <>{items}</>
   }

   let title_modal = "Devolución"
   return (
      <>
         {/* 
               <div className=" m-1 bg-white border rounded ">
                     <button className="btn btn-outline-primary  py-2 border-0" type="button" onClick={() => (setShow(true), getPrinter())}>
                        <i className="uil uil-book-reader buttons-label icon-mobile h3"></i>
                        <span className='h5 fw-bold mx-1 d-block text-center'>   {title_modal} </span>
                     </button>
                  </div> */}






         {actvPlus ?
            <button className='btn btn-outline-dark border-0' onClick={() => (setShow(true), getPrinter())}>
               <i className="uil uil-history-alt icon-mobile"></i>
               <small className='fw-bold label-responsive  hidden-label-sm '>
                  {title_modal}
               </small>
            </button> : <></>}


         {actvLite ? <div className=" m-1 bg-white border rounded ">
            <button className="btn btn-outline-primary  py-2 border-0" type="button" onClick={() => (setShow(true), getPrinter())}>
               <i className="uil uil-history-alt buttons-label icon-mobile h3"></i>
               <span className='h5 fw-bold mx-1 d-block text-center'>   {title_modal} </span>
            </button>
         </div> : <></>}




         <Modal
            show={show}
            size="md"
            onHide={() => setShow(false)}
            aria-labelledby="example-custom-modal-styling-title"
            centered
         >
            <div className='mt-2 text-center p-1'>
               <h4 className='fw-bold'><FaHistory /> {title_modal} </h4>
            </div>


            <Modal.Body>
               <div className='d-lg-flex justify-content-lg-between p-2'>
                  <form class="">
                     <input type="text" class="form-control border w-100" id="floatingInputValue"
                        placeholder="Ingrese número de orden" onChange={(e) => (setTransaction_code(e.target.value))} />

                  </form>
                  <div className='d-flex d-sm-block  justify-content-end mt-sm-0 mt-2 bor'>
                     <button className='btn btn-primary bg-primary-subtle text-primary border-0 fw-bold  ' onClick={async () => (getOrder())}>
                        <i class="uil uil-search-alt pe-1"></i>
                        Buscar orden</button>
                  </div>
               </div>

               <div className='p-2'>
                  {!order.groups ? "" : <>
                     <div className='row'>
                        <div className='col-sm-6'>
                           <a href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                              <div className="d-flex gap-2 w-100 justify-content-around">
                                 <div>
                                    <h5 className="mb-0">Orden - {order.transaction_code}</h5>
                                    <p className="mb-0 opacity-75">Fecha - {order.createdAt.slice(0, 10)}</p>
                                 </div>
                              </div>
                           </a>
                        </div>
                        <div className='col-sm-6'>
                           <a href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                              <div className="d-flex gap-2 w-100 justify-content-around">
                                 <div>
                                    <h5 className="mb-0">{order.client_name}</h5>
                                    <p className="mb-0 opacity-75">ruc: {order.client_ruc} dv: {order.client_dv}</p>

                                 </div>
                              </div>
                           </a>
                        </div>
                     </div>
                     <div className=''>
                        {cardGetOrder()}
                        {orderCombos()}</div>
                     <div className=''>
                        <div className="order-total mt-2 mx-1">
                           <p className='text-size-order' >Descuento: <span>$ <strong>{order.discount.toFixed(2)}</strong></span> </p>
                           <p className='text-size-order '>Subtotal: <span>$ <strong>{order.subtotal.toFixed(2)}</strong></span></p>
                           {orderTaxes.map((tax) => (
                              <><p className='text-size-order' >Impuestos {tax.tax}: <span>$ <strong>{tax.total.toFixed(3)}</strong></span> </p></>
                           ))}
                           <p className='text-size-order' > <b>Total con impuesto:</b>  <span>$ <strong>{order.total.toFixed(2)}</strong></span></p>
                        </div>
                     </div>
                  </>}
               </div>



               <div className='d-flex justify-content-between justify-content-lg-end mt-2'>

                  <button className='btn bg-danger-subtle text-danger  border-0 fw-bold '
                     onClick={() => setShow(false)}> Cancelar </button>



                  {devolution_validation == 0 ? <><button className='btn bg-success-subtle text-success fw-bold border-0  ms-1'
                     onClick={async () => (createOrderTempReturn())} disabled={stateRequest}> Generar devolucion </button></> : <><button className='btn bg-success-subtle text-success fw-bold border-0  ms-1'
                        onClick={async () => (createOrderTempReturn())} disabled={stateRequest}> Aplicar </button></>}

               </div>
            </Modal.Body>
         </Modal>


         <Modal
            show={ModalConfirmation}
            size="md"
            onHide={() => setModalConfirmation(false)}
            backdrop="static"
            centered
         >

            <Modal.Body>

               <div className="bg-light border-sp d-flex align-items-center justify-content-center ">
                  <img className='m-2' src={imgCheck} width="60px"></img>
                  <div >
                     <h1 className='text-center'>Orden: {datatransaction_code}</h1>

                     <div className=" col-md-12 d-flex  p-2 text-center" >
                        <span className="text-muted border border-2 rounded-3 p-1  w-100 bg-light"> Nota acrediro realizada
                        </span>
                     </div>


                  </div>

               </div>

               <div className="m-3 d-flex align-items-center justify-content-center ">
                  <Button className='m-1' variant='success btn-lg' onClick={() => setModalConfirmation(false)}>Continuar</Button>
                  <RenderIf isTrue={pos_integration_name == "Micros"}><Button className='m-1' variant='success btn-lg' onClick={() => printOrder(order_id, datatransaction_code, true)}>Imprimir en quiosco</Button></RenderIf>
               </div>
            </Modal.Body>
         </Modal>


      </>
   )
}

export default ModalReturn