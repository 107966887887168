import React from 'react'
import Swal from 'sweetalert2'; 

function ErrorAlert(message, title, icon, timer) {
 
  if(title == undefined || message == undefined || icon == undefined){
    message = "Ocurrio un error."
    title = "Error!"
    icon = 'error'
  }
  return (
        //We show a error alert 
        Swal.fire({
            icon: icon,
            title: title, //mensaje en pantalla
            text: message,
            showConfirmButton: false,
            timer: timer !== undefined ? timer : 1500
        })
  )
}

export default ErrorAlert