import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class UsersFetchAPI {
    //Funtion to get the business users 
    static async getBusinessUser(business_id) {
        const res = await server(`/users/${business_id}/business`, {
            method: "get",
        });
        return res;
    }

    //Function to get users by role and branchoffice
    static async getUsersByRoleAndBranchoffice(branchoffice_id, users_role_id) {
        const res = await server(`/users/${branchoffice_id}/branchoffice/${users_role_id}/role`, {
            method: "get",
        });
        return res;
    }

    //Function to get users by role and business
    static async getUsesByBusinessAndRole(business_id, users_role_id) {
        let final_string_users_role_id = "role=" + users_role_id.join("&role=")
        const res = await server(`/users/${business_id}/business?${final_string_users_role_id}`, {
            method: "get",
        });
        return res;
    }

    //Function to get user by code
    static async getUserById(id, token) {
        const res = await server(`/user/${id}`, {
            method: "get",
            headers: {
                "x-access-token": token
            }
        });
        return res;
    } 

    //Function to get user by code
    static async getUserByCode(code, token) {
        const res = await server(`/user/${code}/user_code`, {
            method: "get",
            headers: {
                "x-access-token": token
            }
        });
        return res;
    } 


     //Function to get user by idCard
     static async getUserByIdCard(idCard, token) {
        const res = await server(`/user/${idCard}/idCard?role=4`, {
            method: "get",
            headers: {
                "x-access-token": token
            }
        });
        return res;
    } 

    
}