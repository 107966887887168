import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class OrderReturnFetchAPI {
    static async createTempReturn(data, token) {
        console.log(data);
        const res = await server(`/temp/order`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async getTempReturn(machine_code, business_code, branchoffice_code, order_code_reference, token) {
        const res = await server(`/devolution/temp/machine/order/${machine_code}/${business_code}/${branchoffice_code}/${order_code_reference}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async updateOrderTempReturn(data, token) {
        const res = await server(`/temp/order`, {
            method: "delete",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async registerOrderReturn(order_code, business_code, branchoffice_code, token, EMVStreamResponse, approved_by) {
        const res = await server(`/refund/order/${order_code}/${business_code}/${branchoffice_code}`, {
            method: "post",
            data: {EMVStreamResponse, approved_by},
            headers: { 'x-access-token': token },
        });
        return res
    }


}