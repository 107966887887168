//React component
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { Modal, Col, ListGroup, Badge, Row, Button, Alert } from 'react-bootstrap';
//Img
import { RenderIf } from '../utils/RenderIf';
//Api 
import { RequestLocalFetchAPI } from '../../api/Paymentpost/RequestLocal';
import { MachinesFetchAPI } from '../../api/Machines';
import { PaymentMethodFetchAPI } from '../../api/Paymentpost/PaymentMethod';
import { LanguagesFetchAPI } from '../../api/Languages';
import { PrinterFetchAPI } from '../../api/Printer';
import { EvertechFetchAPI } from '../../api/Evertech';
//Alerts
import ErrorAlert from '../Alerts/ErrorAlert';
import LoadingAlert from '../Alerts/LoadingAlert';
//Components
import ModalCategoriesPrinter from './ModalCategoriesPrinter';
import ModalAddCategories from './ModalAddCategorie';
import ModalEditIp from './ModalEditIp';

// We create a function to get the balance of an EBT card
const evertechEbtBalance = async (data) => {
    try {
        // We make the sale
        const res = await EvertechFetchAPI.makeEBTOperation(data);
        // We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            // If the response exists, we check the approval code 
            if (res.data.approval_code !== "00") {
                console.log("Approval code error");
                ErrorAlert("Error", "Error en la comunicación con el servidor", "error");
                //We show the modal with the voucher
                // If the approval code is not 00, we return an error
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                ErrorAlert(`Exito
                    ` , "Consulta de saldo EBT", "success", 30000000
                );
                return res;
            }
        }

        // We return the response
        return res;
    } catch (error) {
        let parsedError;
        console.log("Error caught in evertechSale:", error);
        ErrorAlert("Error", "Error en la comunicación con el servidor", "error");
        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }

        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
};

const ModalSetting = ({ setPageLoader }) => {
    //Params 
    let user = localStorage.getItem('user');
    let token = localStorage.getItem('x-access-machine-token');
    let branchoffice = localStorage.getItem('branchoffice');
    let business = localStorage.getItem('business');
    let machine = localStorage.getItem('machine');
    const [machine_name_get, setMachine_name_get] = useState("");
    const [paymentMethod_list, setpaymentMethod_list] = useState([])
    const [printers_list, setPrinters_list] = useState([])
    const [eventHandle, setEventHandle] = useState(0)
    const [eventHandlerSwitch, setEventHandlerSwitch] = useState(0);
    const [infolanguage, setInfolanguage] = useState({ language: { name: "Español" } })
    const [showModalSetting, setshowModalSetting] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [selectLanguage, setSelectLanguage] = useState('');
    const [ebtVoucher, setEbtVoucher] = useState(null);
    const [ebtBalanceModal, setEbtBalanceModal] = useState(false);
    let ventanaYappy = 0;

    //We validate if the user is logged in
    if (user === null || user === undefined || user === '' || user === 'null') {
        user = {
            name: 'No registrado',
            img: 'https://www.pngitem.com/pimgs/m/30-307416_profile-icon-png-image-free-download-searchpng-employee.png'
        }
    } else {
        user = JSON.parse(localStorage.getItem('user'))
    }

    //We validate if the branch
    if (branchoffice !== null && branchoffice !== undefined && branchoffice !== '') {
        branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
    }

    //We validate if the branch
    if (business !== null && business !== undefined && business !== '') {
        business = JSON.parse(localStorage.getItem('business'))
    }

    //We validate if the branch
    if (machine !== null && machine !== undefined && machine !== '') {
        machine = JSON.parse(localStorage.getItem('machine'))
    }

    const getLanguages = async () => {
        try {
            const response = await LanguagesFetchAPI.getLanguages(token);
            setLanguages(response.data.languages)

        } catch (err) {
            console.log(err);
        }
    }


    const [switchExternalMenu, setSwitchExternalMenu] = useState(() => {
        if (localStorage.getItem('external_menu_manual') == "true") {
            return true
        } else {
            return false
        }
    })

    const [waiters, setWaiters] = useState(() => {
        if (localStorage.getItem('waiters') == "true") {
            return true
        } else {
            return false
        }
    })

    const handleSwitchChange = () => {
        let external_menu_manual = localStorage.getItem('external_menu_manual')
        if (external_menu_manual == "false") {
            localStorage.setItem('external_menu_manual', "true")
            setSwitchExternalMenu(true)
            setEventHandlerSwitch(1)
        } else {
            setSwitchExternalMenu(false)
            localStorage.setItem('external_menu_manual', "false")
            setEventHandlerSwitch(1)
        }
        setPageLoader(1)
    }

    const handleSwitchChangeWaiters = () => {
        let waitersStorage = localStorage.getItem('waiters')
        if (waitersStorage == "false") {
            localStorage.setItem('waiters', "true")
            setWaiters(true)
            setEventHandlerSwitch(1)
        } else {
            setWaiters(false)
            localStorage.setItem('waiters', "false")
            setEventHandlerSwitch(1)
        }
        setPageLoader(1)
    }



    const getMachineInfo = async () => {
        try {

            const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, localStorage.getItem('x-access-machine-token'));
            console.log(response_machine.data.data.language);
            setInfolanguage({ language: response_machine.data.data.language });
            const response = await MachinesFetchAPI.getMachinesByBranchOfficeAndType(branchoffice.id, machine.machine_type_id, token);
            let machines_list = response.data.data

            machines_list.forEach(machine_terminal => {
                if (machine_terminal.code == machine.code) {
                    console.log(machine_terminal);
                    setMachine_name_get(machine_terminal.machine_name)
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    //function action print order
    const getPrinter = async () => {
        try {
            const response = await PrinterFetchAPI.getPrinter(token, machine.id);
            let array = response.data.printers
            for (let i = 0; i < array.length; i++) {
                array[i].status_test = "pendinete"
            }
            setPrinters_list(array)


        } catch (err) {
            console.log(err);
        }
    }


    const getPaymentMethod_list = async () => {
        try {
            const response = await PaymentMethodFetchAPI.getPaymentMethod(machine.id, token)
            let array = response.data.data
            for (let i = 0; i < array.length; i++) {
                array[i].status_test = "pendinete"
            }
            setpaymentMethod_list(array)

        } catch (err) {
            console.log(err);
        }
    }

    //function action request pyment local
    const requestPyament = async (payment_data, index) => {
        LoadingAlert("Enviando prueba metodo de pago: " + payment_data.payment, "Espere un momento por favor...")
        console.log(payment_data);
        console.log(index);
        let JsonTr = {}

        if (payment_data.payment == "Yappy") {
            JsonTr = [{
                Tipo: 'SALE',
                Producto: 'COBRAR',
                Itbms: 0.00,
                Descuento: 0.00,
                Monto: 0.00,
                TipoVenta: payment_data.payment.toUpperCase(),
                paymentMethod: payment_data,
                orderid: "0000",
                tel: "507" + "60000000",
                total: 0.00,
                subtotal: 0.00,
                taxes: 0.00,
            }];
        } else {

            let methodPayment = ""
            if (payment_data.payment == "MasterCard") {
                methodPayment = "MC"
            } else {
                methodPayment = payment_data.payment
            }

            JsonTr = [{
                Tipo: 'SALE',
                Producto: 'COBRAR',
                Itbms: 0.00,
                Descuento: 0.00,
                Monto: 0.00,
                TipoVenta: methodPayment.toUpperCase(),
                paymentMethod: payment_data,
                orderid: "0000",
            }];
        }

        try {

            const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, localStorage.getItem('x-access-machine-token'));
            const response = await RequestLocalFetchAPI.postPaymentMethod(JsonTr, response_machine.data.data.windows_ip);

            console.log(response)
            if (payment_data.payment != "Yappy") {
                let responseCode = response.data.data.EMVStreamResponse.responseCode;
                let description = response.data.data.EMVStreamResponse.responseCodeDescription

                if (responseCode == "00") {
                    paymentMethod_list[index].status_test = "Listo"
                    ErrorAlert("Comunicacion exitosa con terminal de cobro:", "Respuesta: " + description, "success")

                } else {
                    if (description == "OPERACION CANCELADA POR EL USUARIO" || description == "TIEMPO DE ESPERA AGOTADO") {
                        paymentMethod_list[index].status_test = "Listo"
                        ErrorAlert("Comunicacion exitosa con terminal de cobro:", "Respuesta: " + description, "success")

                    } else {
                        paymentMethod_list[index].status_test = "Listo"
                        ErrorAlert("Comunicacion con terminal de cobro:", "Respuesta: " + description, "info")

                    }
                }
                setpaymentMethod_list(paymentMethod_list)
                setEventHandle(1)

            } else {

                if (response.data.data == "") {
                    ErrorAlert("Ocurrio un error con el metodo de pago: " + payment_data.payment, "Verifique los parametros del metodo.", "error")
                } else {
                    ventanaYappy = window.open(response.data.data, "test", "");
                }
            }
        } catch (err) {
            paymentMethod_list[index].status_test = "Error"

            ErrorAlert("Error de comunicacion con el servidor local", "El servidor local esta fuera de linea", "error")
            console.log(err);
            setpaymentMethod_list(paymentMethod_list)
            setEventHandle(1)
        }
    }

    //Function to get ebt evertech balance
    const getEbtBalance = async (payment_data, index) => {
        let iDefaultHostPort, DefaultHostIP, sDefaultTID, iscashback, force_duplicate, special_account, manual_entry_indicator, payment_host, basereducedtax, statetax, reducedtax, citytax;
        LoadingAlert("Consultando saldo de EBT", "Espere un momento por favor...")

        //Get parameters of payment method
        payment_data.params.forEach((param) => {
            if (param.name === "iDefaultHostport") iDefaultHostPort = param.value;
            if (param.name === "DefaultHostIP") DefaultHostIP = param.value;
            if (param.name === "sDefaultTID") sDefaultTID = param.value;
            if (param.name === "iscashback") iscashback = param.value;
            if (param.name === "force_duplicate") force_duplicate = param.value;
            if (param.name === "payment_host") payment_host = param.value;
            if (param.name === "manual_entry_indicator") manual_entry_indicator = param.value;
        });

        let data = {
            sECRStationID: machine.code,
            sCashierID: user.id !== undefined ? user.id : "0000",
            sDefaultHostIP: DefaultHostIP,
            iDefaultHostPort: iDefaultHostPort,
            sDefaultTID: sDefaultTID,
            sRefNumber: "0000",
            tips: 0,
            iscashback: iscashback,
            cashback: 0,
            total: 0,
            basereducedtax: 0,
            statetax: 0,
            reducedtax: 0,
            citytax: 0,
            manual_entry_indicator: manual_entry_indicator,
            force_duplicate: force_duplicate,
            receipt_output: "both",
            special_account: "EB",
            sTransType: "EBT",
            EBTTransType: "BALANCE",
            payment_host: payment_host
        };

        try {
            const logonres = await EvertechFetchAPI.makeLogon({
                sECRStationID: machine.code,
                sCashierID: user.id !== undefined ? user.id : "0000",
                sDefaultHostIP: DefaultHostIP,
                iDefaultHostPort: iDefaultHostPort,
                sDefaultTID: sDefaultTID,
                sRefNumber: "0000",
            });
            //We check the response of the logon request
            if (logonres.data && logonres.data.approval_code === "ZY" && logonres.data.response_message === "Connection Error - LOGON") {
                console.log("si entre porq logon fallo")
                return logonres
            } else {
                return evertechEbtBalance(data)
                    .then(async (res) => {
                        //We show the modal with the voucher
                        setEbtVoucher(res.data.receipt_output.merchant + res.data.receipt_output.customer ? res.data.receipt_output.merchant + res.data.receipt_output.customer : null);
                        setEbtBalanceModal(true);
                        //We make loggoff
                        const logoff = await EvertechFetchAPI.makeLogoff({
                            sECRStationID: machine.code,
                            sCashierID: user.id !== undefined ? user.id : "0000",
                            sDefaultHostIP: DefaultHostIP,
                            iDefaultHostPort: iDefaultHostPort,
                            sDefaultTID: sDefaultTID,
                        });
                        console.log(logoff);
                        // We return the response
                        return res;
                    })
                    .catch(async (error) => {
                        console.log("Error in catch", error);
                        ErrorAlert("Error", "Error en la comunicación con el servidor", "error");
                        const logoff = await EvertechFetchAPI.makeLogoff({
                            sECRStationID: machine.code,
                            sCashierID: user.id !== undefined ? user.id : "0000",
                            sDefaultHostIP: DefaultHostIP,
                            iDefaultHostPort: iDefaultHostPort,
                            sDefaultTID: sDefaultTID,
                        });
                        console.log(logoff);


                        let parsedError;
                        // If the error is already an object, no need to parse
                        if (typeof error === 'object' && error !== null) {
                            console.log("Error is object", error);
                            parsedError = error;
                        } else {
                            console.log("Error is not object", error);

                            try {
                                parsedError = JSON.parse(error);
                            } catch (e) {
                                parsedError = { status: 500, data: error.message };
                            }
                        }
                        console.log("Parsed error1111", parsedError);

                        // Throw the formatted error object
                        if (parsedError.status && parsedError.data) {
                            console.log("Parsed error", parsedError);
                            return parsedError;
                        } else if (error.response) {
                            console.log("Error response", error.response);
                            return {
                                status: 400,
                                data: error.response.data
                            };
                        } else {
                            console.log("Error", error);
                            return error;
                        }
                    });
            }
        } catch (err) {
            ErrorAlert("Error en la comunicación con el servidor", "Error", "error");
        }
    }

    //Function to view payment list
    const viewPaymentlist = () => {
        const listView = paymentMethod_list.map((payment, index) => (
            <>
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-center align-items-center row"
                >
                    <div className="col-sm-4 d-flex justify-content-center align-items-center">
                        <img className='img-pay-met bg-success rounded px-2' src={payment.img} ></img>
                    </div>
                    <div className="col-sm-4">
                        <div className="fw-bold">{payment.payment}</div>
                        <RenderIf isTrue={payment.payment == "Yappy QR" || payment.payment == "Yappy" || payment.payment == "Efectivo" || payment.payment == "Credito"}>
                            <Badge bg="success" pill>
                                <i className="fa-solid fa-check"></i> Listo
                            </Badge>
                        </RenderIf>
                        <RenderIf isTrue={payment.payment == "Visa" || payment.payment == "Clave" || payment.payment == "MasterCard" || payment.payment == "Amex"}>
                            <RenderIf isTrue={payment.status_test == "pendinete"}>
                                <Badge bg="secondary" pill>
                                    <i className="uil uil-map-marker-info"></i> Por probar
                                </Badge>
                            </RenderIf>
                            <RenderIf isTrue={payment.status_test == "Error"}>
                                <Badge bg="danger" pill>
                                    <i className="uil uil-exclamation-triangle"></i> Error de comunicacion
                                </Badge>
                            </RenderIf>
                            <RenderIf isTrue={payment.status_test == "Listo"}>
                                <Badge bg="success" pill>
                                    <i className="fa-solid fa-check"></i> Listo
                                </Badge>
                            </RenderIf>
                        </RenderIf>
                        <RenderIf isTrue={payment.payment == "EBT" || payment.payment == "EBT Cash" || payment.payment == "EBT Unica" || payment.payment == "EBT Cash Unica"}>
                            <Button className='m-1' onClick={() => (getEbtBalance(payment, index))}><i className="uil uil-money-insert"></i>Consultar saldo</Button>
                        </RenderIf>

                        <RenderIf isTrue={payment.payment == "Visa" || payment.payment == "Clave" || payment.payment == "MasterCard" || payment.payment == "Amex"}>
                            <Button className='m-1' onClick={() => (requestPyament(payment, index))}><i className="uil uil-feedback"></i>Probar</Button>
                        </RenderIf>
                    </div>

                </ListGroup.Item>
            </>
        ));
        return (listView)
    }

    {/*view languages*/ }
    const viewLanguages = () => {

        const listItems = languages.map((language) => (
            <>

                <option value={language.id}>
                    <div translate='no'>
                        {language.name}
                    </div>
                </option>


            </>
        ));
        return (listItems)
    }

    const updateLanguage = async () => {
        try {
            let data = {
                "language_id": selectLanguage
            }
            const response = await MachinesFetchAPI.updateMachine(machine.id, data, token)
            console.log(response);

            LoadingAlert("Cambiando idioma", "Espere un momento por favor")
            setInterval(() => {
                window.location.reload(true);
            }, 3000);

        } catch (err) {
            console.log(err);
            ErrorAlert("No se puedo cambiar el idioma", "Opps", "error")
        }
    }

    const viewPrinterlist = () => {
        const listViewPrinter = printers_list.map((printer, index) => (
            <>

                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-center align-items-center row"
                >

                    <div className="col-md-4">
                        <div className="fw-bold">{printer.printer_name}</div>
                        <spam>Tipo: {printer.printer_type}</spam>
                        <spam>Ip adress: {printer.ip == null ? <small className="text-nowrap text-secondary"><div> No registrado</div></small> : printer.ip}</spam>
                        <spam>Estatus: <RenderIf isTrue={printer.status_test == "pendinete"}>
                            <small className="text-nowrap text-secondary"><div><i className="uil uil-map-marker-info"></i> Por probar</div></small>
                        </RenderIf>
                            <RenderIf isTrue={printer.status_test == "Error"}>
                                <small className="text-nowrap text-danger"><div><i className="uil uil-exclamation-triangle"></i> Error de comunicacion</div></small>
                            </RenderIf>
                            <RenderIf isTrue={printer.status_test == "Listo"}>
                                <small className="text-nowrap text-success"><div> <i className="fa-solid fa-check"></i> Listo</div></small>
                            </RenderIf>
                        </spam>
                    </div>

                    <div className="col-md-6">
                        <ModalEditIp printer={printer} setEventHandle={setEventHandle}></ModalEditIp>
                        <ModalCategoriesPrinter printer={printer} ></ModalCategoriesPrinter>
                        <ModalAddCategories printer={printer}></ModalAddCategories>
                        <Button className='m-2' ><i className="uil uil-print"></i> Probar</Button>
                    </div>

                </ListGroup.Item>



            </>
        ));
        return (listViewPrinter)
    }



    useEffect(() => {
        (async () => {
            try {
                getMachineInfo()
                getLanguages();
                getPaymentMethod_list()
                getPrinter()
            } catch (err) {
                console.log(err);
            }
        })();
    }, [])

    useEffect(() => {
        getPrinter();
        setEventHandle(0)
    }, [eventHandle])

    useEffect(() => { setEventHandlerSwitch(0) }, [eventHandlerSwitch])

    return (
        <>
            <div className="dropdown-item" onClick={() => (setshowModalSetting(true))}>
                <i className="uil uil-setting"></i>
                <span  >  Configuracion </span>
            </div>

            <Modal
                show={showModalSetting}
                size='xl'
                onHide={() => setshowModalSetting(false)}
                aria-labelledby="example-custom-modal-styling-title"
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-setting"></i> Configuración</h3>
                </Modal.Header>
                <Modal.Body>
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true"><i className="uil uil-info-circle"></i> Generales</button>
                            <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false"><i className="uil uil-card-atm"></i> Metodos de pago</button>
                            <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false"><i className="uil uil-print"></i> Impresoras</button>
                            {/* <button className="nav-link" id="nav-menu-tab" data-bs-toggle="tab" data-bs-target="#nav-menu" type="button" role="tab" aria-controls="nav-menu" aria-selected="false"><i className="uil uil-web-grid-alt"></i> Diseño de Menú</button> */}

                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active p-5 " id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div className='container'>
                                <div className='row d-flex justify-content-center alight-item-center'>
                                    <div className='col-auto'>
                                        <h3 className='text-center mb-2'><i className="uil uil-user"></i> Operador: {user.name}</h3>
                                        <Form className='mt-5'>


                                            <Form.Switch
                                                type="switch"
                                                id="external1"
                                                label={switchExternalMenu === true ? "Restaurantes socios activados" : "Restaurantes socios desactivados"}
                                                checked={switchExternalMenu === true ? true : false}
                                                onChange={handleSwitchChange}
                                            />

                                            <Form.Switch
                                                type="switch"
                                                id="waiters"
                                                label={waiters === true ? "Meseros activos" : "Meseros desactivados"}
                                                checked={waiters === true ? true : false}
                                                onChange={handleSwitchChangeWaiters}
                                            />

                                            
                                            <Row>
                                                <Col sm={6}>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>
                                                            Negocio:
                                                        </Form.Label>
                                                        <div>
                                                            {business.name}
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label >
                                                            Codigo de negocio:
                                                        </Form.Label>
                                                        <div>
                                                            {business.code}
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label >
                                                            Sucursal:
                                                        </Form.Label>
                                                        <div>
                                                            {branchoffice.name}
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label >
                                                            Codigo de sucursal:
                                                        </Form.Label>
                                                        <div>
                                                            {branchoffice.code}
                                                        </div>
                                                    </Form.Group>


                                                </Col>

                                                <Col sm={6}>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label >
                                                            Tipo de maquina:
                                                        </Form.Label>
                                                        <div>{machine.name_type_machine}</div>


                                                    </Form.Group>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label >
                                                            Nombre de la caja:
                                                        </Form.Label>
                                                        <div>{machine_name_get}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label >
                                                            Codigo de maquina:
                                                        </Form.Label>
                                                        <div>
                                                            {machine.code}
                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" >
                                                        <Form.Label >
                                                            Idioma:
                                                        </Form.Label>
                                                        <div>
                                                            <img className='m-1 img-idioma' src={infolanguage.language.image_url}></img> {infolanguage.language.name}
                                                        </div>

                                                    </Form.Group>

                                                    <Form.Group className="mb-3" >
                                                        <Form.Label >
                                                            Cambiar idioma:
                                                        </Form.Label>

                                                        <div className='m-1 row'>
                                                            <div className='col-sm-8'>
                                                                <Form.Select aria-label="Default select example" onChange={(e) => (setSelectLanguage(e.target.value))}>
                                                                    <option>Seleccione el idioma</option>
                                                                    {viewLanguages()}
                                                                </Form.Select>
                                                            </div>
                                                            <div className='col-sm-4'>
                                                                <Button className='' onClick={async () => (updateLanguage())} >Guardar</Button>
                                                            </div>
                                                        </div>
                                                    </Form.Group>




                                                </Col>
                                            </Row>


                                        </Form>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="tab-pane fade p-5 " id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <h3 className='text-center mb-2'><i className="uil uil-transaction"></i> Metodos de pago</h3>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <ListGroup as="ol" numbered>
                                                {viewPaymentlist()}
                                            </ListGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="tab-pane fade p-5 " id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">

                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <h3 className='text-center mb-2'><i className="uil uil-print"></i> Impresoras</h3>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <ListGroup as="ol" numbered>
                                                {viewPrinterlist()}
                                            </ListGroup>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade p-5 " id="nav-menu" role="tabpanel" aria-labelledby="nav-menu-tab">

                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <h3 className='text-center mb-2'><i className="uil uil-web-grid-alt"></i> Diseño de menú </h3>
                                        <div>



                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setshowModalSetting(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >


            {/**Modal to show the ebt balances */}
            <Modal show={ebtBalanceModal} onHide={() => setEbtBalanceModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Información</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex alight-item-center justify-content-center">
                        <RenderIf isTrue={ebtVoucher !== null}>
                            <div>
                                <pre>{ebtVoucher}</pre>
                            </div>
                        </RenderIf>

                        <RenderIf isTrue={ebtVoucher === null}>
                            <div>
                                <pre>No se genero voucher</pre>
                            </div>
                        </RenderIf>

                    </div>
                    <div className='row'>
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-info" onClick={() => setEbtBalanceModal(false)}>Cerrar</button>
                            <button className="btn btn-success" onClick={() => setEbtBalanceModal(false)}>imprimir</button>
                        </div>
                    </div>
                </Modal.Body >
            </Modal >







        </>
    )
}

export default ModalSetting