import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import ErrorAlert from '../Alerts/ErrorAlert';
import LoadingAlert from '../Alerts/LoadingAlert';
import ModalParthners from './ModalParthners';
import { UsersFetchAPI } from '../../api/Users';
import Swal from 'sweetalert2';


function ModalWaiters({ showWaiters, setShowWaiters, businessLogo, parthners, logo, selectRestaurant, setResetData, calculateInvoiceTotals, shoppingCart, shoppingCartCombos, codeParthner, setShoppingCart, setShoppingCartCombos }) {


    const [codeUser, setCodeUser] = useState('')
    const [showPassword, setShowPassword] = useState('')
    let logoWaiters = ""

    if (localStorage.getItem('logo') != '' && localStorage.getItem('logo') != null && localStorage.getItem('logo') != undefined) {
        logoWaiters = localStorage.getItem('logo')
    } else {
        logoWaiters = businessLogo
    }
    const toogglePasword = () => {
        setShowPassword(!showPassword);
    }

    //consulta el usuario por codigo
    const getUserByCode = async () => {
        LoadingAlert()
        let token = localStorage.getItem('x-access-machine-token')
        let branhoffice = JSON.parse(localStorage.getItem('branchoffice'))
        let userDataNow = JSON.parse(localStorage.getItem('user'))
        try {
            const response = await UsersFetchAPI.getUserByCode(codeUser, token)
            let user = response.data.user
            console.log(response.data);

            let dataBranhAndUser = user.branchoffices.find(branch => branch.branchoffice_id == branhoffice.id)
            console.log(dataBranhAndUser);

            if (dataBranhAndUser == undefined) {

                getUserByIdCard(user)
                
            } else {
                userDataNow.id = user.id
                userDataNow.lastName = user.lastName
                userDataNow.name = user.name
                userDataNow.user_code = user.user_code
                userDataNow.email = user.email
                userDataNow.idCard = user.idCard
                userDataNow.business_id = user.business_id
                localStorage.setItem('user', JSON.stringify(userDataNow))



                Swal.fire({
                    title: "Bienvenido " + user.name,
                    icon: "success",
                    draggable: true
                });
                setShowWaiters(false)

                localStorage.setItem('waiters', "false")
            }

            setCodeUser('')
            document.getElementById('code').value = ''

        } catch (err) {
            ErrorAlert('', 'Codigo invalida', 'error')

            console.log(err);
        }

    }

    //valida que el usuario con idCard este en la lista de usuarios de la sucursal o negocio
    const getUserByIdCard = async (userSelect) => {
        let token = localStorage.getItem('x-access-machine-token')
        let branhoffice = JSON.parse(localStorage.getItem('branchoffice'))
        let userDataNow = JSON.parse(localStorage.getItem('user'))
        let business = JSON.parse(localStorage.getItem("business"))

        try {
            const response = await UsersFetchAPI.getUserByIdCard(userSelect.idCard, token)
            let arrayUser = response.data.user;
            let business_id = 0;

            if(branhoffice.parthner_business){
                business_id = branhoffice.parthner_business
            }else{
                business_id= business.id
            }

            let userByBusiness = arrayUser.find(user => user.business_id === business_id)
            if (userByBusiness !== undefined) {
                userDataNow.id = userByBusiness.id
                userDataNow.lastName = userByBusiness.lastName
                userDataNow.name = userByBusiness.name
                userDataNow.user_code = userByBusiness.user_code
                userDataNow.email = userByBusiness.email
                userDataNow.idCard = userByBusiness.idCard
                userDataNow.business_id = userByBusiness.business_id
                localStorage.setItem('user', JSON.stringify(userDataNow))

                Swal.fire({
                    title: "Bienvenido " + userSelect.name,
                    icon: "success",
                    draggable: true
                });
                setShowWaiters(false)
                localStorage.setItem('waiters', "false")
            } else {
                console.log("user not found")
                ErrorAlert("", userSelect.name + " " + "no tiene permitido facturar aqui.", "warning");
            }

        } catch (err) {
            console.log(err);
            ErrorAlert("", "Error de consulta de usuario", "error");
        }
    }



    return (
        <>



            <Modal
                show={showWaiters}
                size="xl"
                centered

            >
                <Modal.Body >

                    <div className='flex-container'>
                        <div className='mt-5 row justify-content-center align-items-center'>

                            <div className="col-4 col-xs-3 col-sm-3 col-md-3 col-lg-3 border-product" >
                                <div className="card border-0 shadow-sm rounded-sp m-2 ">
                                    <img src={logoWaiters} className="card-img-pre-acoount" alt="Product" loading='lazy' />
                                </div>

                                {parthners.length > 0 ? <>
                                    <div className="card-body d-flex justify-content-center alight-item-center p-1" >
                                        <ModalParthners parthners={parthners} logo={businessLogo} selectRestaurant={selectRestaurant} setResetData={setResetData} calculateInvoiceTotals={calculateInvoiceTotals} shoppingCart={shoppingCart} shoppingCartCombos={shoppingCartCombos} codeParthner={codeParthner} setShoppingCart={setShoppingCart} setShoppingCartCombos={setShoppingCartCombos} waiters={true}/>
                                    </div></> : <></>}

                            </div>
                        </div>

                        <div className="d-flex justify-content-center border-bottom-0">
                            <h1 className="modal-title fw-bold fs-5">
                                <i class="uil uil-user-circle"> Autenticador de cajero</i>

                            </h1>
                        </div>


                        <div className='d-flex justify-content-center align-itm-center'>
                            <div>
                                <label className="  text-center mt-3"><i class="uil uil-user-md"> Ingresa codigo de cajero</i></label>

                                <div className='d-flex justify-content-center align-itm-center'>
                                    <div className="form-floating mt-1">
                                        <input
                                            type={showPassword ? 'numeric' : 'password'}
                                            className="form-control rounded bg-light"
                                            id="code"
                                            name='pass'
                                            placeholder="Ingresa codigo"
                                            required
                                            spellcheck="false"
                                            onChange={(e) => setCodeUser(e.target.value)}
                                        />
                                        <label className='fw-bold' htmlFor="floatingPassword"><i className="uil uil-lock-alt"></i> Codigo de cajero</label>

                                    </div>
                                    <button className=" m-2 btn btn-success" type="submit" onClick={() => getUserByCode()}>
                                        <span className='rotated-icon'>
                                            <i class="uil uil-signout"></i>
                                        </span> Ingresar </button></div>

                                <div className="form-check text-start my-3">
                                    <input className="form-check-input" type="checkbox"
                                        value="remember-me" id="flexCheckDefault"
                                        onChange={toogglePasword}
                                    />
                                    <label className="form-check-label" for="flexCheckDefault">
                                        Mostrar Contraseña
                                    </label>
                                </div>


                            </div>
                        </div>

                        <small className='text-body-tertiary text-center mt-3'>
                            <i className="uil uil-info-circle me-1"></i>
                            Por favor, ingresa con tu codigo de cajero
                        </small>


                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalWaiters