//API 
import { EvertechFetchAPI } from "../api/Evertech";
import { BanescoFetchAPI } from "../api/Banesco";

// We create a function to make a sale with Evertech
const evertechSale = async (data) => {
    try {
        // We make the sale
        const res = await EvertechFetchAPI.makeSale(data);

        // We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            // If the response exists, we check the approval code 
            if (res.data.approval_code !== "00") {
                console.log("Approval code error");
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                return res;
            }
        }

        // We return the response
        return res;
    } catch (error) {
        let parsedError;
        console.log("Error caught in evertechSale:", error);

        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }

        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
};

//We create a function to make a cash sale with evertech
const evertechCashSale = async (data) => {
    try {
        console.log("Data en cash", data);
        // We make the sale
        const res = await EvertechFetchAPI.makeCashSale(data);

        // We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            // If the response exists, we check the approval code 
            if (res.data.approval_code !== "00") {
                console.log("Approval code error");
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                return res;
            }
        }

        // We return the response
        return res;
    } catch (error) {
        let parsedError;
        console.log("Error caught in evertechSale:", error);

        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }

        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
}

//We create a function to make a ebt sale with evertech
const evertechEBTSale = async (data) => {
    {
        try {
            console.log("Data en ebt", data);
            // We make the sale
            const res = await EvertechFetchAPI.makeEBTOperation(data);

            // We check the response of the server in base of the response of the pin pad
            if (res && res.data !== undefined) {
                // If the response exists, we check the approval code 
                if (res.data.approval_code !== "00") {
                    console.log("Approval code error");
                    return ({
                        status: 400,
                        data: res.data
                    });
                } else {
                    return res;
                }
            }

            // We return the response
            return res;
        } catch (error) {
            let parsedError;
            console.log("Error caught in evertechSale:", error);

            // If the error is already an object, no need to parse
            if (typeof error === 'object' && error !== null) {
                parsedError = error;
            } else {
                // We try to convert the error into an object
                try {
                    parsedError = JSON.parse(error);
                } catch (e) {
                    // If parsing fails, return a generic error object
                    parsedError = { status: 500, message: error };
                }
            }

            // Throw the formatted error object
            if (parsedError.status && parsedError.data) {
                return parsedError;
            } else if (error.response) {
                throw {
                    status: 400,
                    data: error.response.data
                };
            } else {
                throw error;
            }
        }
    }
}


//We create a function to make a sale with banesco 
const banescoSale = async (data) => {
    try {
        // We make the sale
        const res = await BanescoFetchAPI.makeSale(data);

        console.log("Response from banesco", res);

        return res;
    } catch (error) {
        console.log("Error caught in evertechSale:", error);
        return null
    }
}

// Function to process payments
export const ProcessPayment = async (props) => {

    // Set parameters from props
    if (props.integration.name === "Evertech") {
        //variables
        let iDefaultHostPort, DefaultHostIP, sDefaultTID, iscashback, force_duplicate, special_account, manual_entry_indicator, payment_host, basereducedtax, statetax, reducedtax, citytax;

        props.params.forEach((param) => {
            if (param.name === "iDefaultHostport") iDefaultHostPort = param.value;
            if (param.name === "DefaultHostIP") DefaultHostIP = param.value;
            if (param.name === "sDefaultTID") sDefaultTID = param.value;
            if (param.name === "iscashback") iscashback = param.value;
            if (param.name === "force_duplicate") force_duplicate = param.value;
            if (param.name === "payment_host") payment_host = param.value;
            if (param.name === "manual_entry_indicator") manual_entry_indicator = param.value;
        });
        //We initialize the variables of taxes
        props.taxes.forEach((tax) => {
            if (tax.tax === "state_tax") {
                if (statetax > 0) {
                    statetax += Math.round(tax.total * 100) || 0;
                } else {
                    statetax = Math.round(tax.total * 100) || 0;
                }
            }
            if (tax.tax === "reduced_tax") {
                if (reducedtax > 0) {
                    reducedtax += Math.round(tax.total * 100) || 0;
                } else {
                    reducedtax = Math.round(tax.total * 100) || 0;
                }
                //basereducedtax = Math.round((props.subtotal - tax.total) * 100) || 0
                basereducedtax = Math.round((props.basereducedtax) * 100) || 0
            };
            if (tax.tax === "city_tax") {
                if (citytax > 0) {
                    citytax += Math.round(tax.total * 100) || 0;
                } else {
                    citytax = Math.round(tax.total * 100) || 0;
                }
            }
        });

        special_account = {
            "Visa": "VC",
            "Mastercard": "MC",
            "Amex": "AX",
            "Cash": "IC",
            "EBT": "EB",
            "EBT Cash": "EB",
            "ATH Movil": "AM",
            "EBT Unica": "UN",
            "EBT Cash Unica": "UN",
            "EBT Cash Advance": "EB",
        }[props.payment] || "NONE";

        let data = {
            sECRStationID: props.sECRStationID,
            sCashierID: props.sCashierID,
            sDefaultHostIP: DefaultHostIP,
            iDefaultHostPort: iDefaultHostPort,
            sDefaultTID: sDefaultTID,
            sRefNumber: props.sRefNumber,
            tips: Math.round(props.tips * 100),
            iscashback: iscashback,
            cashback: iscashback === "yes" ? Math.round(props.cashback * 100) : 0,
            total: Math.round((props.total) * 100),
            basereducedtax: basereducedtax,
            statetax: statetax,
            reducedtax: reducedtax,
            citytax: citytax,
            manual_entry_indicator: manual_entry_indicator,
            force_duplicate: force_duplicate,
            receipt_output: "both",
            special_account: special_account,
            payment_host: payment_host
        };

        //We check if the payment is ebt to add the EBTTransType
        if (props.payment === "EBT" || props.payment === "EBT Unica" || props.payment === "EBT Cash Advance") {
            //We check if the payment is ebt cash advance
            if (props.payment === "EBT Cash Advance") {
                data.EBTTransType = "CASHWITHDRAWAL"
                data.sTransType = "EBT"
            } else if (props.payment === "EBT Unica") {
                data.EBTTransType = "CASHPURCHASE"
                data.sTransType = "EBT"
            }
            else {
                data.EBTTransType = "FOODPURCHASE"
                data.sTransType = "EBT"
            }

            //We check if the paymet is ebt 
            if (props.payment === "EBT" || props.payment === "EBT Cash Advance") {
                //We delete the taxes 
                delete data.statetax;
                delete data.reducedtax;
                delete data.citytax;
                delete data.basereducedtax;
            }
        }

        //We check if the payment is cash to add the EBTTransType cash
        if (props.payment === "EBT Cash" || props.payment === "EBT Cash Unica") {
            if (props.payment === "EBT Cash Unica") {
                data.EBTTransType = "CASHBACK"
                data.sTransType = "EBT"
            } else {
                data.EBTTransType = "CASHPURCHASE"
                data.sTransType = "EBT"
            }
        }

        const logonres = await EvertechFetchAPI.makeLogon({
            sECRStationID: props.sECRStationID,
            sCashierID: props.sCashierID,
            sDefaultHostIP: DefaultHostIP,
            iDefaultHostPort: iDefaultHostPort,
            sDefaultTID: sDefaultTID,
            sRefNumber: props.sRefNumber
        });
        //We check the response of the logon request
        if (logonres.data && logonres.data.approval_code === "ZY" && logonres.data.response_message === "Connection Error - LOGON") {
            console.log("si entre porq logon fallo")
            return logonres
        } else {
            //We check if the payment is cash
            if (props.payment !== "Cash" && props.payment !== "EBT" && props.payment !== "EBT Cash" && props.payment !== "EBT Unica" && props.payment !== "EBT Cash Unica" && props.payment !== "EBT Cash Advance") {
                return evertechSale(data)
                    .then(async (res) => {
                        const logoff = await EvertechFetchAPI.makeLogoff({
                            sECRStationID: props.sECRStationID,
                            sCashierID: props.sCashierID,
                            sDefaultHostIP: DefaultHostIP,
                            iDefaultHostPort: iDefaultHostPort,
                            sDefaultTID: sDefaultTID
                        });
                        console.log(logoff);
                        // We return the response
                        return res;
                    })
                    .catch(async (error) => {
                        console.log("Error in catch", error);

                        const logoff = await EvertechFetchAPI.makeLogoff({
                            sECRStationID: props.sECRStationID,
                            sCashierID: props.sCashierID,
                            sDefaultHostIP: DefaultHostIP,
                            iDefaultHostPort: iDefaultHostPort,
                            sDefaultTID: sDefaultTID
                        });
                        console.log(logoff);


                        let parsedError;
                        // If the error is already an object, no need to parse
                        if (typeof error === 'object' && error !== null) {
                            console.log("Error is object", error);
                            parsedError = error;
                        } else {
                            console.log("Error is not object", error);

                            try {
                                parsedError = JSON.parse(error);
                            } catch (e) {
                                parsedError = { status: 500, data: error.message };
                            }
                        }
                        console.log("Parsed error1111", parsedError);

                        // Throw the formatted error object
                        if (parsedError.status && parsedError.data) {
                            console.log("Parsed error", parsedError);
                            return parsedError;
                        } else if (error.response) {
                            console.log("Error response", error.response);
                            return {
                                status: 400,
                                data: error.response.data
                            };
                        } else {
                            console.log("Error", error);
                            return error;
                        }
                    });
            } else if (props.payment === "EBT" || props.payment === "EBT Cash" || props.payment === "EBT Unica" || props.payment === "EBT Cash Unica" || props.payment === "EBT Cash Advance") {
                return evertechEBTSale(data)
                    .then(async (res) => {
                        const logoff = await EvertechFetchAPI.makeLogoff({
                            sECRStationID: props.sECRStationID,
                            sCashierID: props.sCashierID,
                            sDefaultHostIP: DefaultHostIP,
                            iDefaultHostPort: iDefaultHostPort,
                            sDefaultTID: sDefaultTID
                        });
                        console.log(logoff);
                        // We return the response
                        return res;
                    })
                    .catch(async (error) => {
                        console.log("Error in catch", error);

                        const logoff = await EvertechFetchAPI.makeLogoff({
                            sECRStationID: props.sECRStationID,
                            sCashierID: props.sCashierID,
                            sDefaultHostIP: DefaultHostIP,
                            iDefaultHostPort: iDefaultHostPort,
                            sDefaultTID: sDefaultTID
                        });
                        console.log(logoff);
                    });
            } else {
                return evertechCashSale(data)
                    .then(async (res) => {
                        const logoff = await EvertechFetchAPI.makeLogoff({
                            sECRStationID: props.sECRStationID,
                            sCashierID: props.sCashierID,
                            sDefaultHostIP: DefaultHostIP,
                            iDefaultHostPort: iDefaultHostPort,
                            sDefaultTID: sDefaultTID
                        });
                        console.log(logoff);
                        // We return the response
                        return res;
                    })
                    .catch(async (error) => {
                        console.log("Error in catch", error);

                        const logoff = await EvertechFetchAPI.makeLogoff({
                            sECRStationID: props.sECRStationID,
                            sCashierID: props.sCashierID,
                            sDefaultHostIP: DefaultHostIP,
                            iDefaultHostPort: iDefaultHostPort,
                            sDefaultTID: sDefaultTID
                        });
                        console.log(logoff);


                        let parsedError;
                        // If the error is already an object, no need to parse
                        if (typeof error === 'object' && error !== null) {
                            console.log("Error is object", error);
                            parsedError = error;
                        } else {
                            console.log("Error is not object", error);

                            try {
                                parsedError = JSON.parse(error);
                            } catch (e) {
                                parsedError = { status: 500, data: error.message };
                            }
                        }
                        console.log("Parsed error1111", parsedError);

                        // Throw the formatted error object
                        if (parsedError.status && parsedError.data) {
                            console.log("Parsed error", parsedError);
                            return parsedError;
                        } else if (error.response) {
                            console.log("Error response", error.response);
                            return {
                                status: 400,
                                data: error.response.data
                            };
                        } else {
                            console.log("Error", error);
                            return error;
                        }
                    });
            }
        }

    }

    // Set parameterf from props 
    if(props.integration.name === "Banesco"){
        let port, quotespay, pointspay, ip;

        //We get the parameters 
        props.params.forEach((param) => {
            if (param.name === "port") port = param.value;
            if (param.name === "quotespay") quotespay = param.value;
            if (param.name === "pointspay") pointspay = param.value;
            if (param.name === "IP") ip = param.value;
        });

        //We create the data object 
        let data = {
            "port": port,
            "NumberOfQuotas": quotespay === "yes" ? props.numberQuotes : 0,
            "points": pointspay === "yes" ? true : false,
            "ip": ip,
            "amount": props.total, 
            "taxAmount": props.tax,
            "tipAmount": props.tips,
            "timeout": 45000
        };

        //We make the sale
        return banescoSale(data)
            .then(async (res) => {
                //We return the response
                return res;
            })
            .catch(async (error) => {
                console.log("Error in catch", error);

                let parsedError;
                // If the error is already an object, no need to parse
                if (typeof error === 'object' && error !== null) {
                    console.log("Error is object", error);
                    parsedError = error;
                } else {
                    console.log("Error is not object", error);

                    try {
                        parsedError = JSON.parse(error);
                    } catch (e) {
                        parsedError = { status: 500, data: error.message };
                    }
                }
                console.log("Parsed error1111", parsedError);

                // Throw the formatted error object
                if (parsedError.status && parsedError.data) {
                    console.log("Parsed error", parsedError);
                    return parsedError;
                } else if (error.response) {
                    console.log("Error response", error.response);
                    return {
                        status: 400,
                        data: error.response.data
                    };
                } else {
                    console.log("Error", error);
                    return error;
                }
            });
    }
};