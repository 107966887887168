import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'

import { Form } from 'react-bootstrap';


function ModalJustification({ setJustification, deletePreOrder, order }) {

    const [showModalJustification, setShowModalJustification] = useState(false);


    return (
        <>
            <button className='btn btn-danger bg-danger-subtle text-danger border-0 btn-sm btn-block m-1'
                data-order={order.code}
                onClick={async (e) => setShowModalJustification(true)}>
                <i className="uil uil-trash-alt"> Eliminar</i>
            </button>

            <Modal
                show={showModalJustification}
                size="xl"
                centered
                onHide={() => setShowModalJustification(false)}
            >
                <Modal.Body>
                    <div className="d-flex justify-content-center border-bottom-0">
                        <h1 className="modal-title fw-bold fs-5">
                        <i class="uil uil-file-question-alt"></i>
                            ¿Por qué se elimina la precuenta?
                        </h1>
                        {/* <button type="button" className="btn-close rounded"
                     onClick={() => (setShow(false), setProductChecked([]))}></button> */}
                    </div>

                    <div className=''>
                        <Form>
                            <Form.Group className="m-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Justifique:</Form.Label>
                                <Form.Control as="textarea" rows={1} onChange={(e) => (setJustification(e.target.value))} />
                            </Form.Group>
                        </Form>
                    </div>

                    <div className='d-flex justify-content-between'>
                        <button className='btn bg-secondary-subtle text-secondary  border-0 fw-bold '
                            onClick={() => (setJustification(""), setShowModalJustification(false))}> Cancelar </button>
                        <button className='btn btn-danger bg-danger-subtle text-danger border-0 btn-sm btn-block m-1'
                            data-order={order.code}
                            onClick={async (e) => (deletePreOrder(order), setJustification(""), setShowModalJustification(false))}>
                            <i className="uil uil-trash-alt"> Eliminar</i>
                        </button>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalJustification