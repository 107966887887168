import axios from 'axios';

//We set the base url of the server 
const baseURL = process.env.REACT_APP_C_SERVER_URL


export class BanescoFetchAPI {

    //Function to make a sale with Banesco
    static async makeSale(data) {
             data = JSON.stringify(data);
                const res = await axios.post(`${baseURL}/kinpos/dclsale`, data);
                return res 
     /*    return {
            data: {
                "TxnRspCode": "00",
                "TxnAuthNum": "126491",
                "TxnDate": "20250206",
                "TxnTime": "151034",
                "TxnTID": "00091613",
                "TxnMerName": "5808872669     ",
                "TxnMaskPAN": "520057******0378",
                "TxnInvoice": "001429",
                "TxnIssName": "MASTERCARD           ",
                "TxnRRN": "503755067645",
                "TxnSTAN": "003328",
                "TxnHolderName": "\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000",
                "EMVData": {
                    "AID": "A0000000041010",
                    "TVR": "0000008001",
                    "TC": "4803EB4DD6A8A64C"
                }
            }
        } */
    }

    //Function to make a settle with banesco
    static async makeSettle(data) {
        data = JSON.stringify(data);
        const res = await axios.post(`${baseURL}/kinpos/dclsettlement`, data);
        return res
    }

    //Function to make a refund with banesco
    static async makeRefund(data) {
        console.log("lo que enviamos es: ", data)
                data = JSON.stringify(data);
               const res = await axios.post(`${baseURL}/kinpos/dclrefund`, data);
               return res 
       /*  return {
            data: {
                "TxnRspCode": "00",
                "TxnAuthNum": "\u0000\u0000\u0000\u0000\u0000\u0000",
                "TxnDate": "20250206",
                "TxnTime": "154752",
                "TxnTID": "00091613",
                "TxnMerName": "5808872669     ",
                "TxnMaskPAN": "552162******4544",
                "TxnInvoice": "001433",
                "TxnIssName": "MASTERCARD           ",
                "TxnRRN": "503740069323",
                "TxnSTAN": "003335",
                "TxnHolderName": "RODRIGUEZ/MOISES          \u0000\u0000\u0000\u0000\u0000\u0000",
                "TxnRspText": "\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000"
            }
        } */
    }

    //Function to make a void with banesco
    static async makeVoid(data) {
         data = JSON.stringify(data);
        const res = await axios.post(`${baseURL}/kinpos/dclvoid`, data);
        return res 
 /*        return {
            data: {
                "TxnRspCode": "00",
                "TxnAuthNum": "489803",
                "TxnRRN": "503730068699",
                "TxnInvoice": "001431",
                "TxnSTAN": "003333",
                "TxnRspText": "\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000",
                "TxnTID": "00091613",
                "TxnIssName": "VISA           ",
                "TxnMerName": "5808872669     ",
                "TxnBaseAmt": "000000000099",
                "TxnMaskPAN": "451393******8407"
              }
        } */
    }
}