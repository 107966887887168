import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

//Context
import UserContext from "../../context/UserContext";

//Api 
import { AuthFetchAPI } from '../../api/Auth';
import { UsersFetchAPI } from '../../api/Users';
import { BusinessFetchAPI } from '../../api/Business';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { MachinesFetchAPI } from '../../api/Machines';
import { TablesFetchAPI } from '../../api/Tables';
//Components 
import Swal from "sweetalert2";
//Img
import logo_sp from '../img/logos/icon-black.png';

//Utils 
import { RenderIf } from '../utils/RenderIf'
const Login = () => {
    //States 
    const [businessData, setBusinessData] = useState({
        name: '',
        id: 0
    });
    const [eventListener, setEventListener] = useState(0)
    const [branchoffices, setBranchoffices] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState();
    const [users, setUsers] = useState([]);
    const [branchofficeTables, setBranchofficeTables] = useState([]);
    const [statusAuth, setStatusAuth] = useState(false);
    const [selectedBranchTable, setBranchTable] = useState();

    const [branchofficeMachines, setBranchofficeMachines] = useState([]);

    //Context 
    const { setUserData } = useContext(UserContext);
    //Params
    const { business_code, branchoffice_code } = useParams();
    //Navigation
    const navigate = useNavigate();
    //Location page 
    const url_path = useLocation();

    //Function to get the initial data 
    const getInitialData = async (business_code) => {
        //Variables
        let response_business = '';
        let response_branchoffices = ''
        try {
            //We get the business
            response_business = await BusinessFetchAPI.getBusinessByCode(business_code)
            //We set the business data 
            setBusinessData(response_business.data.business)
        } catch (err) {
            //If we get some error we redirect to a no mactch page 
            navigate('/nomatch')
        }
        try {
            //We get the branchoffices of the business 
            response_branchoffices = await BranchOfficeFetchAPI.getBranchOfficesByBusiness(response_business.data.business.id)
            //We set the branchoffices data 
            setBranchoffices(response_branchoffices.data.data)
        } catch (err) {
            //If we dont get any branch office we set an empty array 
            setBranchoffices([])
        }
        if (url_path.pathname.includes('comanda')) {

            try {

                const branch = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code)
                console.log(branch)

                //We get the machines by branchoffice and by type (6 in this case is for only comandas)
                let response = await MachinesFetchAPI.getMachinesByBranchOfficeAndType(branch.data.branchoffice.id, 6);
                console.log(response)

                setBranchofficeMachines(response.data.data);
                setSelectedBranch(branchoffice_code);
            } catch (err) {
                setBranchofficeMachines([]);
                setSelectedBranch(null);
                console.log(err)
            }
        }


        if (url_path.pathname.includes('tables') && url_path.pathname.includes('machines')) {
            try {
                const branch = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code)
                let token = localStorage.getItem('x-access-machine-token')
                //We get the branchoffice tables 
                getBranchofficeTables(branch.data.branchoffice.id, token)
            } catch (err) {
                console.log(err)
            }
        }
        //We set the handler to force to charge the state of the business and branchoffice 
        setEventListener(1)
    }

    const signout = async () => {
        let machine_token = localStorage.getItem('x-access-machine-token');
        let machine = JSON.parse(localStorage.getItem('machine_original'));

        //We logout the machine 
        try {
            const response = await MachinesFetchAPI.updateMachineStatus(machine.code, false, machine_token)
            console.log(response.data.success);

            if (response.data.success == true) {
                //We delete the tokens 
                localStorage.setItem('x-access-token', "");
                localStorage.setItem('x-access-machine-token', "");

                //We delete the machine and user info 
                localStorage.setItem('user', "");
                localStorage.setItem('machine', "");
                localStorage.setItem('branchoffice', "");
                localStorage.setItem('business', "");
                localStorage.setItem('table', "");
                localStorage.removeItem("generalData");
                localStorage.removeItem("codeParthner");
                localStorage.removeItem("external_menu_manual");
                localStorage.removeItem("table");
                localStorage.removeItem("waiters");
                navigate(`/machines/login/${business_code}`);
                //We redirect to the login of the users on the payment post 
            }
        } catch (err) {
            console.log(err)
        }
    }

    //Function to get the users of a branchoffice or a business depending the role 
    const getUsers = async (branchoffice_id, branch_code) => {
        try {
            //Variables 
            let user_role_id;
            let response;
            //We check what type of login
            if (url_path.pathname.includes('cashier')) {
                user_role_id = 4 //Cashier role 
            } else if (url_path.pathname.includes('dispatcher')) {
                user_role_id = 5 //Dispatcher role
            } else {
                user_role_id = 3; //Admin role 
            }

            //We check if the role is tha admin role to make the consult 
            if (user_role_id === 3) {
                //If the role is admin we just get the admin users by business 
                response = await UsersFetchAPI.getUsesByBusinessAndRole(businessData.id, [3, 6]);
            } else {
                //We get the users by branch and role 
                response = await UsersFetchAPI.getUsersByRoleAndBranchoffice(branchoffice_id, user_role_id);
                setSelectedBranch(branch_code);
            }
            setUsers(response.data.users);

        } catch (err) {
            //If we get some error we set the users data empty 
            setUsers([])
            setSelectedBranch(null)
        }
    }

    //Event to login
    const submit = async (e) => {
        e.preventDefault();
        //We check if the user check one branch office 
        if ((url_path.pathname.includes('cashier') && selectedBranch !== null) || (url_path.pathname.includes('dispatcher') && selectedBranch !== null) || (!url_path.pathname.includes('dispatcher') || !url_path.pathname.includes('cashier') && selectedBranch === undefined || selectedBranch === null)) {
            const form = new FormData(e.target);
            let user_logged = Object.fromEntries(form.entries());
            user_logged.business_id = businessData.id;
            try {
                const response = await AuthFetchAPI.login(user_logged);
                //We get the token and user data 
                let token = response.data.token;
                let user = response.data.user;
                //We set the user info and the token access 
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('user_original', JSON.stringify(user));
                localStorage.setItem('x-access-token', token);
                //We set the context of the user 
                setUserData({ token, user: user });

                //We check the role of the user and depending of it we redirect 
                if (user.role === 4) {
                    navigate(`/cashier/${business_code}/${selectedBranch}`)
                } else if (user.role === 5) {
                    navigate(`/orders_customers/${business_code}/${selectedBranch}`)
                } else if (user.role === 3 || user.role === 6) {
                    navigate(`/transactions/${business_code}`)
                }

            } catch (err) {
                Swal.fire({
                    icon: "error",
                    title: err.response.data.error,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } else {
            //If the user dont select any branch we throw an error
            Swal.fire({
                icon: "error",
                title: 'Seleccione una sucursal',
                showConfirmButton: false,
                timer: 1500,
            });
        }

    }

    //Function to get the machines of a branch office 
    const getMachinesBranchoffice = async (branchoffice_id, branch_code) => {
        try {
            let response = []
            if (url_path.pathname.includes('machines') && !url_path.pathname.includes('tables')) {
                //We get the machines by branchoffice and by type (3 in this case is for only pos machines)
                response = await MachinesFetchAPI.getMachinesByBranchOfficeAndType(branchoffice_id, 3);
            }

            if (url_path.pathname.includes('machines') && url_path.pathname.includes('tables')) {
                //We get the machines by branchoffice and by type (8 in this case is for only smartpay table)
                response = await MachinesFetchAPI.getMachinesByBranchOfficeAndType(branchoffice_id, 8);
            }

            if (url_path.pathname.includes('comanda')) {
                //We get the machines by branchoffice and by type (6 in this case is for only comanda)
                response = await MachinesFetchAPI.getMachinesByBranchOfficeAndType(branchoffice_id, 6);

            }
            setBranchofficeMachines(response.data.data);
            setSelectedBranch(branch_code);

        } catch (err) {
            //If we get some error we set the machines data empty 
            setBranchofficeMachines([])
            //We check the page
            if (url_path.pathname.includes('machines ')) {
                setSelectedBranch(null)
            }
        }
    }

    //Event valid sesion of the machine
    /* const authValidSesion = () => {
         if (localStorage.getItem("x-access-machine-token") != "") {
             let business = JSON.parse(localStorage.getItem("business"))
             let branchoffice_info = JSON.parse(localStorage.getItem("branchoffice"))
             navigate(`/payments/${business.code}/${branchoffice_info.code}`)
         }
     }*/

    //Event to login a machine 
    const loginMachine = async (e) => {

        e.preventDefault();
        if (statusAuth == false) {
            setStatusAuth(true)

            if (selectedBranch !== null) {
                const form = new FormData(e.target);
                form.append('branchoffice_code', selectedBranch);
                form.append('business_code', business_code);
                let machine = Object.fromEntries(form.entries());
                try {
                    const response = await AuthFetchAPI.loginSmartPay(machine);
                    setStatusAuth(false)
                    //We get the token and user data 
                    let token = response.data.token;
                    let business = response.data.business;
                    let branchoffice = response.data.branchoffice;
                    let machine_info = response.data.machine
                    //We set the token info and the token access 
                    localStorage.setItem('x-access-machine-token', token);
                    localStorage.setItem('business', JSON.stringify(business));
                    localStorage.setItem('branchoffice', JSON.stringify(branchoffice));
                    localStorage.setItem('branchoffice_original', JSON.stringify(branchoffice));
                    localStorage.setItem('machine', JSON.stringify(machine_info));
                    localStorage.setItem('machine_original', JSON.stringify(machine_info));

                    if (url_path.pathname.includes('customer')) {
                        navigate(`/orders/business/${business_code}/branchoffice/${selectedBranch}/list`)
                    } else if (url_path.pathname.includes('comanda')) {
                        localStorage.setItem('x-access-token', token);
                        //We get the machine categories available 
                        try {
                            const response = await MachinesFetchAPI.getMachineCategories(machine_info.id, token)
                            localStorage.setItem('machine', JSON.stringify({ categories_available: response.data.categories, ...machine_info }))
                        } catch (err) {
                            localStorage.setItem('machine', JSON.stringify({ categories_available: [], ...machine_info }))
                        }

                        navigate(`/kds/${business_code}/${selectedBranch}`)
                    } else if (url_path.pathname.includes('tables') && url_path.pathname.includes('machines')) {
                        navigate(`/tables/login/${business_code}/${selectedBranch}`)
                        //We get the branchoffice tables 
                        getBranchofficeTables(branchoffice.id, token)
                    } else {
                        //We get the branchoffice data 
                        getUsers(branchoffice.id, selectedBranch);
                        setEventListener(1);
                        //We redirect 
                        navigate(`/machine/cashier/login/${business_code}/${selectedBranch}`)
                    }

                } catch (err) {
                    setStatusAuth(false)
                    Swal.fire({
                        icon: "error",
                        title: err.response.data.error,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }

            } else {
                //If the user dont select any branch we throw an error
                Swal.fire({
                    icon: "error",
                    title: 'Seleccione una sucursal',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        }

    }




    //Hook to get data when the page is loaded
    useEffect(() => {
        //We get the business by code 
        getInitialData(business_code);
        if (branchoffice_code === undefined) {
            getUsers(0, 0);
        }

    }, [eventListener]);

    //Function to gen the list of the branchoffices 
    const branchofficesList = () => {
        const branchoffices_list = branchoffices.map((data) => (
            <>
                <option data-code={data.branchoffice_code} value={data.branchofficeId}>{data.branchofficeName}</option>
            </>
        ))

        //We check the branchoffices 
        if (branchoffices.length === 0) {
            return (
                <>
                    <option value={null}>No hay sucursales registradas</option>
                </>
            )
        } else {
            return (
                <>
                    <option value={null}>Seleccione...</option>
                    {branchoffices_list}
                </>
            )
        }
    }

    //Function to list the users of branchoffice
    const listUsers = () => {
        let users_list;
        if (users !== undefined) {
            users_list = users.map((data) => (
                <>
                    <input type="radio" className="btn-check" name="email" id={data.user_id} value={data.email} />
                    <label className="btn btn-outline-primary rounded-pill border" for={data.user_id}>{data.user_name}</label>
                </>
            ))
        } else {
            users_list = ''
        }

        //We check the users list
        return (<>{users_list} </>)
    }

    //Function to list the machines of a branchoffice 
    const listMachines = () => {
        const machines_list = branchofficeMachines.map((data) => (
            <>
                <input type="radio" className="btn-check" name="machine_code" id={data.machine_per_branch_id} value={data.code} />
                <label className="btn btn-outline-primary rounded-pill border" for={data.machine_per_branch_id} > {data.machine_name} </label>
            </>
        ))
        //We check the users list
        return (<>{machines_list} </>)
    }

    //Function to get branch tables
    const listBranchTables = () => {
        const tables_list = branchofficeTables.map((data) => (
            <>
                <input type="radio" className="btn-check" name="table_id" id={data.id} value={data.id} />
                <label className="btn btn-outline-primary rounded-pill" for={data.id}  > {data.name} </label>
            </>
        ))
        //We check the users list
        return (<>{tables_list} </>)
    }

    //Function to list the tables of a branchoffice 
    const getBranchofficeTables = async (branchoffice_id, token) => {
        try {
            //Variables 
            let response = await TablesFetchAPI.getBranchofficeTables(branchoffice_id, token)
            setBranchofficeTables(response.data.tables)
        } catch (err) {
            //If we get some error we set the users data empty 
            setBranchofficeTables([])
            setSelectedBranch(null)
        }
    }

    //Function to login the table 
    const loginTable = async (e) => {
        e.preventDefault();
        if (selectedBranch !== null) {
            const form = new FormData(e.target);
            let table = Object.fromEntries(form.entries());
            try {
                const response = await AuthFetchAPI.loginBranchTable(table);
                //We get the token and user data 
                localStorage.setItem('table', JSON.stringify(response.data.table));
                navigate(`/preaccount/${business_code}/${branchoffice_code}`)
            } catch (err) {
                Swal.fire({
                    icon: "error",
                    title: err.response.data.error,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        } else {
            //If the user dont select any branch we throw an error
            Swal.fire({
                icon: "error",
                title: 'Seleccione una sucursal',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }

    const [showPassword, setShowPassword] = useState('')
    const toogglePasword = () => {

        setShowPassword(!showPassword);

    }


    useEffect(() => {

        let urlNow = window.location.href + ""
        let arrayParams = urlNow.split('/');
        console.log(arrayParams[3] + " " + arrayParams[4]);

        if (arrayParams[3] == "machines" && arrayParams[4] == "login") {
            console.log("entre");

            if (localStorage.getItem("x-access-token") == "" || localStorage.getItem("x-access-token") == undefined || localStorage.getItem("x-access-machine-token") == "" || localStorage.getItem("x-access-machine-token") == undefined || localStorage.getItem("branchoffice") == "" || localStorage.getItem("branchoffice") == undefined || localStorage.getItem("machine") == "" || localStorage.getItem("machine") == undefined || localStorage.getItem("user") == "" || localStorage.getItem("user") == undefined) {

            } else {

                let branchoffice = JSON.parse(localStorage.getItem('branchoffice'));
                let machine = JSON.parse(localStorage.getItem('machine'));
                let business = JSON.parse(localStorage.getItem("business"))

                //console.log(`/kiosk/${business.code}/${branchoffice.code}`);
                navigate(`/payments/${business.code}/${branchoffice.code}`)
            }
        }

    }, []);

    return (
        <>

            <main className='main-login-sp'>
                <div className="top-container-sp">
                    <div className='container  d-flex justify-content-start'>
                        <div className='scale-up-top-left-sp'>
                            <span className='text-dark'>¡Bienvenido a </span>
                            <span className='text-color-sp'>Smart Pay </span> 😄!
                        </div>
                        <div>
                            {/* <i className="uil uil-info-circle"></i> */}
                        </div>

                    </div>
                </div>
                <section className="form-signin-sp bg-white shadow-sm scale-up-top-left-sp">
                    <div className="d-flex justify-content-center">
                        {/* <Link to="/"> */}
                        <img
                            className="mb-2"
                            src={logo_sp}
                            alt=""
                            width="100"
                        />
                        {/* </Link> */}
                    </div>

                    <div className="">
                        <div>

                            <h1 className="title-main-login  text-center">Iniciar Sesión</h1>
                            <h5 className="text-muted text-center">
                                <i className="uil uil-label-alt"></i>
                                {businessData.name}
                            </h5>
                            {/**Branchoffice select */}
                            <RenderIf isTrue={(url_path.pathname.includes('cashier') && branchoffice_code === undefined) || url_path.pathname.includes('dispatcher') || url_path.pathname.includes('machines') || url_path.pathname.includes('comanda')} >
                                <label className="form-label d-flex justify-content-start fw-bold">
                                    <i className="uil uil-store-alt"></i> Sucursal de la empresa</label>
                                <select className="form-select" onChange={e => {
                                    //We get the code selected on the select 
                                    let index = e.target.selectedIndex;
                                    let optionElement = e.target.childNodes[index]
                                    let code = optionElement.getAttribute('data-code');
                                    //We get the users of the branch
                                    getUsers(e.target.value, code);
                                    getMachinesBranchoffice(e.target.value, code);
                                }}
                                    name="branchoffice" id="branchoffice">
                                    {branchofficesList()}
                                </select>
                            </RenderIf>

                            {/**List and form of users depending the branchoffice */}
                            <RenderIf isTrue={url_path.pathname.includes('cashier') || url_path.pathname.includes('dispatcher') || !url_path.pathname.includes('machines') && !url_path.pathname.includes('comanda') && !url_path.pathname.includes('tables')}>
                                {/*Form */}
                                <form onSubmit={submit} className="py-3" noValidate>
                                    <label className="form-label d-flex justify-content-start fw-bold">
                                        <i className="uil uil-users-alt"></i> Usuarios
                                    </label>
                                    <div className='login-wrapper bg-light '>
                                        <div className='d-flex gap-1 p-2 flex-column' >
                                            {listUsers()}
                                        </div>
                                    </div>
                                    {/* <div className='email mt-3'>
                                        <label className="form-label d-flex justify-content-start fw-bold">Contraseña</label>
                                        <input
                                            spellcheck="false"
                                            className="control rounded"
                                            id="password"
                                            type="password"
                                            name='pass'
                                            placeholder="Ingresar contraseña"
                                            required
                                        />

                                    </div> */}

                                    <div className="form-floating mt-3">
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className="form-control rounded bg-light"
                                            id="password"
                                            name='pass'
                                            placeholder="Ingresar contraseña"
                                            required
                                            spellcheck="false"
                                        />
                                        <label className='fw-bold' htmlFor="floatingPassword">{!showPassword ? <i className="uil uil-lock-alt"></i> : <i className="uil uil-lock-open-alt"></i>} Contraseña</label>
                                    </div>

                                    <div className="form-check text-start my-3">
                                        <input className="form-check-input" type="checkbox"
                                            value="remember-me" id="flexCheckDefault"
                                            onChange={toogglePasword}
                                        />
                                        <label className="form-check-label" for="flexCheckDefault">
                                            Mostrar Contraseña
                                        </label>
                                    </div>
                                    <div className='d-flex justify-content-between mt-3'>
                                        <button className=" m-2 btn btn-primary" type="button" onClick={() => signout()}>
                                            <span className='rotated-icon'>
                                                <i class="uil uil-sign-in-alt">Volver</i>
                                            </span>

                                        </button>
                                        <button className=" m-2 btn btn-success" type="submit"><span className='rotated-icon'>
                                            <i class="uil uil-signout"></i>
                                        </span> Ingresar </button>
                                    </div>


                                </form>
                            </RenderIf>


                            {/**List and form of machines depending the branchoffice */}
                            <RenderIf isTrue={url_path.pathname.includes('machines') || url_path.pathname.includes('comanda')}>
                                {/*Form */}

                                <form onSubmit={loginMachine} className="py-3" noValidate>
                                    <label className="form-label d-flex justify-content-start fw-bold">
                                        <i className="uil uil-monitor-heart-rate"></i> Máquinas

                                    </label>
                                    <div className='login-wrapper bg-light '>
                                        <div className='d-flex gap-1 p-2 flex-column'>
                                            {listMachines()}
                                        </div>
                                    </div>
                                    <button className="btn btn-success w-100 mt-3" type="submit">
                                        <span className='rotated-icon'><i className="uil uil-message "></i>
                                        </span>
                                        Ingresar</button>
                                </form>
                            </RenderIf>

                            {/**LIst and form of tables depending the branchoffice */}
                            <RenderIf isTrue={url_path.pathname.includes('tables') && !url_path.pathname.includes('machines')}>
                                {/*Form */}
                                <form onSubmit={loginTable} className="py-3" noValidate>
                                    <label className="form-label d-flex justify-content-start fw-bold">Mesas</label>
                                    <div className='login-wrapper  '>
                                        <div className='d-flex gap-1 p-2 flex-column'>
                                            {listBranchTables()}
                                        </div>
                                    </div>
                                    <button className="btn btn-success w-100 mt-3" type="submit">
                                        <span className='rotated-icon'>
                                            <i className="uil uil-message "></i>
                                        </span>
                                        Ingresar

                                    </button>
                                </form>
                            </RenderIf>


                            {/* 
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control bg-light w-100"
                                    name="email"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    required
                                    autoFocus
                                />
                                <label htmlFor="floatingInput">
                                    <i className="uil uil-envelope"></i> Correo Electrónico
                                </label>
                            </div>
                            <div className="form-floating mt-3">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    className="form-control rounded bg-light"
                                    name="password"
                                    id="floatingPassword"
                                    placeholder="Contraseña"
                                    required
                                />
                                <label htmlFor="floatingPassword">{!showPassword ? <i className="uil uil-lock-alt"></i> : <i className="uil uil-lock-open-alt"></i>} Contraseña</label>
                            </div>
                            <div className="form-check text-start my-3">
                                <input className="form-check-input" type="checkbox"
                                    value="remember-me" id="flexCheckDefault"
                                    onChange={toogglePasword}
                                />
                                <label className="form-check-label" for="flexCheckDefault">
                                    Mostrar Contraseña
                                </label>
                            </div>
                            <button className="control w-100 btn-lg" type="submit">
                                Iniciar sesión
                            </button> */}
                        </div>
                    </div>
                </section>
            </main>

            <div className="bottom-container-footer">
                <div className='d-flex align-items-center justify-content-between  p-lg-3 opacity-75'>

                    <span>
                        © 2023 Smart Pay
                    </span>

                    <span className='bg-white'>
                        Powered by Smart Retail Group
                    </span>

                </div>
            </div>

        </>
    )
}

export default Login