
//API 
import { EvertechFetchAPI } from "../api/Evertech";
import { BanescoFetchAPI } from "../api/Banesco";
// Funcion to settle with evertech
const evertechSettle = async (data) => {

    try {
        //We make the settle operation
        const res = await EvertechFetchAPI.makeSettle(data);
        //We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            //If the response exists, we check the approval code 
            if (res.data.approval_code !== "00") {
                console.log("Approval code error on settle");
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                return res;
            }
        }
        return res;
    } catch (error) {
        let parsedError;
        console.log("Error caught in evertechSale:", error);

        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }

        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
}

// Funcion to refund with evertech
const evertechRefund = async (data) => {
    try {
        //We make the refund operation
        const res = await EvertechFetchAPI.makeRefund(data);
        //We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            //If the response exists, we check the approval code 
            if (res.data.approval_code !== "00") {
                console.log("Approval code error on refund");
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                return res;
            }
        }
    } catch (error) {
        let parsedError;
        console.log("Error caught in evertechRefund:", error);

        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }
        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
}

// Funcion to void with evertech
const evertechVoid = async (data) => {
    try {
        //We make the void operation
        const res = await EvertechFetchAPI.makeVoid(data);
        //We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            //If the response exists, we check the approval code 
            if (res.data.approval_code !== "00") {
                console.log("Approval code error on void");
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                return res;
            }
        }
    } catch (error) {
        let parsedError;
        console.log("Error caught in evertechVoid:", error);

        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }
        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
}

//Function to make an adjust with evertech
const evertechAdjust = async (data) => {
    try {
        //We make the adjust operation
        const res = await EvertechFetchAPI.makeAdjust(data);
        //We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            //If the response exists, we check the approval code 
            if (res.data.approval_code !== "00") {
                console.log("Approval code error on adjust");
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                return res;
            }
        }
    } catch (error) {
        let parsedError;
        console.log("Error caught in evertechAdjust:", error);

        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }
        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
}

//Function to refund a sale with evertech cash sale 
const evertechCashRefund = async (data) => {
    try {
        //We make the refund operation
        const res = await EvertechFetchAPI.makeRefundCashSale(data);
        //We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            //If the response exists, we check the approval code 
            if (res.data.approval_code !== "00") {
                console.log("Approval code error on refund");
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                return res;
            }
        }
    } catch (error) {
        let parsedError;
        console.log("Error caught in evertechRefund:", error);

        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }
        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
}

//Function to refund a sale with evertech ebt sale 
const evertechEBTRefund = async (data) => {
    try {
        //We make the refund operation
        const res = await EvertechFetchAPI.makeEBTOperation(data);
        //We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            //If the response exists, we check the approval code 
            if (res.data.approval_code !== "00") {
                console.log("Approval code error on refund");
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                return res;
            }
        }
    } catch (error) {
        let parsedError;
        console.log("Error caught in evertechRefund:", error);

        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }
        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
}


//Function to refund a sale with banesco
const banescoRefund = async (data) => {
    try {
        //We make the refund operation
        const res = await BanescoFetchAPI.makeRefund(data);
        //We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            //If the response exists, we check the approval code 
            if (res.data.TxnRspCode !== "00") {
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                return res;
            }
        }
    } catch (error) {
        let parsedError;
        console.log("Error caught in evertechRefund:", error);

        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }
        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
}

//Function to void a sale with banesco
const banescoVoid = async (data) => {
    try {
        //We make the void operation
        const res = await BanescoFetchAPI.makeVoid(data);
        //We check the response of the server in base of the response of the pin pad
        if (res && res.data !== undefined) {
            //If the response exists, we check the approval code 
            if (res.data.TxnRspCode !== "00") {
                return ({
                    status: 400,
                    data: res.data
                });
            } else {
                return res;
            }
        }
    } catch (error) {
        let parsedError;
        console.log("Error caught in banescoVoid:", error);

        // If the error is already an object, no need to parse
        if (typeof error === 'object' && error !== null) {
            parsedError = error;
        } else {
            // We try to convert the error into an object
            try {
                parsedError = JSON.parse(error);
            } catch (e) {
                // If parsing fails, return a generic error object
                parsedError = { status: 500, message: error };
            }
        }
        // Throw the formatted error object
        if (parsedError.status && parsedError.data) {
            return parsedError;
        } else if (error.response) {
            throw {
                status: 400,
                data: error.response.data
            };
        } else {
            throw error;
        }
    }
}
// Function to handle payment operations
export const PaymentOperations = async (props) => {
    //We do the payment operation depending of the integration type
    switch (props.integrationType) {
        case "Evertech":
            let iDefaultHostPort, DefaultHostIP, sDefaultTID, iscashback, force_duplicate, special_account, manual_entry_indicator, payment_host, basereducedtax, statetax, reducedtax, citytax;

            // We get the values of the parameters
            props.params.forEach((param) => {
                if (param.name === "iDefaultHostport") iDefaultHostPort = param.value;
                if (param.name === "DefaultHostIP") DefaultHostIP = param.value;
                if (param.name === "sDefaultTID") sDefaultTID = param.value;
                if (param.name === "iscashback") iscashback = param.value;
                if (param.name === "force_duplicate") force_duplicate = param.value;
                if (param.name === "payment_host") payment_host = param.value;
                if (param.name === "manual_entry_indicator") manual_entry_indicator = param.value;
            });

            //If we get the taxes we map them to the variables
            if (props.taxes) {
                props.taxes.forEach((tax) => {
                    if (tax.tax === "state_tax") {
                        if (statetax > 0) {
                            statetax += Math.round(tax.total * 100) || 0;
                        } else {
                            statetax = Math.round(tax.total * 100) || 0;
                        }
                    }
                    if (tax.tax === "reduced_tax") {
                        if (reducedtax > 0) {
                            reducedtax += Math.round(tax.total * 100) || 0;
                        } else {
                            reducedtax = Math.round(tax.total * 100) || 0;
                        }
                        basereducedtax = Math.round((props.basereducedtax) * 100) || 0
                    };
                    if (tax.tax === "city_tax") {
                        if (citytax > 0) {
                            citytax += Math.round(tax.total * 100) || 0;
                        } else {
                            citytax = Math.round(tax.total * 100) || 0;
                        }
                    }
                });
            } else {
                //If we don't get the taxes we set them to 0
                basereducedtax = 0;
                statetax = 0;
                reducedtax = 0;
                citytax = 0;
            }
            //We set the special account depending of the payment type
            special_account = {
                "Visa": "VC",
                "Mastercard": "MC",
                "Amex": "AX",
                "Cash": "IC",
                "EBT": "EB",
                "EBT Cash": "EB",
                "ATH Movil": "AM",
                "EBT Unica": "UN",
                "EBT Cash Unique": "UN",
                "EBT Refund": "EB",
                "Cash Refund": "IC",
            }[props.payment] || "NONE";

            // We get the values data for the payment operation
            let data = {
                sECRStationID: props.sECRStationID,
                sCashierID: props.sCashierID,
                sDefaultHostIP: DefaultHostIP,
                iDefaultHostPort: iDefaultHostPort,
                sDefaultTID: sDefaultTID,
                sRefNumber: props.sRefNumber,
                tips: Math.round(props.tips * 100) || 0,
            };

            const logonres = await EvertechFetchAPI.makeLogon({
                sECRStationID: props.sECRStationID,
                sCashierID: props.sCashierID,
                sDefaultHostIP: DefaultHostIP,
                iDefaultHostPort: iDefaultHostPort,
                sDefaultTID: sDefaultTID,
                sRefNumber: props.sRefNumber
            });
            //We check the response of the logon request
            if (logonres.data && logonres.data.approval_code === "ZY" && logonres.data.response_message === "Connection Error - LOGON") {
                console.log("si entre porq logon fallo")
                return logonres
            } else {
                // We do the payment operation depending of the operation type
                switch (props.operationType) {
                    case "settle":
                        return evertechSettle(data)
                            .then(async (res) => {
                                const logoff = await EvertechFetchAPI.makeLogoff({
                                    sECRStationID: props.sECRStationID,
                                    sCashierID: props.sCashierID,
                                    sDefaultHostIP: DefaultHostIP,
                                    iDefaultHostPort: iDefaultHostPort,
                                    sDefaultTID: sDefaultTID
                                });
                                console.log(logoff);
                                // We return the response
                                return res;
                            })
                            .catch(async (error) => {
                                console.log("Error in catch", error);

                                const logoff = await EvertechFetchAPI.makeLogoff({
                                    sECRStationID: props.sECRStationID,
                                    sCashierID: props.sCashierID,
                                    sDefaultHostIP: DefaultHostIP,
                                    iDefaultHostPort: iDefaultHostPort,
                                    sDefaultTID: sDefaultTID
                                });
                                console.log(logoff);


                                let parsedError;
                                // If the error is already an object, no need to parse
                                if (typeof error === 'object' && error !== null) {
                                    console.log("Error is object", error);
                                    parsedError = error;
                                } else {
                                    console.log("Error is not object", error);

                                    try {
                                        parsedError = JSON.parse(error);
                                    } catch (e) {
                                        parsedError = { status: 500, data: error.message };
                                    }
                                }
                                console.log("Parsed error1111", parsedError);

                                // Throw the formatted error object
                                if (parsedError.status && parsedError.data) {
                                    console.log("Parsed error", parsedError);
                                    return parsedError;
                                } else if (error.response) {
                                    console.log("Error response", error.response);
                                    return {
                                        status: 400,
                                        data: error.response.data
                                    };
                                } else {
                                    console.log("Error", error);
                                    return error;
                                }
                            });
                    case "refund":

                        //We add some parameters to the data
                        data.iscashback = iscashback;
                        data.cashback = 0;
                        data.tips = Math.round(props.tips * 100) || 0;
                        data.force_duplicate = force_duplicate;
                        data.special_account = special_account;
                        data.manual_entry_indicator = manual_entry_indicator;
                        data.payment_host = payment_host;
                        data.basereducedtax = basereducedtax;
                        data.statetax = statetax;
                        data.reducedtax = reducedtax;
                        data.citytax = citytax;
                        data.receipt_output = "both";
                        data.total = Math.round((props.total) * 100);

                        //If the payment is cash we do a cash refund
                        if (props.payment === "Cash Refund") {
                            return evertechCashRefund(data)
                                .then(async (res) => {
                                    const logoff = await EvertechFetchAPI.makeLogoff({
                                        sECRStationID: props.sECRStationID,
                                        sCashierID: props.sCashierID,
                                        sDefaultHostIP: DefaultHostIP,
                                        iDefaultHostPort: iDefaultHostPort,
                                        sDefaultTID: sDefaultTID
                                    });
                                    console.log(logoff);
                                    // We return the response
                                    return res;
                                })
                                .catch(async (error) => {
                                    console.log("Error in catch", error);

                                    const logoff = await EvertechFetchAPI.makeLogoff({
                                        sECRStationID: props.sECRStationID,
                                        sCashierID: props.sCashierID,
                                        sDefaultHostIP: DefaultHostIP,
                                        iDefaultHostPort: iDefaultHostPort,
                                        sDefaultTID: sDefaultTID
                                    });
                                    console.log(logoff);


                                    let parsedError;
                                    // If the error is already an object, no need to parse
                                    if (typeof error === 'object' && error !== null) {
                                        console.log("Error is object", error);
                                        parsedError = error;
                                    } else {
                                        console.log("Error is not object", error);

                                        try {
                                            parsedError = JSON.parse(error);
                                        } catch (e) {
                                            parsedError = { status: 500, data: error.message };
                                        }
                                    }
                                    console.log("Parsed error1111", parsedError);

                                    // Throw the formatted error object
                                    if (parsedError.status && parsedError.data) {
                                        console.log("Parsed error", parsedError);
                                        return parsedError;
                                    } else if (error.response) {
                                        console.log("Error response", error.response);
                                        return {
                                            status: 400,
                                            data: error.response.data
                                        };
                                    } else {
                                        console.log("Error", error);
                                        return error;
                                    }
                                });
                        } else if (props.payment === "EBT Refund") {
                            data.sTransType = "EBT";
                            data.EBTTransType = "FOODREFUND";
                            //If the payment is EBT we do an EBT refund
                            return evertechEBTRefund(data)
                                .then(async (res) => {
                                    const logoff = await EvertechFetchAPI.makeLogoff({
                                        sECRStationID: props.sECRStationID,
                                        sCashierID: props.sCashierID,
                                        sDefaultHostIP: DefaultHostIP,
                                        iDefaultHostPort: iDefaultHostPort,
                                        sDefaultTID: sDefaultTID
                                    });
                                    console.log(logoff);
                                    // We return the response
                                    return res;
                                })
                                .catch(async (error) => {
                                    console.log("Error in catch", error);

                                    const logoff = await EvertechFetchAPI.makeLogoff({
                                        sECRStationID: props.sECRStationID,
                                        sCashierID: props.sCashierID,
                                        sDefaultHostIP: DefaultHostIP,
                                        iDefaultHostPort: iDefaultHostPort,
                                        sDefaultTID: sDefaultTID
                                    });
                                    console.log(logoff);


                                    let parsedError;
                                    // If the error is already an object, no need to parse
                                    if (typeof error === 'object' && error !== null) {
                                        console.log("Error is object", error);
                                        parsedError = error;
                                    } else {
                                        console.log("Error is not object", error);

                                        try {
                                            parsedError = JSON.parse(error);
                                        } catch (e) {
                                            parsedError = { status: 500, data: error.message };
                                        }
                                    }
                                    console.log("Parsed error1111", parsedError);

                                    // Throw the formatted error object
                                    if (parsedError.status && parsedError.data) {
                                        console.log("Parsed error", parsedError);
                                        return parsedError;
                                    } else if (error.response) {
                                        console.log("Error response", error.response);
                                        return {
                                            status: 400,
                                            data: error.response.data
                                        };
                                    } else {
                                        console.log("Error", error);
                                        return error;
                                    }
                                });
                        } else {
                            //We do the refund operation
                            return evertechRefund(data)
                                .then(async (res) => {
                                    const logoff = await EvertechFetchAPI.makeLogoff({
                                        sECRStationID: props.sECRStationID,
                                        sCashierID: props.sCashierID,
                                        sDefaultHostIP: DefaultHostIP,
                                        iDefaultHostPort: iDefaultHostPort,
                                        sDefaultTID: sDefaultTID
                                    });
                                    console.log(logoff);
                                    // We return the response
                                    return res;
                                })
                                .catch(async (error) => {
                                    console.log("Error in catch", error);

                                    const logoff = await EvertechFetchAPI.makeLogoff({
                                        sECRStationID: props.sECRStationID,
                                        sCashierID: props.sCashierID,
                                        sDefaultHostIP: DefaultHostIP,
                                        iDefaultHostPort: iDefaultHostPort,
                                        sDefaultTID: sDefaultTID
                                    });
                                    console.log(logoff);


                                    let parsedError;
                                    // If the error is already an object, no need to parse
                                    if (typeof error === 'object' && error !== null) {
                                        console.log("Error is object", error);
                                        parsedError = error;
                                    } else {
                                        console.log("Error is not object", error);

                                        try {
                                            parsedError = JSON.parse(error);
                                        } catch (e) {
                                            parsedError = { status: 500, data: error.message };
                                        }
                                    }
                                    console.log("Parsed error1111", parsedError);

                                    // Throw the formatted error object
                                    if (parsedError.status && parsedError.data) {
                                        console.log("Parsed error", parsedError);
                                        return parsedError;
                                    } else if (error.response) {
                                        console.log("Error response", error.response);
                                        return {
                                            status: 400,
                                            data: error.response.data
                                        };
                                    } else {
                                        console.log("Error", error);
                                        return error;
                                    }
                                });
                        }
                    case "void":
                        //We add some parameters to the data
                        data.referenceVoid = props.referenceVoid;
                        delete data.sRefNumber;
                        console.log("data to void", data);
                        return evertechVoid(data)
                            .then(async (res) => {
                                const logoff = await EvertechFetchAPI.makeLogoff({
                                    sECRStationID: props.sECRStationID,
                                    sCashierID: props.sCashierID,
                                    sDefaultHostIP: DefaultHostIP,
                                    iDefaultHostPort: iDefaultHostPort,
                                    sDefaultTID: sDefaultTID
                                });
                                console.log(logoff);
                                // We return the response
                                return res;
                            })
                            .catch(async (error) => {
                                console.log("Error in catch", error);

                                const logoff = await EvertechFetchAPI.makeLogoff({
                                    sECRStationID: props.sECRStationID,
                                    sCashierID: props.sCashierID,
                                    sDefaultHostIP: DefaultHostIP,
                                    iDefaultHostPort: iDefaultHostPort,
                                    sDefaultTID: sDefaultTID
                                });
                                console.log(logoff);


                                let parsedError;
                                // If the error is already an object, no need to parse
                                if (typeof error === 'object' && error !== null) {
                                    console.log("Error is object", error);
                                    parsedError = error;
                                } else {
                                    console.log("Error is not object", error);

                                    try {
                                        parsedError = JSON.parse(error);
                                    } catch (e) {
                                        parsedError = { status: 500, data: error.message };
                                    }
                                }
                                console.log("Parsed error1111", parsedError);

                                // Throw the formatted error object
                                if (parsedError.status && parsedError.data) {
                                    console.log("Parsed error", parsedError);
                                    return parsedError;
                                } else if (error.response) {
                                    console.log("Error response", error.response);
                                    return {
                                        status: 400,
                                        data: error.response.data
                                    };
                                } else {
                                    console.log("Error", error);
                                    return error;
                                }
                            });

                    case "adjust":
                        //We delete duplicate data
                        delete data.sRefNumber;
                        //We add some parameters to the data
                        data.sDataEntry = props.sDataEntry;
                        data.statetax = statetax;
                        data.reducedtax = reducedtax;
                        data.total = Math.round((props.total) * 100);

                        //We make the adjust operation
                        return evertechAdjust(data)
                            .then(async (res) => {
                                const logoff = await EvertechFetchAPI.makeLogoff({
                                    sECRStationID: props.sECRStationID,
                                    sCashierID: props.sCashierID,
                                    sDefaultHostIP: DefaultHostIP,
                                    iDefaultHostPort: iDefaultHostPort,
                                    sDefaultTID: sDefaultTID
                                });
                                console.log(logoff);
                                // We return the response
                                return res;
                            })
                            .catch(async (error) => {
                                console.log("Error in catch", error);

                                await EvertechFetchAPI.makeLogoff({
                                    sECRStationID: props.sECRStationID,
                                    sCashierID: props.sCashierID,
                                    sDefaultHostIP: DefaultHostIP,
                                    iDefaultHostPort: iDefaultHostPort,
                                    sDefaultTID: sDefaultTID
                                });
                                let parsedError;
                                // If the error is already an object, no need to parse
                                if (typeof error === 'object' && error !== null) {
                                    console.log("Error is object", error);
                                    parsedError = error;
                                } else {
                                    console.log("Error is not object", error);

                                    try {
                                        parsedError = JSON.parse(error);
                                    } catch (e) {
                                        parsedError = { status: 500, data: error.message };
                                    }
                                }
                                console.log("Parsed error1111", parsedError);

                                // Throw the formatted error object
                                if (parsedError.status && parsedError.data) {
                                    console.log("Parsed error", parsedError);
                                    return parsedError;
                                } else if (error.response) {
                                    console.log("Error response", error.response);
                                    return {
                                        status: 400,
                                        data: error.response.data
                                    };
                                } else {
                                    console.log("Error", error);
                                    return error;
                                }
                            });

                    default:
                        return { status: 400, data: "Invalid operation type" };
                }
            }

        case "Banesco":
            let port, quotespay, pointspay, ip;
            //We get the parameters 
            props.params.forEach((param) => {
                if (param.name === "port") port = param.value;
                if (param.name === "quotespay") quotespay = param.value;
                if (param.name === "pointspay") pointspay = param.value;
                if (param.name === "IP") ip = param.value;
            });

            //We create the data object 
            let dataBanesco = {
                "port": port,
                "ip": ip,
                "timeout": 45000
            };

            //We do the payment operation depending of the operation type
            switch (props.operationType) {
                case "settle":
                    return { status: 400, data: "Settle operation not supported for Banesco" };
                case "refund":
                    //We add some parameters to the data
                    dataBanesco.NumberOfQuotas = quotespay === "yes" ? props.numberQuotes : 0
                    dataBanesco.points = pointspay === "yes" ? true : false
                    dataBanesco.tipAmount = props.tips
                    dataBanesco.taxAmount = props.tax
                    dataBanesco.amount = props.total

                    //We do the refund operation
                    return banescoRefund(dataBanesco)
                        .then((res) => {
                            return res;
                        })
                        .catch((error) => {
                            let parsedError;
                            // If the error is already an object, no need to parse
                            if (typeof error === 'object' && error !== null) {
                                parsedError = error;
                            } else {
                                // We try to convert the error into an object
                                try {
                                    parsedError = JSON.parse(error);
                                } catch (e) {
                                    // If parsing fails, return a generic error object
                                    parsedError = { status: 500, message: error };
                                }
                            }
                            // Throw the formatted error object
                            if (parsedError.status && parsedError.data) {
                                return parsedError;
                            } else if (error.response) {
                                return {
                                    status: 400,
                                    data: error.response.data
                                };
                            } else {
                                return error;
                            }
                        });
                case "void":
                    console.log("props en el void de banesco", dataBanesco);
                    //We add some parameters to the data
                    dataBanesco.invoice = props.invoice;
                    //We do the void operation
                    return banescoVoid(dataBanesco)
                        .then((res) => {
                            return res;
                        })
                        .catch((error) => {
                            let parsedError;
                            // If the error is already an object, no need to parse
                            if (typeof error === 'object' && error !== null) {
                                parsedError = error;
                            } else {
                                // We try to convert the error into an object
                                try {
                                    parsedError = JSON.parse(error);
                                } catch (e) {
                                    // If parsing fails, return a generic error object
                                    parsedError = { status: 500, message: error };
                                }
                            }
                            // Throw the formatted error object
                            if (parsedError.status && parsedError.data) {
                                return parsedError;
                            } else if (error.response) {
                                return {
                                    status: 400,
                                    data: error.response.data
                                };
                            } else {
                                return error;
                            }
                        });
                default:
                    return { status: 400, data: "Invalid operation type" };
            }
        default:
            return { status: 400, data: "Invalid integration type" };

    }
}